import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/services/auth.service';
import { environment } from 'src/environments/environment';
// #fake-start#
import { FakeAPIService } from './_fake/fake-api.service';
import { StoreModule } from '@ngrx/store';
import { clearState } from './core/23blocks/gateway/reducers/clearState.metareducer';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { Gateway23blocksModule } from './core/23blocks/gateway/gateway-23blocks.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { Assets23blocksModule } from './core/23blocks/assets/assets-23blocks.module';
import { CRM23blocksModule } from './core/23blocks/crm/crm-23blocks.module';
import { Files23blocksModule } from './core/23blocks/files/files-23blocks.module';
import { Forms23blocksModule } from './core/23blocks/forms/forms-23blocks.module';
import { Geolocation23blocksModule } from './core/23blocks/geolocation/geolocation-23blocks.module';
import { Onboarding23blocksModule } from './core/23blocks/onboarding/onboarding-23blocks.module';
import { Products23blocksModule } from './core/23blocks/products/products-23blocks.module';
import { Realtime23blocksModule } from './core/23blocks/realtime/realtime-23blocks.module';
import { Rewards23blocksModule } from './core/23blocks/rewards/rewards-23blocks.module';
import { Sales23blocksModule } from './core/23blocks/sales/sales-23blocks.module';
import { Search23blocksModule } from './core/23blocks/search/search-23blocks.module';
import { Wallet23blocksModule } from './core/23blocks/wallet/wallet-23blocks.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { AvatarModule } from 'ngx-avatars';
import { GatewayGuard, GatewayService } from './core/23blocks/gateway';
import { Mooncheck23blocksModule } from './core/23blocks/mooncheck/mooncheck-23blocks.module';
import { Content23blocksModule } from './core/23blocks/content/content-23blocks.module';
import { InTabNavigationService } from './services/in-tab-navigation.service';
// #fake-end#

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      //@ts-ignore
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    // #fake-start#
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
          passThruUnknownUrl: true,
          dataEncapsulation: false,
        })
      : [],
    // #fake-end#
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    StoreModule.forRoot({}, { metaReducers: [clearState] }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([]),
    Gateway23blocksModule.forRoot({
      apiBase: environment.API_23GATEWAY_URL,
      APPID: environment.APPID,
      registerAccountCallback: environment.APP_URL + '/auth/step2/',
      resetPasswordCallback: environment.APP_URL + '/auth/change-password/',
    }),
    Onboarding23blocksModule.forRoot({
      apiBase: environment.API_23ONBOARDING_URL,
      APPID: environment.APPID,
    }),
    Products23blocksModule.forRoot({
      apiBase: environment.API_23PRODUCTS_URL,
      APPID: environment.APPID,
    }),
    CRM23blocksModule.forRoot({
      apiBase: environment.API_23CRM_URL,
      APPID: environment.APPID,
    }),
    Sales23blocksModule.forRoot({
      apiBase: environment.API_23SALES_URL,
      APPID: environment.APPID,
    }),
    Realtime23blocksModule.forRoot({
      apiBase: environment.API_23REALTIME_URL,
      APPID: environment.APPID,
    }),
    Files23blocksModule.forRoot({
      apiBase: environment.API_23FILES_URL,
      APPID: environment.APPID,
    }),
    Search23blocksModule.forRoot({
      apiBase: environment.API_23SEARCH_URL,
      APPID: environment.APPID,
    }),
    Rewards23blocksModule.forRoot({
      apiBase: environment.API_23REWARDS_URL,
      APPID: environment.APPID,
    }),
    Geolocation23blocksModule.forRoot({
      apiBase: environment.API_23GEOLOCATION_URL,
      APPID: environment.APPID,
    }),
    Forms23blocksModule.forRoot({
      apiBase: environment.API_23FORMS_URL,
      APPID: environment.APPID,
    }),
    Wallet23blocksModule.forRoot({
      apiBase: environment.API_23WALLET_URL,
      APPID: environment.APPID,
    }),
    Assets23blocksModule.forRoot({
      apiBase: environment.API_23ASSETS_URL,
      APPID: environment.APPID,
    }),
    Mooncheck23blocksModule.forRoot({
      apiBase: environment.API_23MOONCHECK_URL,
      APPID: environment.APPID,
    }),
    Content23blocksModule.forRoot({
      apiBase: environment.API_23CONTENT_URL,
      APPID: environment.APPID,
    }),
    RecaptchaV3Module,
    MatSnackBarModule,
    AvatarModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    GatewayService,
    GatewayGuard,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.RECAPTCHA_KEY,
    },
    InTabNavigationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
