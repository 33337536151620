import { Action } from '@ngrx/store';
import { UserIdentity } from '../models/user-identity.model';

export enum SalesActionTypes {
  PaymentStatusRequested = '[Request User Payment Status] API',
  PaymentStatusLoaded = '[Load User Payment Status] API',
  IdentityRequested = '[Request User Sales Identity] API',
  IdentityLoaded = '[Load User Sales Identity] API',
  CreateIdentity = '[Create User Sales Identity] API',
  CreateSubscription = '[Create User Sales Subscription] API',
}
export class PaymentStatusRequested implements Action {
  readonly type = SalesActionTypes.PaymentStatusRequested;
}
export class PaymentStatusLoaded implements Action {
  readonly type = SalesActionTypes.PaymentStatusLoaded;
  constructor(public payload: { paymentStatus: string }) {}
}

export class IdentityRequested implements Action {
  readonly type = SalesActionTypes.IdentityRequested;
}
export class IdentityLoaded implements Action {
  readonly type = SalesActionTypes.IdentityLoaded;
  constructor(public payload: { userIdentity: UserIdentity }) {}
}

export class CreateIdentity implements Action {
  readonly type = SalesActionTypes.CreateIdentity;
}

export class CreateSubscription implements Action {
  readonly type = SalesActionTypes.CreateSubscription;
}

export type SalesActions =
  | PaymentStatusRequested
  | PaymentStatusLoaded
  | IdentityRequested
  | IdentityLoaded
  | CreateIdentity
  | CreateSubscription;
