// Spain
export const locale = {
  lang: 'es',
  data: {
    TRANSLATOR: {
      SELECT: 'Elige tu idioma',
    },
    MENU: {
      NEW: 'Nuevo',
      ACTIONS: 'Comportamiento',
      CREATE_POST: 'Crear nueva publicación',
      PAGES: 'Páginas',
      FEATURES: 'Caracteristicas',
      APPS: 'Aplicaciones',
      DASHBOARD: 'Tablero',
      COURSES: 'Cursos',
      PROFILE: 'Perfil',
    },
    AUTH: {
      GENERAL: {
        OR: 'O',
        SUBMIT_BUTTON: 'Enviar',
        NO_ACCOUNT: '¿No tienes cuenta aún?',
        SIGNUP_BUTTON: 'Regístrate',
        FORGOT_BUTTON: 'Se te olvidó tu contraseña',
        BACK_BUTTON: 'Volver',
        PRIVACY: 'Privacidad',
        LEGAL: 'Legal',
        CONTACT: 'Contacto',
        GOOGLE_RECAPTCHA:
          'Esta página está protegida por reCAPTCHA y aplican la',
        GOOGLE_RECAPTCHA1: 'política de privacidad',
        GOOGLE_RECAPTCHA2: 'términos de servicio',
        GOOGLE_RECAPTCHA_AND: 'y los',
        GOOGLE_RECAPTCHA_APPLY: ' de Google.',
      },
      LOGIN: {
        TITLE: 'Iniciar Sesión',
        BUTTON: 'Iniciar Sesión',
        PASSWORD_OR_USER_INVALID: 'Identidad o contraseña no válida.',
      },
      FORGOT: {
        TITLE: 'Contraseña olvidada',
        DESC: 'Te enviaremos un email con instrucciones de como restablecer tu contraseña',
        SUCCESS:
          'Recibiras un email con instrucciones de como restablecer tu contraseña en unos minutos',
        FORGOT_BUTTON: 'Enviarme email',
        FORGOT_EMAIL: 'No recuerdo mi correo',
        RESEND_EMAIL_LINK: 'Reenviar email de confirmacion de cuenta',
        RESEND_EMAIL_SUCCESS:
          'Se ha reenviado un email con instrucciones de como activar tu cuenta',
        EMAIL_AND_SUBJECT:
          'mailto:inhance@inhance.life?subject=Problema de acceso',
        ERROR_NOT_FOUND_EMAIL_MESSAGE:
          'No se pudo encontrar un usuario con ese correo electrónico',
      },
      CHANGE_PASSWORD: {
        TITLE: 'Cambiar contraseña',
        DESC: 'Te enviaremos un email con instrucciones de como restablecer tu contraseña',
        SUCCESS: 'Tu contraseña ha sido actualizada exitosamente',
        FAILURE:
          'No se pudo actualizar tu contraseña. Por favor comunicate con nuestro canal de soporte',
        CHANGE_BUTTON: 'Cambiar contraseña',
        ONE_MORE_TRY: '¿Un intento más?',
      },
      REGISTER: {
        TITLE: 'Registro',
        FUN_JUMPER_TITLE: 'Regístrate como Fun Jumper',
        AFF_TITLE: 'Regístrate como AFF',
        DESC: 'Escribe tus datos para crear la cuenta',
        SUCCESS:
          'Tu cuenta ha sido registrada correctamente. Te enviaremos un email con instrucciones de como activar tu cuenta',
        INVITATION_SUCCESS:
          'Tu cuenta ha sido registrada correctamente. Ya puedes iniciar sesión',
        REGISTER_BUTTON: 'Crear cuenta',
        BACK_TO_SIGNIN: 'Volver a iniciar sesión',
        ACCOUNT_QUESTION: '¿Ya tienes una cuenta?',
        CONDITION_MESSAGE:
          'Al hacer clic en Registrarme, usted acepta nuestros',
        TERMS: 'Términos',
        PRIVACY: 'Política de privacidad',
        COOKIES: 'Política de cookies',
        ERROR_MESSAGE:
          'El correo ya está registado. Si no puedes ingresar, <a href="/auth/forgot-password" class="text-white">haz click aquí.</a>',
        INVITATION_ERROR_MESSAGE: 'Hubo un error',
        WITHOUT_INVITATION: 'No puedes registarte sin invitatión',
      },
      EMAIL_CONFIRMATION: {
        CONFIRM_EMAIL: 'Por favor, confirme su email',
        ACCOUNT_CREATED: 'Tu cuenta ha sido registrada correctamente',
        WILL_RECEIVE_EMAIL:
          'Te enviaremos un email con instrucciones de como activar tu cuenta',
        NO_EMAIL: '¿No recibiste ningún email en tu bandeja de entrada?',
        RESEND_EMAIL_BUTTON: 'Reenviar email',
        RESEND: 'Reenviar',
        ENTER_EMAIL:
          'Ingrese su correo electrónico y le enviaremos el correo electrónico de confirmación de cuenta',
      },
      STEP2: {
        TITLE: 'Estamos listos',
        ALREADY_CONFIRMED:
          'Tu email ya ha sido confirmado, por favor intenta iniciar sesión',
        CONFIRMATION_COMPLETE: 'Confirmación de email exitosa. Muchas gracias',
      },
      INPUT: {
        EMAIL: 'Correo Electrónico',
        NAME: 'Nombre',
        FULLNAME: 'Nombre Completo',
        PASSWORD: 'Contraseña',
        CONFIRM_PASSWORD: 'Confirmar contraseña',
        USERNAME: 'Usuario',
        NEW_PASSWORD: 'Nueva contraseña ',
        CONFIRM_NEW_PASSWORD: 'Confirmar Contraseña',
      },
      VALIDATION: {
        INVALID: '{{name}} no es valido',
        REQUIRED: '{{name}} es requerido',
        MIN_LENGTH: '{{name}} una longitud mínima de {{min}}',
        AGREEMENT_REQUIRED: 'Aceptar términos y condiciones es requerido',
        NOT_FOUND: 'EL requerimiento {{name}} no fue encontrado',
        INVALID_LOGIN: 'Los datos de iniciar sesión son incorrectos',
        REQUIRED_FIELD: 'Campo requerido',
        MIN_LENGTH_FIELD: 'Longitud mínima:',
        MAX_LENGTH_FIELD: 'Longitud máxima:',
        INVALID_FIELD: 'Campo no válido',
        PASSWORDS_NOT_MATCH: 'Las contraseñas no coinciden',
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields',
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted',
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created',
        },
      },
    },
    GENERAL: {
      DAY: 'Día',
      LOADING: 'Cargando',
      CONTENT_NOT_AVAILABLE: 'El contenido no está disponible aún',
      COURSES: 'Cursos',
      START: 'Comenzar',
      NEXT: 'Siguiente',
      ERROR_MESSAGE: 'Ha ocurrido un error',
      DURATION: 'Duración',
      HELP: 'Ayuda',
      INFO_USER: 'Info-usuario',
      STEP: 'Paso',
    },
    INTRO: {
      SUBTITLE: 'Presentación',
      DESCRIPTION: 'Descripción del curso',
      DETAILS: 'Detalles',
      EN: 'Ingles',
      ES: 'Español',
      START_BUTTON: 'Comenzar',
      COURSE_PROGRESS: 'Progreso del curso',
      PROGRESS_MESSAGE: '¡Estás en el paso',
      CONTINUE_MESSAGE: 'Continúa tu aprendizaje.',
      COMPLETED_MESSAGE: '¡Lo has completado!',
      HAPPINESS_LEVEL_TITLE: 'Nivel de Felicidad',
      CHART_INITIAL_TITLE: 'Inicial',
      CHART_AFTER_TITLE: 'Después',
      CHART_MESSAGE: `Estamos midiendo tu felicidad, para que tengas una idea de
      dónde estás en referencia a ella. El número que saques aquí es sólo una
      referencia, ya que el trabajo recién comienza. La felicidad está en ti
       y ya tomaste la decisión de trabajar en ella. ¡Felicidades por eso!`,
    },
    TRAINING: {
      INTRO: {
        INTRO_TAB: 'Introducción',
        CONTENT_TAB: 'Contenido',
        RESOURCES_TAB: 'Recursos',
        DESCRIPTION_TITLE: 'Descripción',
        CONTENT_TITLE: '¿Qué incluye?',
        DETAILS: 'Detalles',
        FIRST_DETAILS:
          '1 meditación para ser escuchada cuantas veces quieran y en el orden que la quieran escuchar.  Click en el tab "Recursos."',
        SECOND_DETAILS:
          '1 video inicial para ser visto antes de comenzar los días.',
        THIRD_DETAILS: '7 días, una práctica por día.',
      },
      CONTENT: {
        MARK_AS_FINISHED_BUTTON: 'Marcar este día como terminado',
        FINISHED_DAY_BUTTON: 'Día terminado',
        NEXT_DAY: 'Siguiente día',
        STEP_NOTES: 'Notas paso',
        DOWNLOAD: 'Descargar',
        PRINT: 'Imprimir',
        SAVE: 'Guardar',
        PLACEHOLDER_NOTES: 'Escribe aquí tus notas',
        NOTE_DAY_ONE:
          'Te invitamos a usar las notas a la derecha para que escribas todo lo que vas pensando y procesando en cada día.',
      },
      RESOURCES: {},
    },
    ONBOARDING: {
      WIZARD_FIRST_TITLE: 'Selecciona un curso',
      WIZARD_SECOND_TITLE: 'Revisa y empieza',
      WIZARD_THIRD_TITLE: 'Valida tu código',
      FIRST_STEP_TITLE: 'Selecciona el curso que deseas realizar',
      CONTENT_TITLE: '¿Qué incluye?',
      LIMITED_TIME_ACCESS: 'Acceso por tiempo limitado',
      CONTACT_EMAIL:
        'Si tienes cualquier pregunta, envíanos un email a inhance@inhance.life',
      SECOND_STEP_TITLE: 'Accede a tu curso',
      INSTRUCTIONS: 'Instrucciones a seguir:',
      INSTRUCTION_STEP_ONE:
        'Paga tu curso siguiendo las instrucciones que encontrarás en nuestro website www.inhance.life.',
      CODE_READY: 'Si ya tienes un código, haz click',
      HERE: 'aquí',
      TO_VALIDATE: 'para validarlo',
      INSTRUCTION_STEP_TWO:
        'Cuando hayas hecho el pago, recibirás un código en las próximas 24 horas.',
      INSTRUCTION_STEP_THREE: `Cuando tengas el código, ingresa nuevamente a la plataforma y copialo en la casilla
      que dice validar código (Haz click en SIGUIENTE).`,
      INSTRUCTION_STEP_FOUR: 'Ahora podras empezar tu curso.',
      THIRD_STEP_TITLE:
        'Necesitamos validar tu pago para que puedas disfrutar del curso',
      CODE: 'Código',
      CODE_REQUIRED: 'El código es requerido',
      COURSE_SELECTED_CORRECTLY: 'Se selecciono correctamente el curso',
      COURSE_NOT_SELECTED: 'No seleccionaste un curso',
      CODE_VALIDATED: 'Se valido tu código correctamente',
      CODE_INVALID: 'Código inválido vuelve a intentarlo',
      CODE_NOT_VALIDATED: 'No validaste el código',
    },
    HAPPINESS_SURVEY: {
      TITLE: 'Encuesta de la Felicidad',
      INSTRUCTIONS: `La siguiente encuesta nos dará una idea de tu nivel actual de
      felicidad y nos permitirá evaluar tu progreso en el curso. Recuerda,
       no hay respuestas correctas o incorrectas, así que trata de ser lo
       más honesto posible.`,
      SURVEY_TITLE: 'Felicidad General',
      SEND_BUTTON: 'Enviar',
      HAPPINESS_LEVEL_TITLE: 'Nivel de Felicidad',
      SUBMIT_MESSAGE: 'Estamos procesando tus respuestas',
      INCOMPLETE_SURVEY: 'Responde todas las preguntas para poder avanzar.',
    },
    COURSE_SURVEY: {
      TITLE: 'Encuesta sobre el curso',
      INSTRUCTIONS: 'Cómo valoras los siguientes aspectos',
      SCORE: '1 poco 5 mucho',
      COACH: 'Coach',
      QUESTION_ONE: 'Manera de explicar',
      QUESTION_TWO: 'Conocimiento en la disciplina',
      QUESTION_THREE: 'Participación / retroalimentación',
      QUESTION_FOUR: '¿Harías otro curso?',
      COURSE: 'CURSO',
      QUESTION_FIVE: 'Contenido',
      QUESTION_SIX: 'Calidad de audio/video',
      QUESTION_SEVEN: 'Recursos adicionales',
      QUESTION_EIGHT: '¿Era lo que esperabas?',
      COMMENTS: '¿Tienes algo más para decirnos?',
      RECOMMENDATION_QUESTION: '¿Recomendarías este curso?',
      SEND_RATING: 'Enviar Valoración',
      YES: 'Si',
      NO: 'No',
    },
    TOPBAR: {
      HELP_MESSAGE: 'Si necesita ayuda, escríbanos a inhance@inhance.life',
    },
    QUICK_PANEL: {
      USER: 'Usuario',
      LOGOUT_BUTTON: 'Cerrar Sesión',
    },
    PROFILE: {
      SECTION: 'Perfil',
      TITLE: 'Información Básica',
      NAME: 'Nombre',
      EMAIL: 'Correo Electrónico',
      NOT_AVAILABLE: 'No Disponible',
      QUESTION_NOT_AVAILABLE: 'Pregunta no disponible',
      ANSWER: 'Respuesta',
      NOT_ANSWER: 'Sin Respuesta',
    },
    HEADER: {
      TITLE: 'Universidad de la Felicidad',
    },
  },
};
