import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import {
  Realtime23blocksOptions,
  REALTIME_23BLOCKS_SERVICE_OPTIONS,
} from './models/realtime.interfaces';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Realtime23blocksInterceptor } from './interceptors/realtime-23blocks.interceptor';
import { RealtimeService } from './services/realtime.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { notificationsReducer } from './reducers/notifications.reducers';
import { NotificationsEffects } from './effects/notifications.effects';
import { UserStatusService } from './services/user-status.service';
import { realtimeReducer } from './reducers/realtime.reducers';
import { RealtimeEffects } from './effects/realtime.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('notifications', notificationsReducer),
    StoreModule.forFeature('realtime', realtimeReducer),
    EffectsModule.forFeature([NotificationsEffects, RealtimeEffects]),
  ],
})
export class Realtime23blocksModule {
  constructor(@Optional() @SkipSelf() parentModule: Realtime23blocksModule) {
    if (parentModule) {
      throw new Error(
        "Realtime23Blocks is already loaded. It should only be imported in your application's main module."
      );
    }
  }
  static forRoot(
    options: Realtime23blocksOptions
  ): ModuleWithProviders<Realtime23blocksModule> {
    return {
      ngModule: Realtime23blocksModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: Realtime23blocksInterceptor,
          multi: true,
        },
        options.realtimeOptionsProvider || {
          provide: REALTIME_23BLOCKS_SERVICE_OPTIONS,
          useValue: options,
        },
        RealtimeService,
        UserStatusService,
      ],
    };
  }
}
