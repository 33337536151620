import { Action } from '@ngrx/store';
import { UserIdentity } from '../models/user-identity.model';

export enum RealtimeActionTypes {
  IdentityRequested = '[Request User Realtime Identity] API',
  IdentityLoaded = '[Load User Realtime Identity] API',
  CreateIdentity = '[Create User Realtime Identity] API',
}

export class IdentityRequested implements Action {
  readonly type = RealtimeActionTypes.IdentityRequested;
}
export class IdentityLoaded implements Action {
  readonly type = RealtimeActionTypes.IdentityLoaded;
  constructor(public payload: { userIdentity: UserIdentity }) {}
}

export class CreateIdentity implements Action {
  readonly type = RealtimeActionTypes.CreateIdentity;
}

export type RealtimeActions =
  | IdentityRequested
  | IdentityLoaded
  | CreateIdentity;
