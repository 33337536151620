import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { CRMService } from '../services/crm.service';

@Injectable()
export class CRM23blocksInterceptor implements HttpInterceptor {
  constructor(private crmService: CRMService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.match(environment.API_23CRM_URL)) {
      if (
        req.headers.get('appid') === null &&
        req.headers.get('company-token') === null
      ) {
        // Get auth data from local storage
        this.crmService.getAuthDataFromStorage();

        // Add the headers if the request is going to the configured server
        const authData = this.crmService.authData.value;

        let headers = {};

        if (authData) {
          headers = {
            'company-token': authData.companyToken,
            appid: localStorage.getItem('app_tenant_id')
              ? localStorage.getItem('app_tenant_id')
              : authData.appid,
          };
        } else {
          headers = {
            appid: localStorage.getItem('app_tenant_id')
              ? localStorage.getItem('app_tenant_id')
              : environment.APPID,
          };
        }

        req = req.clone({
          setHeaders: headers,
        });
      }
    } else return next.handle(req);

    return next
      .handle(req)
      .pipe(
        tap(
          (res) => this.handleResponse(res),
          (err) => this.handleResponse(err)
        )
      )
      .pipe(
        catchError((err) => {
          // const errors = err.error
          //   ? err.error?.errors || {
          //       errors: [err.error?.message],
          //     } || { errors: [err.statusText] }
          //   : { ...err };
          // return throwError(errors);
          return throwError(err);
        })
      );
  }

  // Parse Auth data from response
  private handleResponse(
    res: HttpResponse<any> | HttpErrorResponse | HttpEvent<any>
  ): void {
    if (res instanceof HttpResponse || res instanceof HttpErrorResponse) {
      if (
        this.crmService.tokenOptions.apiBase === null ||
        (res.url && res.url.match(this.crmService.tokenOptions.apiBase))
      ) {
        // console.log('Successful response - Interceptor CRM');
      }
    }
  }
}
