import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  filter,
  first,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';
import { environment } from 'src/environments/environment';
import {
  AllRolesLoaded,
  AuthActionTypes,
  currentAuthUser,
  GatewayService,
  RoleActionTypes,
  selectRoleById,
  User,
  UserLoaded,
} from '../../gateway';
import {
  CreateIdentity,
  IdentityLoaded,
  IdentityRequested,
  OnboardingActionTypes,
  OnboardingConfigLoaded,
  OnboardingConfigRequested,
  UserJourneyLoaded,
  UserJourneyRequested,
  SendStep,
  OnboardingCompleted,
  CreateUserJourney,
} from '../actions/onboarding.actions';
import { OnboardingService } from '../services/onboarding.service';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
import { OnboardingStepData, UserData } from '../models/onboarding.interfaces';
import {
  selectUserIdentity,
  isIdentityLoaded,
  selectUserJourney,
  isCurrentStepComplete,
  isUserJourneyLoaded,
} from '../selectors/onboarding.selectors';
import { Onboarding } from '../models/onboarding.model';
import { of } from 'rxjs';
import { OnboardingStep } from '../models/onboarding-step.model';
import { UserJourney } from '../models/user-journey.model';
import { Router } from '@angular/router';
import {
  getRoleId,
  getRoleNameByUniqueId,
  getRoleNameForIdentity,
  getRoleUniqueId,
} from 'src/app/core/tools/helper-functions';
import { normalizeApiResponse } from 'src/app/common/utils/utils';

@Injectable()
export class OnboardingEffects {
  getUserJourney$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UserJourneyRequested>(
          OnboardingActionTypes.UserJourneyRequested
        ),
        concatLatestFrom((action) => this.store.select(isUserJourneyLoaded)),
        filter(([action, isLoaded]) => !isLoaded),
        concatLatestFrom(([action, isLoaded]) => [
          this.store.select(selectUserIdentity),
        ]),
        switchMap(([action, userIdentity]) => {
          const roleId = getRoleId(userIdentity.roleUniqueId);
          return this.store
            .select(selectRoleById(roleId))
            .pipe(first((role) => !!role));
        }),
        switchMap((role) => {
          // console.log(role, 'Getting Onboarding status');
          const onBoardingId = role.payload.onboarding;
          return this.onboarding.getUserJourney(onBoardingId);
        }),
        catchError((error) => {
          return of({ data: null });
        }),
        tap(
          (apiResponse) => {
            if (!apiResponse?.data) {
              this.store.dispatch(new CreateUserJourney());
              return;
            }
            let _userJourney: UserJourney = null;
            let isComplete: boolean = false;
            if (apiResponse?.data) {
              _userJourney = normalizeApiResponse(
                apiResponse,
                'userJourney',
                false
              ) as UserJourney;
              isComplete = this.isOnboardingDone(_userJourney);
            }
            this.store.dispatch(
              new UserJourneyLoaded({
                userJourney: _userJourney,
                isComplete,
              })
            );
          },
          (error) => {
            console.log(error, 'Error fetching user journey');
            this.store.dispatch(
              new UserJourneyLoaded({
                userJourney: null,
                isComplete: false,
              })
            );
          }
        )
      ),
    { dispatch: false }
  );

  createUserJourney$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CreateUserJourney>(OnboardingActionTypes.CreateUserJourney),
        concatLatestFrom((action) => [this.store.select(selectUserIdentity)]),
        switchMap(([action, userIdentity]) => {
          const roleId = getRoleId(userIdentity.roleUniqueId);
          return this.store
            .select(selectRoleById(roleId))
            .pipe(first((role) => !!role));
        }),
        concatLatestFrom((role) => [this.store.select(currentAuthUser)]),
        switchMap(([role, user]) => {
          const onBoardingId = role.payload.onboarding;
          return this.onboarding.createUserJourney(onBoardingId, user.uniqueId);
        }),
        tap(
          (apiResponse) => {
            let _userJourney: UserJourney = null;
            let isComplete: boolean = false;
            if (apiResponse?.data) {
              const data: any = normalize(apiResponse);
              _userJourney = build(data, 'userJourney', apiResponse.data.id, {
                eager: true,
              });
              isComplete = this.isOnboardingDone(_userJourney);
            }
            this.store.dispatch(
              new UserJourneyLoaded({
                userJourney: _userJourney,
                isComplete,
              })
            );
          },
          (error) => {
            console.log(error, 'Error creating User Journey');
            this.store.dispatch(
              new UserJourneyLoaded({
                userJourney: null,
                isComplete: false,
              })
            );
          }
        )
      ),
    { dispatch: false }
  );

  isOnboardingDone(userJourney: UserJourney): boolean {
    const { progress, stepStatus } = userJourney;
    if (progress && stepStatus === 'complete') {
      return +progress < 100 ? false : true;
    } else return false;
  }

  getOnboardingConfig$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<OnboardingConfigRequested>(
          OnboardingActionTypes.OnboardingConfigRequested
        ),
        concatLatestFrom((action) => [this.store.select(selectUserIdentity)]),
        switchMap(([action, userIdentity]) => {
          const roleId = getRoleId(userIdentity.roleUniqueId);
          return this.store
            .select(selectRoleById(roleId))
            .pipe(first((role) => !!role));
        }),
        switchMap((role) => {
          const onboardingId = role.payload?.onboarding;
          return this.onboarding.getOnboardingConfiguration(onboardingId);
        }),

        tap(
          (apiResponse) => {
            if (apiResponse.data) {
              // console.log(apiResponse, 'Onboarding config');
              const data: any = normalize(apiResponse);
              const _onboarding: Onboarding = build(
                data,
                'onboarding',
                apiResponse.data.id,
                {
                  eager: true,
                }
              );
              const _steps: OnboardingStep[] = build(data, 'step', null, {
                eager: true,
              });
              this.store.dispatch(
                new OnboardingConfigLoaded({
                  onboardingConfig: { ..._onboarding, steps: _steps },
                })
              );
            }
          },
          (error) => {
            console.log('Error fetching onboarding config');
          }
        )
      ),
    { dispatch: false }
  );

  loadIdentity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<IdentityRequested>(OnboardingActionTypes.IdentityRequested),
        concatLatestFrom((action) => this.store.select(currentAuthUser)),
        mergeMap(([action, user]) => {
          return this.onboarding.getUserIdentity(user.uniqueId);
        }),
        tap(
          (apiResponse) => {
            if (apiResponse?.data) {
              // console.log(apiResponse, 'Ya existe identidad onboarding');
              const data: any = normalize(apiResponse);
              const _userIdentity = build(
                data,
                'userIdentity',
                apiResponse.data.id,
                {
                  eager: true,
                }
              );
              this.store.dispatch(
                new IdentityLoaded({
                  userIdentity: _userIdentity,
                  firstTime: false,
                })
              );
            } else {
              // console.log('No existe identidad onboarding');
              this.store.dispatch(new CreateIdentity());
            }
          },
          (error) => {
            this.store.dispatch(new CreateIdentity());
          }
        )
      ),
    { dispatch: false }
  );

  createIdentity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CreateIdentity>(OnboardingActionTypes.CreateIdentity),
        concatLatestFrom((action) => [this.store.select(currentAuthUser)]),
        switchMap(([action, user]) => {
          const roleId = user.roleId;
          return this.store.select(selectRoleById(roleId)).pipe(
            first((role) => !!role),
            switchMap((role) => {
              console.log(user, 'Creando Identidad Onboarding...');
              const userData = this.buildUserData(user);

              const onboardingId = role.payload?.onboarding;

              return this.onboarding.createUserIdentity(
                user.uniqueId,
                onboardingId,
                userData
              );
            })
          );
        }),

        tap(
          (apiResponse) => {
            // console.log(apiResponse, 'Se agrego identidad onboarding');
            const data: any = normalize(apiResponse);
            const _userIdentity = build(
              data,
              'userIdentity',
              apiResponse.data.id,
              {
                eager: true,
              }
            );
            this.store.dispatch(
              new IdentityLoaded({
                userIdentity: _userIdentity,
                firstTime: true,
              })
            );
          },
          (error) => {
            // console.log(error, 'Error al agregar identidad onboarding');
          }
        )
      ),
    { dispatch: false }
  );

  buildUserData(user: User): UserData {
    const role_name = getRoleNameForIdentity(user.roleId);
    const role_unique_id = getRoleUniqueId(user.roleId);

    return {
      name: user.name || 'User Name',
      phone:
        user.phone ||
        (Math.floor(Math.random() * 9000000000) + 1000000000).toString(),
      email: user.email || 'example@email.com',
      user_unique_id: user.uniqueId,
      role_name,
      role_unique_id,
    };
  }

  getStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<IdentityLoaded>(OnboardingActionTypes.IdentityLoaded),
        concatLatestFrom((action) => this.store.select(isIdentityLoaded)),
        tap(([action, isLoaded]) => {
          this.store.dispatch(new OnboardingConfigRequested());
          this.store.dispatch(new UserJourneyRequested());
        })
      ),
    { dispatch: false }
  );

  sendStep$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SendStep>(OnboardingActionTypes.SendStep),
        concatLatestFrom((action) => [
          this.store.select(selectUserJourney),
          this.store.select(isCurrentStepComplete),
        ]),
        mergeMap(([action, userJourney, isCurrentStepComplete]) => {
          // console.log('Calling Onboarding SendStep service...');
          const { onboardingUniqueId } = userJourney;
          const stepData = this.parseStepFormData(action.payload);
          return this.onboarding.stepOnboard(onboardingUniqueId, stepData);
        }),
        tap((apiResponse) => {
          // console.log('RESPUESTA DE Onboarding SendStep');
          let _userJourney: UserJourney;
          if (apiResponse.data) {
            const data: any = normalize(apiResponse);
            _userJourney = build(data, 'userJourney', apiResponse.data.id, {
              eager: true,
            });
          } else {
            _userJourney = null;
          }
          // console.log(_userJourney, 'Updated Onboarding userJourney');
          const isComplete = this.isOnboardingDone(_userJourney);
          this.store.dispatch(
            new UserJourneyLoaded({
              userJourney: _userJourney,
              isComplete,
            })
          );
          if (isComplete) {
            this.store.dispatch(new OnboardingCompleted());
          }
        })
      ),
    { dispatch: false }
  );

  parseStepFormData(payload: {
    stepId: string;
    status: string;
    params?: any;
  }): OnboardingStepData {
    const { stepId, status } = payload;
    const params = payload.params;
    return {
      step_unique_id: stepId,
      step_params: params ? JSON.stringify(params) : '',
      source: 'User',
      source_id: 1,
      source_type: 'User completed',
      source_alias: 'User',
      step_status: status,
      payload: JSON.stringify({}),
    };
  }

  checkIfComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<OnboardingCompleted>(OnboardingActionTypes.OnboardingCompleted),
        concatLatestFrom((action) => this.store.select(selectUserIdentity)),
        tap(([action, userIdentity]) => {
          console.log('Onboarding Completed! Redirecting to dashboard');
          const userType = getRoleNameByUniqueId(userIdentity.roleUniqueId);
          this.router.navigateByUrl(`/${userType}/dashboard`);
        })
      ),
    { dispatch: false }
  );

  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AllRolesLoaded>(RoleActionTypes.AllRolesLoaded),
        tap((action) => {
          // this.store.dispatch(new IdentityRequested());
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private onboarding: OnboardingService,
    private store: Store<AppState>,
    private router: Router,
    private gateway: GatewayService
  ) {}
}
