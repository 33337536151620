import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductsState } from '../reducers/products.reducers';

export const selectProductsState =
  createFeatureSelector<ProductsState>('products');

export const selectUserIdentity = createSelector(
  selectProductsState,
  (state) => state.userIdentity
);
export const isIdentityLoaded = createSelector(
  selectProductsState,
  (state) => state.isIdentityLoaded
);

export const selectCart = createSelector(
  selectProductsState,
  (state) => state.cart
);

export const isCartLoaded = createSelector(
  selectProductsState,
  (state) => state.isCartSuccess
);
