import { Action } from '@ngrx/store';
import { Notification } from '..';

export enum NotificationsActionTypes {
  NotificationsRequested = '[Request Notifications] 23Blocks API',
  NotificationsLoaded = '[Loaded Notifications] 23Blocks API',
  NotificationRequested = '[Request Notification] 23Blocks API',
  NotificationLoaded = '[Loaded Notification] 23Blocks API',
  NotificationRemoveRequested = '[Request Notification Remove] 23Blocks API',
  NotificationRemoved = '[Removed Notification] 23Blocks API',
  NotificationReceived = '[Received Notification] 23Blocks API',
}

export class NotificationsRequested implements Action {
  readonly type = NotificationsActionTypes.NotificationsRequested;
  constructor(public payload: { userId: string }) {}
}

export class NotificationsLoaded implements Action {
  readonly type = NotificationsActionTypes.NotificationsLoaded;
  constructor(public payload: { notifications: Notification[] }) {}
}

export class NotificationRequested implements Action {
  readonly type = NotificationsActionTypes.NotificationRequested;
  constructor(public payload: { notificationUniqueId: string }) {}
}

export class NotificationLoaded implements Action {
  readonly type = NotificationsActionTypes.NotificationLoaded;
  constructor(public payload: { notification: Notification }) {}
}

export class NotificationRemoveRequested implements Action {
  readonly type = NotificationsActionTypes.NotificationRemoveRequested;
  constructor(public payload: { notificationUniqueId: string }) {}
}

export class NotificationRemoved implements Action {
  readonly type = NotificationsActionTypes.NotificationRemoved;
  constructor(public payload: { notificationUniqueId: string }) {}
}

export class NotificationReceived implements Action {
  readonly type = NotificationsActionTypes.NotificationReceived;
  constructor(public payload: { notification: Notification }) {}
}

export type NotificationsActions =
  | NotificationsRequested
  | NotificationsLoaded
  | NotificationRequested
  | NotificationLoaded
  | NotificationRemoveRequested
  | NotificationRemoved
  | NotificationReceived;
