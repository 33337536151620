// Actions
import { AuthActions, AuthActionTypes } from '../actions/auth.actions';
// Models
import { User } from '../models/user.model';
import { Avatar } from '../models/avatar.model';
import { Subscription } from '../models/subscription.model';
import { UserActions, UserActionTypes } from '../actions/user.actions';
import { Profile } from '../models/profile.model';

export interface UserState {
  saveProfileStarted: boolean;
  saveProfileSuccess: boolean;
  saveProfileFailure: boolean;
  userProfile: Profile;
}

export const initialUserState: UserState = {
  saveProfileStarted: false,
  saveProfileSuccess: false,
  saveProfileFailure: false,
  userProfile: undefined,
};

export function userReducer(
  state = initialUserState,
  action: UserActions
): UserState {
  switch (action.type) {
    case UserActionTypes.SaveProfile: {
      return {
        ...state,
        saveProfileFailure: false,
        saveProfileStarted: true,
        saveProfileSuccess: false,
      };
    }

    case UserActionTypes.ProfileLoaded: {
      const profile = { ...action.payload.profile };
      delete profile['user'];
      return {
        ...state,
        userProfile: profile,
      };
    }

    case UserActionTypes.SaveProfileSuccess: {
      return {
        ...state,
        saveProfileFailure: false,
        saveProfileStarted: false,
        saveProfileSuccess: true,
      };
    }

    case UserActionTypes.SaveProfileFailure: {
      return {
        ...state,
        saveProfileFailure: true,
        saveProfileStarted: false,
        saveProfileSuccess: false,
      };
    }

    default:
      return state;
  }
}
