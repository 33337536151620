import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import {
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import normalize from 'json-api-normalizer';
import build from 'redux-object';
import {
  NotificationLoaded,
  NotificationRemoved,
  NotificationRemoveRequested,
  NotificationRequested,
  NotificationsActionTypes,
  NotificationsLoaded,
  NotificationsRequested,
} from '../actions/notifications.actions';
import { RealtimeService } from '../services/realtime.service';
import { currentNotifications } from '../selectors/notifications.selectors';
import { combineLatest, of } from 'rxjs';

@Injectable()
export class NotificationsEffects {
  loadNotifications$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<NotificationsRequested>(
          NotificationsActionTypes.NotificationsRequested
        ),
        // withLatestFrom(this.store.pipe(select(currentUserIdentity))),
        // filter(([action, _user]) => (_user ? true : false)),
        mergeMap((action) =>
          this.realtimeService.getNotifications(action.payload.userId)
        ),
        tap(
          (apiResponse) => {
            // console.log('Notifications Requested');
            if (apiResponse) {
              // console.log(apiResponse, 'Notifications returned from service');

              const data: any = normalize(apiResponse);
              // console.log(data);
              const _notifications = build(data, 'notification', null, {
                eager: true,
              });
              // console.log(_notifications);

              this.store.dispatch(
                new NotificationsLoaded({
                  notifications: _notifications || [],
                })
              );
            } else {
              // console.log('No Notifications returned from Legge Service');
              // this.store.dispatch(new Logout());
            }
          },
          (error) => {
            // console.log('Service Error - Load Notifications');
            // this.store.dispatch(new Logout());
          }
        )
      ),
    { dispatch: false }
  );

  loadNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<NotificationRequested>(
          NotificationsActionTypes.NotificationRequested
        ),
        // withLatestFrom(this.store.pipe(select(currentUserIdentity))),
        // filter(([action, _user]) => (_user ? true : false)),
        mergeMap((action) =>
          this.realtimeService.getNotification(
            action.payload.notificationUniqueId
          )
        ),
        tap(
          (apiResponse) => {
            // console.log('Notification Requested');
            if (apiResponse) {
              // console.log('Notification returned from service');

              const data: any = normalize(apiResponse);
              // console.log(data);
              const _notification = build(
                data,
                'notification',
                apiResponse.data.id,
                {
                  eager: true,
                }
              );
              // console.log(_notification);

              this.store.dispatch(
                new NotificationLoaded({
                  notification: _notification,
                })
              );
            } else {
              // console.log('No Notification returned from Legge Service');
              // this.store.dispatch(new Logout());
            }
          },
          (error) => {
            // console.log('Service Error - Load Notification');
            // this.store.dispatch(new Logout());
          }
        )
      ),
    { dispatch: false }
  );

  readNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<NotificationRemoveRequested>(
          NotificationsActionTypes.NotificationRemoveRequested
        ),
        mergeMap((action) =>
          this.realtimeService
            .readNotification(action.payload.notificationUniqueId)
            .pipe(
              concatLatestFrom((apiResponse) =>
                this.store.pipe(select(currentNotifications))
              ),

              tap(([apiResponse, _notifications]) => {
                if (apiResponse) {
                  const updatedList = _notifications.filter(
                    (elem) =>
                      elem.uniqueId !== action.payload.notificationUniqueId
                  );
                  this.store.dispatch(
                    new NotificationsLoaded({
                      notifications: updatedList,
                    })
                  );
                }
              })
            )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<AppState>,
    private realtimeService: RealtimeService
  ) {}
}
