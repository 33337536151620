import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  Assets23blocksOptions,
  ASSETS_23BLOCKS_SERVICE_OPTIONS,
} from './models/assets.interfaces';
import { Assets23blocksInterceptor } from './interceptors/assets.interceptors';
import { AssetsService } from './services/assets.service';
import { AssetsEffects } from './effects/assets.effects';
import { assetsReducer } from './reducers/assets.reducers';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('assets', assetsReducer),
    EffectsModule.forFeature([AssetsEffects]),
  ],
})
export class Assets23blocksModule {
  constructor(@Optional() @SkipSelf() parentModule: Assets23blocksModule) {
    if (parentModule) {
      throw new Error(
        "Assets23blocksModule is already loaded. It should only be imported in your application's main module."
      );
    }
  }
  static forRoot(
    options: Assets23blocksOptions
  ): ModuleWithProviders<Assets23blocksModule> {
    return {
      ngModule: Assets23blocksModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: Assets23blocksInterceptor,
          multi: true,
        },
        options.AssetsOptionsProvider || {
          provide: ASSETS_23BLOCKS_SERVICE_OPTIONS,
          useValue: options,
        },
        AssetsService,
      ],
    };
  }
}
