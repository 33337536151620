import { InjectionToken, Provider } from '@angular/core';

export interface Assets23blocksOptions {
  AssetsOptionsProvider?: Provider;
  apiBase?: string;
  apiPath?: string;
  APPID: string;
}

export const ASSETS_23BLOCKS_SERVICE_OPTIONS = new InjectionToken(
  'ASSETS_23BLOCKS_SERVICE_OPTIONS'
);

export interface UserData {
  name: string;
  email: string;
  phone: string;
  user_unique_id: string;
  role_name: string;
  role_unique_id: string;
}

export type Status =
  | 'new'
  | 'active'
  | 'suspended'
  | 'incomplete'
  | 'incomplete_expired'
  | 'trialing'
  | 'past_due'
  | 'canceled'
  | 'unpaid'
  | 'cancelling'
  | 'payment_failed'
  | 'canceled';

export interface UserIdentityData {
  user_unique_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  role_name: string;
  role_unique_id: string;
  email_notifications?: boolean;
  sms_notifications?: boolean;
  whatsapp_notifications?: boolean;
  other_notifications?: boolean;
}

export interface AssetData {
  name: string;
  serial: string;
  asset_type?: string;

  description?: string;
  thumbnail_url?: string;
  image_url?: string;
  content_url?: string;
  media_url?: string;
  icon_url?: string;
  price?: string;
  tax?: string;
  tax_value?: string;
  price_with_fees?: string;
  price_with_taxes?: string;
  total_price?: string;
  payload?: string;
  tags?: string;
  owner_unique_id?: string;
  owner_name?: string;
  qcode?: string;
  vendor_unique_id?: string;
  vendor_code?: string;
  vendor_name?: string;
  warehouse_unique_id?: string;
  warehouse_code?: string;
  warehouse_name?: string;
  source?: string;
  source_alias?: string;
  source_id?: string;
  source_type?: string;

  brand_unique_id?: string;
  brand_code?: string;
  brand_name?: string;
  brand_image_url?: string;

  model_unique_id?: string;
  model_code?: string;
  model_description?: string;
  model_image_url?: string;

  production_at?: string;
  batch?: string;
  origin?: string;

  notes?: string;

  manual_entry_uses?: string | number;
  system_uses?: string | number;
  total_uses?: string | number;

  next_service?: string;

  assigned_to_name?: string;
  assigned_to_unique_id?: string;
  maintenance_status?: string;

  lifespan?: number;
  mtbf?: number;
  up_time?: number;
  down_time?: number;

  slug?: string;
  meta_title?: string;
  token?: string;
  total_tokens?: string;
}

export interface PresignUploadData {
  filename: string;
}
export interface AWSUrl {
  presigned_url: string;
  public_url: string;
  signed_url: string;
  file_name: string;
}

export interface File23BlocksData {
  // unique_id: string;
  // user_identity_unique_id: string;
  // user_unique_id: string;
  // status: string;
  // enabled: string;
  // bucket: string;
  name: string;
  url: string;
  thumbnail_url: string;
  file_type: string;
  file_size: string | number;
  description: string;
  original_name: string;
  original_file: string;
  category_unique_id: string;
  category_name?: string;
  // created_by: string;
  // updated_by: string;
  payload: any;
  tags?: any;
}

export type SearchParams = {
  query: string;
  page: number;
  perPage: number;
  searchType?: string;
  assignedToUniqueId?: string;
  sourceExclude?: string;
};

export interface AssetEventData {
  event_name: string;
  event_code: string;
  event_at: string;
  category_unique_id: string;
  vendor_unique_id: string;
  vendor_name: string;
  warehouse_unique_id: string;
  extra_info: string;
  notes: string;
  labor_cost: number;
  waiting_time: string;
  working_time: string;
  started_at: string;
  finished_at: string;
  next_at: string;
  responsible_unique_id: string;
  responsible_name: string;
  responsible_charge: number;
  payload: string;
}

export type AssetEventType =
  | 'removed_from'
  | 'part_removed'
  | 'new_part_added'
  | 'assigned_to'
  | 'asset_deleted'
  | 'asset_updated'
  | 'new_asset'
  | 'non_scheduled_service'
  | 'scheduled_service';

export interface RegisterFileData {
  name: string;
  url: string;
  thumbnail_url: string;
  file_type: string;
  file_size: number;
  description: string;
  original_name: string;
  original_file: string;
  is_public: boolean;
  is_main_image: boolean;
}

export interface VendorData {
  code: string;
  name: string;
  email: string;
  phone: string;
  contact_name: string;
  thumbnail_url: string;
  image_url: string;
  content_url: string;
  media_url: string;
  payload: string;
  slug: string;
  unique_id: string;
}

export type MaintenanceStatus =
  | 'in_use'
  | 'in_review'
  | 'scheduled'
  | 'unscheduled'
  | 'on_hold'
  | 'pending'
  | 'in_repair'
  | 'completed'
  | 'canceled';

export interface LendAssetData {
  target_user_unique_id: string;
  period: number;
  otp_code: string;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;
}

export interface OperationData {
  name: string;
  description: string;
  notes: string;
  operation_date: string;
  operation_started_at: string;
  operation_ended_at: string;
  operator_name: string;
  operator_email: string;
  operator_phone: string;
  operator_unique_id: string;
  crew_name?: string;
  crew_unique_id?: string;
  payload?: string;
  operation_cost: string;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;

  operation_minutes: number;
  operation_units: string;
  operation_units_system: string;
}

export interface EventReportData {
  serial: string;
  event_name: string;
  event_code: string;
  category_unique_id: string;
  vendor_unique_id: string;
  warehouse_unique_id: string;
  start_at: string;
  end_at: string;
  group_by: string;
}
