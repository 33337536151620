import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SalesState } from '../reducers/sales.reducers';

export const selectSalesState = createFeatureSelector<SalesState>('sales');

export const selectUserIdentity = createSelector(
  selectSalesState,
  (salesState) => salesState.userIdentity
);
export const isIdentityLoaded = createSelector(
  selectSalesState,
  (salesState) => salesState.isIdentityLoaded
);

export const selectPaymentStatus = createSelector(
  selectSalesState,
  (salesState) => salesState.paymentStatus
);

export const isPaymentStatusLoaded = createSelector(
  selectSalesState,
  (salesState) => salesState.isPaymentStatusLoaded
);
