import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';
import { environment } from 'src/environments/environment';
import {
  AuthActionTypes,
  currentAuthUser,
  selectRoleById,
  User,
  UserLoaded,
} from '../../gateway';
import {
  CreateIdentity,
  CreateSubscription,
  IdentityLoaded,
  IdentityRequested,
  PaymentStatusLoaded,
  PaymentStatusRequested,
  SalesActionTypes,
} from '../actions/sales.actions';
import { SalesService } from '../services/sales.service';
import { NewSubscriptionData, UserData } from '../models/sales.interfaces';
import { selectUserIdentity } from '../selectors/sales.selectors';
import { Subscription } from '../models/subscription.model';
import { of } from 'rxjs';
import {
  currentAuthSubscription,
  selectAuthState,
} from 'src/app/core/23blocks/gateway/selectors/auth.selectors';
import {
  getRoleNameForIdentity,
  getRoleUniqueId,
} from 'src/app/core/tools/helper-functions';
import { normalizeApiResponse } from 'src/app/common/utils/utils';
import { UserIdentity } from '../models/user-identity.model';

@Injectable()
export class SalesEffects {
  loadIdentity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<IdentityRequested>(SalesActionTypes.IdentityRequested),
        concatLatestFrom((action) => this.store.select(currentAuthUser)),
        filter(([action, user]) => [3, 4].includes(user.roleId)),
        mergeMap(([action, user]) => {
          console.log('Fetching sales Identity');
          return this.sales.getUserIdentity(user.uniqueId);
        }),
        tap(
          (apiResponse) => {
            if (apiResponse.data) {
              console.log('Ya existe identidad sales');

              const _userIdentity = normalizeApiResponse(
                apiResponse,
                'userIdentity',
                false
              ) as UserIdentity;
              this.store.dispatch(
                new IdentityLoaded({
                  userIdentity: _userIdentity,
                })
              );
            } else {
              console.log('No existe identidad sales');
              this.store.dispatch(new CreateIdentity());
            }
          },
          (error) => {
            console.log('Error fetching identidad sales');
            // this.store.dispatch(
            //   new IdentityLoaded({ userIdentity: null, firstTime: false })
            // );
            this.store.dispatch(new CreateIdentity());
          }
        )
      ),
    { dispatch: false }
  );

  createIdentity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CreateIdentity>(SalesActionTypes.CreateIdentity),
        concatLatestFrom((action) => this.store.select(currentAuthUser)),
        switchMap(([action, user]) => {
          const userData = this.buildUserData(user);
          return this.sales.createUserIdentity(user.uniqueId, userData);
        }),
        tap(
          (apiResponse) => {
            console.log(apiResponse, 'Se agrego identidad sales');
            const _userIdentity = normalizeApiResponse(
              apiResponse,
              'userIdentity',
              false
            ) as UserIdentity;
            this.store.dispatch(
              new IdentityLoaded({ userIdentity: _userIdentity })
            );
          },
          (error) => {
            console.log(error, 'Error al agregar identidad sales');
          }
        )
      ),
    { dispatch: false }
  );

  buildUserData(user: User): UserData {
    const role_name = getRoleNameForIdentity(user.roleId);
    const role_unique_id = getRoleUniqueId(user.roleId);

    return {
      name: user.name || 'User Name',
      phone:
        user.phone ||
        (Math.floor(Math.random() * 9000000000) + 1000000000).toString(),
      email: user.email || 'example@email.com',
      user_unique_id: user.uniqueId,
      role_name,
      role_unique_id,
    };
  }

  checkPaymentStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<IdentityLoaded>(SalesActionTypes.IdentityLoaded),
        tap((action) => {
          console.log('payment status requested');
          // this.store.dispatch(new PaymentStatusRequested());
        })
      ),
    { dispatch: false }
  );

  requestPaymentStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PaymentStatusRequested>(SalesActionTypes.PaymentStatusRequested),
        concatLatestFrom((action) => this.store.select(selectUserIdentity))
        // mergeMap(([action, userIdentity]) => {
        //   console.log('payment status requested');
        //   const subscriptionId = environment.ADMIN_ROLE_UNIQUE_ID;
        //   return this.sales.getCompanySubscription(
        //     userIdentity.userUniqueId,
        //     subscriptionId
        //   );
        // }),
        // catchError((err) => of({ data: null })),
        // tap((apiResponse) => {
        //   console.log(apiResponse);
        //   if (apiResponse.data === null) {
        //     // this.store.dispatch(new CreateSubscription());
        //     this.store.dispatch(
        //       new PaymentStatusLoaded({ paymentStatus: 'not_active' })
        //     );
        //   } else {
        //     const data: any = normalize(apiResponse);
        //     const _companySubscription: CompanySubscription = build(
        //       data,
        //       'companySubscription',
        //       apiResponse.data.id,
        //       {
        //         eager: true,
        //       }
        //     );
        //     this.store.dispatch(
        //       new PaymentStatusLoaded({
        //         paymentStatus: _companySubscription.status,
        //       })
        //     );
        //     console.log('payment status loaded in payment status requested');
        //   }
        // })
      ),
    { dispatch: false }
  );

  // @Effect({ dispatch: false })
  // requestPaymentStatus$ = this.actions$.pipe(
  //   ofType<PaymentStatusRequested>(SalesActionTypes.PaymentStatusRequested),
  //   withLatestFrom(this.store.select(selectUserIdentity)),
  //   mergeMap(([action, userIdentity]) => {
  //     console.log('payment status requested');
  //     const subscriptionId = environment.ADMIN_ROLE_UNIQUE_ID;
  //     return this.sales.getUserSubscription(
  //       userIdentity.userUniqueId,
  //       subscriptionId
  //     );
  //   }),
  //   catchError((err) => of({ data: null })),
  //   tap((apiResponse) => {
  //     console.log(apiResponse);
  //     if (apiResponse.data === null) {
  //       this.store.dispatch(new CreateSubscription());
  //     } else {
  //       const data: any = normalize(apiResponse);
  //       const _userSubscription: Subscription = build(
  //         data,
  //         'userSubscription',
  //         apiResponse.data.id,
  //         {
  //           eager: true,
  //         }
  //       );
  //       this.store.dispatch(
  //         new PaymentStatusLoaded({ paymentStatus: _userSubscription.status })
  //       );
  //       console.log('payment status loaded in payment status requested');
  //     }
  //   })
  // );

  // @Effect({ dispatch: false })
  // createSubscription$ = this.actions$.pipe(
  //   ofType<CreateSubscription>(SalesActionTypes.CreateSubscription),
  //   withLatestFrom(
  //     this.store.select(currentAuthSubscription),
  //     this.store.select(selectUserIdentity)
  //   ),
  //   mergeMap(([action, subscription, userIdentity]) => {
  //     console.log(subscription);
  //     const subscriptionId = environment.ADMIN_ROLE_UNIQUE_ID;

  //     const subscriptionData: NewSubscriptionData = {
  //       subscription_number: subscription.subscriptionNumber,
  //       status: subscription.status,
  //     };
  //     return this.sales.createUserSubscription(
  //       userIdentity.userUniqueId,
  //       subscriptionId,
  //       subscriptionData
  //     );
  //   }),
  //   tap(
  //     (apiResponse) => {
  //       const data: any = normalize(apiResponse);
  //       const _userSubscription: Subscription = build(
  //         data,
  //         'userSubscription',
  //         apiResponse.data.id,
  //         {
  //           eager: true,
  //         }
  //       );
  //       this.store.dispatch(
  //         new PaymentStatusLoaded({ paymentStatus: _userSubscription.status })
  //       );
  //       console.log('payment status loaded in create subscripcion');
  //     },
  //     (error) => console.log('error creando subscripcion sales', error)
  //   )
  // );

  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UserLoaded>(AuthActionTypes.UserLoaded),
        tap((action) => {
          // this.store.dispatch(new IdentityRequested());
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private sales: SalesService,
    private store: Store<AppState>
  ) {}
}
