import { InjectionToken, Provider } from '@angular/core';

export interface Search23blocksOptions {
  SearchOptionsProvider?: Provider;
  apiBase?: string;
  apiPath?: string;
  APPID: string;
}

export const SEARCH_23BLOCKS_SERVICE_OPTIONS = new InjectionToken(
  'SEARCH_23BLOCKS_SERVICE_OPTIONS'
);

export interface UserIdentityData {
  user_unique_id: string;
  name: string;
  email: string;
  phone: string;
  role_name: string;
  role_unique_id: string;
}

export interface UserData {
  name: string;
  email: string;
  phone: string;
  status: string;
  user_unique_id: string;
  role_name: string;
  role_unique_id: string;
}

export interface SearchData {
  params: string;
  exclude: string;
  page: number;
  records: number;
}
