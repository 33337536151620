import { InjectionToken, Provider } from '@angular/core';

export interface Mooncheck23blocksOptions {
  MooncheckOptionsProvider?: Provider;
  apiBase?: string;
  apiPath?: string;
  APPID: string;
}

export const MOONCHECK_23BLOCKS_SERVICE_OPTIONS = new InjectionToken(
  'MOONCHECK_23BLOCKS_SERVICE_OPTIONS'
);

export interface AWSUrl {
  presigned_url: string;
  public_url: string;
  signed_url: string;
  file_name: string;
}

export interface UserIdentityData {
  user: Partial<UserData>;
  tag: TagData;
}

export interface UserData {
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  phone: string;
  avatar_url: string;
  user_unique_id: string;
  role_name: string;
  role_unique_id: string;
  payload: string;
  time_zone: string;
  preferred_language: string;
  email_notifications: string;
  sms_notifications: string;
  whatsapp_notifications: string;
  other_notifications: string;
  wallet_code: string;
}

export interface TagData {
  tag: string;
  unique_id: string;
}

export type SearchParams = {
  query: string;
  page: number;
  records: number;
};

export class ApiResponse {
  status?: string;
  success?: boolean;
  statusText?: string;
  errors?: any;
  data?: any;
  meta?: MetaData;
  include?: any;
}

export interface MetaData {
  totalRecords: number;
  totalPages: number;
}

// New interfaces for services

export interface ProjectData {
  name: string;
  website: string;
  description: string;
  completion: number;
  // project_images: any[];
  completion_comment: string;
  is_claimed: boolean;

  // social media ??

  risk_factors_url: string;
  announcements_url: string;
  milestones_url: string;
  discussions_url: string;
  contributions_url: string;
  recent_activity_url: string;

  creator_name: string;
  creator_unique_id: string;
  unique_id: string;

  payload: string;
}

export interface RiskFactorData {
  type: string; //Business,Token,Governance,Ecosystem,Legal,Industry
  title: string;
  description: string;
  content: string;
  risk_percentage: number;
  // images: any[];

  //   riskObjectives: RiskObjective[];

  //   sources: RiskFactorSource[];

  creator_name: string;
  creator_unique_id: string;
  unique_id: string;
  project_unique_id: string;
  project_name: string;
}

export interface RiskFactorSourceData {
  text: string;
  source_url: string;
  // sourceFiles: any[];

  creator_name: string;
  creator_unique_id: string;
  unique_id: string;
  project_unique_id: string;
  project_name: string;
  risk_factor_unique_id: string;
  risk_factor_name: string;
}

export interface RiskObjectiveData {
  title: string;
  description: string;
  content: string;
  status: string; //not yet started, completed, etc
  isCompleted: boolean;

  creator_name: string;
  creator_unique_id: string;
  unique_id: string;
  project_unique_id: string;
  project_name: string;
}

export interface ProjectAnnouncementData {
  title: string;
  description: string;
  content_url: string;
  announcement_type: string;

  creator_name: string;
  creator_unique_id: string;
  status: string;
  payload: string;
}

export interface ProjectMilestoneData {
  type: string;
  title: string;
  description: string;
  content_url: string;
  why_is_important: string;
  risk_and_challenges: string;
  delivery_date: string;
  status: string;

  //   milestoneUpdates: ProjectMilestoneStatus[];

  creator_name: string;
  creator_unique_id: string;
  unique_id: string;
  project_unique_id: string;
  project_name: string;
}

export interface ProjectMilestoneStatusData {
  status: string;
  title: string;
  description: string;
  date: string;

  creator_name: string;
  creator_unique_id: string;
  unique_id: string;
  project_unique_id: string;
  project_name: string;
  project_milestone_unique_id: string;
}

export interface ProjectContributionData {
  content: string;
  status: string; //accepted, rejected, etc

  contributor_name: string;
  contributor_unique_id: string;

  project_unique_id: string;
  project_name: string;
  risk_factor_unique_id: string;
  risk_factor_name: string;
}

// gets created on announcement, update, etc
export interface ProjectEventData {
  type: string; //announcement,update,response,etc
  date: string;
  title: string;
  description: string;
  content: string;

  unique_id: string;
  project_unique_id: string;
  project_name: string;
}

export interface ProjectDiscussionData {
  //   discussion_messages: ProjectDiscussionMessage[];
  title: string;
  description: string;
  content: string;

  likes: number;
  liked_by: any[];

  creator_name: string;
  creator_unique_id: string;
  unique_id: string;
  project_unique_id: string;
  project_name: string;
}

export interface ProjectDiscussionMessageData {
  content: string;

  likes: number;
  liked_by: any[];

  child_comments: any[];
  parent_unique_id: string;

  creator_name: string;
  creator_unique_id: string;
  unique_id: string;
  project_unique_id: string;
  project_name: string;
  discussion_unique_id: string;
  discussion_name: string;
}

export interface ProjectFileData {
  unique_id: string;
  project_unique_id: string;
  project_name: string;
  payload: string;
  status: string;
  bucket: string;
  name: string;
  url: string;
  thumbnail: string;
  file_type: string;
  file_size: string;
  description: string;
  original_name: string;
  original_file: string;
  virtual_folder: string;
  created_by: string;
  updated_by: string;
  is_public: string;
  ai_enabled: string;
  is_temp: string;
}

export interface UpdateProjectObjectiveData {
  content_url: string;
  color: string;
  is_completed: boolean;
  completed_at: string;
  payload: string;
  status: string;
  not_applicable: boolean;
  not_applicable_reason: string;
}

export interface ChatContextData {
  unique_id: string; //projectObjectiveId
  name: string; //objective name
  reference: string; //project name
  source: string;
  source_id: string;
  source_alias: string;
  source_type: string;
  payload: string;
}

export interface ChatMessageData {
  context_id: string;
  parent_id: string;
  content: string;
  source: string;
  source_alias: string;
  source_email: string;
  source_phone: string;
  target: string;
  target_alias: string;
  target_email: string;
  target_phone: string;
  value: number;
  data_source: string;
  data_source_id: string;
  data_source_type: string;
  data_source_alias: string;
  notification_content: string;
  notification_url: string;
  payload: string;
  target_id: string;
  target_device_id: string;
  target_type: string;
}
