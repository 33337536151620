export class Notification {
	constructor(notificationData: any) {
		this.id = notificationData.id;
		this.uniqueId = notificationData.unique_id;
		this.content = notificationData.content;
		this.sourceAlias = notificationData.source_alias;
		this.targetAlias = notificationData.target_alias;
		this.createdAt = notificationData.created_at;

		this.url = notificationData.url;
		this.status = notificationData.status;
		this.source = notificationData.source;
		this.sourceType = notificationData.source_type;
		this.sourceId = notificationData.source_id;

		this.targetId = notificationData.target_id;
		this.target = notificationData.target;
		this.targetType = notificationData.target_type;
		this.targetEmail = notificationData.target_email;
		this.targetPhone = notificationData.target_phone;

		this.createdBy = notificationData.created_by;
		this.updatedBy = notificationData.updated_by;
		this.updatedAt = notificationData.updated_at;
	}

	id: number;
	uniqueId: string;
	sourceAlias: string;
	targetAlias: string;
	content: string;
	createdAt: Date;
	// ADDED
	url: string;
	status: string;
	source: string;
	sourceType: string;
	sourceId: string;

	targetId: string;
	target: string;
	targetType: string;
	targetEmail: string;
	targetPhone: string;

	createdBy: string;
	updatedBy: string;
	updatedAt: Date;
}
