// NGRX
import { createSelector } from '@ngrx/store';

export const selectNotificationsState = (state) => state.notifications;

export const currentNotifications = createSelector(
  selectNotificationsState,
  (notificationsState) => notificationsState.notifications
);

export const currentNotification = createSelector(
  selectNotificationsState,
  (notificationsState) => notificationsState.notification
);
