import { Action } from '@ngrx/store';
import { UserIdentity } from '../models/user-identity.model';

export enum MooncheckActionTypes {
  IdentityRequested = '[Request User Mooncheck Identity] API',
  IdentityLoaded = '[Load User Mooncheck Identity] API',
  CreateIdentity = '[Create User Mooncheck Identity] API',
}

export class IdentityRequested implements Action {
  readonly type = MooncheckActionTypes.IdentityRequested;
}
export class IdentityLoaded implements Action {
  readonly type = MooncheckActionTypes.IdentityLoaded;
  constructor(public payload: { userIdentity: UserIdentity }) {}
}

export class CreateIdentity implements Action {
  readonly type = MooncheckActionTypes.CreateIdentity;
}

export type MooncheckActions =
  | IdentityRequested
  | IdentityLoaded
  | CreateIdentity;
