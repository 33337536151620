import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilesService } from './services/files.service';
import {
  Files23blocksOptions,
  FILES_23BLOCKS_SERVICE_OPTIONS,
} from './models/files.interface';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Files23blocksInterceptor } from './interceptors/files.interceptors';
import { FilesEffects } from './effects/files.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([FilesEffects])],
  providers: [FilesService],
})
export class Files23blocksModule {
  constructor(@Optional() @SkipSelf() parentModule: Files23blocksModule) {
    if (parentModule) {
      throw new Error(
        "Files23Blocks is already loaded. It should only be imported in your application's main module."
      );
    }
  }
  static forRoot(options: Files23blocksOptions): ModuleWithProviders<any> {
    return {
      ngModule: Files23blocksModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: Files23blocksInterceptor,
          multi: true,
        },
        options.filesOptionsProvider || {
          provide: FILES_23BLOCKS_SERVICE_OPTIONS,
          useValue: options,
        },
        FilesService,
      ],
    };
  }
}
