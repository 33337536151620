import { WalletActions, WalletActionTypes } from '../actions/wallet.actions';
import { Wallet } from '../models/wallet.model';

export interface WalletState {
  wallet: Wallet;
  isLoaded: boolean;
}

export const initialWalletState: WalletState = {
  wallet: null,
  isLoaded: false,
};

export function walletReducer(
  state = initialWalletState,
  action: WalletActions
): WalletState {
  switch (action.type) {
    case WalletActionTypes.WalletLoaded: {
      return {
        ...state,
        wallet: action.payload.wallet,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
}
