import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  Rewards23blocksOptions,
  REWARDS_23BLOCKS_SERVICE_OPTIONS,
} from './models/rewards.interfaces';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Rewards23blocksInterceptor } from './interceptors/rewards-23blocks.interceptor';
import { RewardsService } from './services/rewards.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class Rewards23blocksModule {
  constructor(@Optional() @SkipSelf() parentModule: Rewards23blocksModule) {
    if (parentModule) {
      throw new Error(
        "Rewards23Blocks is already loaded. It should only be imported in your application's main module."
      );
    }
  }
  static forRoot(
    options: Rewards23blocksOptions
  ): ModuleWithProviders<Rewards23blocksModule> {
    return {
      ngModule: Rewards23blocksModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: Rewards23blocksInterceptor,
          multi: true,
        },
        options.rewardsOptionsProvider || {
          provide: REWARDS_23BLOCKS_SERVICE_OPTIONS,
          useValue: options,
        },
        RewardsService,
      ],
    };
  }
}
