import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMap, tap } from 'rxjs/operators';
import {
  ProfileLoaded,
  SaveProfile,
  SaveProfileFailure,
  SaveProfileSuccess,
  UserActionTypes,
} from '../actions/user.actions';
import { GatewayService } from '../services/gateway.service';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
import { Profile } from '../models/profile.model';
import { AppState } from 'src/app/core/reducers';
@Injectable()
export class UserEffects {
  saveProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SaveProfile>(UserActionTypes.SaveProfile),
        mergeMap((action) =>
          this.gateway.addProfile(action.payload.profileData).pipe(
            tap(
              (apiResponse) => {
                this.store.dispatch(new SaveProfileSuccess());
                console.log(apiResponse);
                const data: any = normalize(apiResponse);
                const _userProfile: Profile = build(
                  data,
                  'userProfile',
                  apiResponse.data.id,
                  {
                    eager: true,
                  }
                );
                this.store.dispatch(
                  new ProfileLoaded({ profile: _userProfile })
                );
              },
              (err) => {
                console.log(err, 'error');
                this.store.dispatch(new SaveProfileFailure());
              }
            )
          )
        )
      ),
    { dispatch: false }
  );
  constructor(
    private actions$: Actions,
    private gateway: GatewayService,
    private store: Store<AppState>
  ) {}
}
