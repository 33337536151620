import { InjectionToken, Provider } from '@angular/core';

export interface Onboarding23blocksOptions {
  OnboardingOptionsProvider?: Provider;
  apiBase?: string;
  apiPath?: string;
  APPID: string;
}

export const ONBOARDING_23BLOCKS_SERVICE_OPTIONS = new InjectionToken(
  'ONBOARDING_23BLOCKS_SERVICE_OPTIONS'
);

export interface UserIdentityData {
  user_unique_id: string;
  name: string;
  email: string;
  phone: string;
  role_name: string;
  role_unique_id: string;
}

export interface UserData {
  name: string;
  email: string;
  phone: string;
  status?: string;
  user_unique_id: string;
  role_name: string;
  role_unique_id: string;
}

export interface OnboardingStepData {
  step_unique_id: string;
  step_params: string;
  source: string;
  source_id: number;
  source_type: string;
  source_alias: string;
  payload: string;
  step_status: string;
}

export interface OnboardingData {
  name: string;
  description: string;
  source: string;
  source_id: number;
  source_type: string;
  source_alias: string;
  total_steps: number;
  content_url: string;
  image_url: string;
  video_url: string;
  payload: string;
}

export interface NewOnboardingStepData {
  name: string;
  description: string;
  source: string;
  source_id: number;
  source_type: string;
  source_alias: string;
  order: number;
  step_url: string;
  step_params: string;
  content_url: string;
  image_url: string;
  video_url: string;
  payload: string;
}

export interface MailTemplateData {
  template_html: string;
  template_text: string;
  from_address: string;
  from_name: string;
  from_subject: string;
}
