import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { GeolocationService } from '../services/geolocation.service';

@Injectable()
export class Geolocation23blocksInterceptor implements HttpInterceptor {
  private headers: any;

  constructor(private geolocationService: GeolocationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.match(environment.API_23GEOLOCATION_URL)) {
      if (
        req.headers.get('appid') === null &&
        req.headers.get('company-token') === null
      ) {
        // Get auth data from local storage
        this.geolocationService.getAuthDataFromStorage();

        // Add the headers if the request is going to the configured server
        const authData = this.geolocationService.authData.value;

        if (authData) {
          this.headers = {
            'company-token': authData.companyToken,
            appid: authData.appid,
          };
        } else {
          this.headers = {
            appid: environment.APPID,
          };
        }

        req = req.clone({
          setHeaders: this.headers,
        });
      }
    } else return next.handle(req);

    return next
      .handle(req)
      .pipe(
        tap(
          (res) => this.handleResponse(res),
          (err) => this.handleResponse(err)
        )
      )
      .pipe(
        catchError((err) => {
          const errors = err.error
            ? err.error?.errors || {
                errors: [err.error?.message],
              } || { errors: [err.statusText] }
            : { ...err };
          return throwError(errors);
        })
      );
  }

  // Parse Auth data from response
  private handleResponse(
    res: HttpResponse<any> | HttpErrorResponse | HttpEvent<any>
  ): void {
    if (res instanceof HttpResponse || res instanceof HttpErrorResponse) {
      if (
        this.geolocationService.tokenOptions.apiBase === null ||
        (res.url && res.url.match(this.geolocationService.tokenOptions.apiBase))
      ) {
        // console.log('Successful response - Interceptor GeoLocation');
      }
    }
  }
}
