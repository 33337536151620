export class Avatar {
  id: string;
  status: string;
  bucket: string;
  originalName: string;
  name: string;
  url: string;
  thumbnail: string;
  fileType: Date;
  fileSize: Date;
  description: Date;
  originalFile: Date;
  updatedAt: string;

  clear(): void {
    this.id = undefined;
  }
}
