import { Action } from '@ngrx/store';
import { UserJourney } from '../models/user-journey.model';
import { OnboardingStepData } from '../models/onboarding.interfaces';
import { Onboarding } from '../models/onboarding.model';
import { UserIdentity } from '../models/user-identity.model';

export enum OnboardingActionTypes {
  UserJourneyRequested = '[Request User Journey] API',
  UserJourneyLoaded = '[Load User Journey] API',
  CreateUserJourney = '[Create User Journey] API',
  OnboardingConfigRequested = '[Request Onboarding Config] API',
  OnboardingConfigLoaded = '[Load Onboarding Config] API',
  IdentityRequested = '[Request Onboarding Identity] API',
  IdentityLoaded = '[Load User Onboarding Identity] API',
  OnboardingStepRequested = '[Request Onboarding Step] API',
  OnboardingStepLoaded = '[Load Onboarding Step] API',
  CreateIdentity = '[Check and Create Onboarding Identity] API',
  SendStep = '[Send Onboarding Step] API',
  OnboardingCompleted = '[Onboarding Completed] API',
  StartOnboardingSequence = '[Start Onboarding Sequence] API',
}
export class UserJourneyRequested implements Action {
  readonly type = OnboardingActionTypes.UserJourneyRequested;
}
export class UserJourneyLoaded implements Action {
  readonly type = OnboardingActionTypes.UserJourneyLoaded;
  constructor(
    public payload: { userJourney: UserJourney; isComplete: boolean }
  ) {}
}

export class CreateUserJourney implements Action {
  readonly type = OnboardingActionTypes.CreateUserJourney;
  constructor() {}
}

export class OnboardingConfigRequested implements Action {
  readonly type = OnboardingActionTypes.OnboardingConfigRequested;
}
export class OnboardingConfigLoaded implements Action {
  readonly type = OnboardingActionTypes.OnboardingConfigLoaded;
  constructor(public payload: { onboardingConfig: Onboarding }) {}
}

export class OnboardingStepRequested implements Action {
  readonly type = OnboardingActionTypes.OnboardingStepRequested;
}
export class OnboardingStepLoaded implements Action {
  readonly type = OnboardingActionTypes.OnboardingStepLoaded;
  // constructor(public payload: { userIdentity: UserIdentity }) {}
}

export class IdentityRequested implements Action {
  readonly type = OnboardingActionTypes.IdentityRequested;
}
export class IdentityLoaded implements Action {
  readonly type = OnboardingActionTypes.IdentityLoaded;
  constructor(
    public payload: { userIdentity: UserIdentity; firstTime: boolean }
  ) {}
}

export class CreateIdentity implements Action {
  readonly type = OnboardingActionTypes.CreateIdentity;
}

export class SendStep implements Action {
  readonly type = OnboardingActionTypes.SendStep;
  constructor(
    public payload: { stepId: string; status: string; params?: any }
  ) {}
}

export class OnboardingCompleted implements Action {
  readonly type = OnboardingActionTypes.OnboardingCompleted;
}

export class StartOnboardingSequence implements Action {
  readonly type = OnboardingActionTypes.StartOnboardingSequence;
}

export type OnboardingActions =
  | UserJourneyRequested
  | UserJourneyLoaded
  | OnboardingConfigRequested
  | OnboardingConfigLoaded
  | IdentityRequested
  | IdentityLoaded
  | OnboardingStepRequested
  | OnboardingStepLoaded
  | SendStep
  | OnboardingCompleted
  | StartOnboardingSequence
  | CreateUserJourney;
