import { Inject, Injectable } from '@angular/core';
import { AppState } from 'src/app/core/reducers';
import { Store, select } from '@ngrx/store';
import { selectAccessToken } from '../../gateway';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResponse } from '../../models/api-response.model';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { AuthToken } from '../../gateway/models/gateway.interfaces';
import {
  GrantBadgeData,
  CustomerRewardData,
  EventRuleData,
  LoyaltyTierData,
  LoyaltyTierDetailsData,
  MoneyRuleData,
  OfferCodeData,
  ProductRuleData,
  RedeemCouponData,
  RefundRewardData,
  RewardCouponData,
  Rewards23blocksOptions,
  RewardsData,
  REWARDS_23BLOCKS_SERVICE_OPTIONS,
  BadgeData,
  NewOfferCodeData,
} from '../models/rewards.interfaces';

@Injectable({
  providedIn: 'root',
})
export class RewardsService {
  public authData: BehaviorSubject<AuthToken> = new BehaviorSubject<AuthToken>(
    null
  );

  get tokenOptions(): Rewards23blocksOptions {
    return this.options;
  }

  set tokenOptions(options: Rewards23blocksOptions) {
    this.options = (Object as any).assign(this.options, options);
  }

  private options: Rewards23blocksOptions;

  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    @Inject(REWARDS_23BLOCKS_SERVICE_OPTIONS) config: any
  ) {
    const defaultOptions: Rewards23blocksOptions = {
      apiPath: null,
      apiBase: null,
      APPID: null,
    };

    const mergedOptions = (Object as any).assign(defaultOptions, config);
    this.options = mergedOptions;

    if (this.options.apiBase === null) {
      console.warn(
        `[Rewards 23Blocks] You have not configured 'apiBase', which may result in security issues. ` +
          `Please refer to the documentation at https://github.com/neroniaky/angular-token/wiki`
      );
    }
  }

  private checkAuthData(authData: AuthToken): boolean {
    if (
      authData.companyToken != null &&
      authData.accessToken != null &&
      authData.client != null &&
      authData.expiry != null &&
      authData.tokenType != null &&
      authData.uid != null &&
      authData.appid != null
    ) {
      if (this.authData.value != null) {
        return authData.expiry >= this.authData.value.expiry;
      }
      return true;
    }
    return false;
  }

  public getAuthDataFromStorage(): void {
    const authData: AuthToken = {
      companyToken: localStorage.getItem('companyToken'),
      accessToken: localStorage.getItem('accessToken'),
      client: localStorage.getItem('client'),
      expiry: localStorage.getItem('expiry'),
      tokenType: localStorage.getItem('tokenType'),
      uid: localStorage.getItem('uid'),
      appid: localStorage.getItem('appid'),
    };

    if (this.checkAuthData(authData)) {
      this.authData.next(authData);
    }
  }

  searchCustomers(
    query: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23REWARDS_URL + '/customers',
      httpOptions
    );
  }

  getCustomerLoyalty(userId: string): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.get(
      environment.API_23REWARDS_URL + '/customers/' + userId + '/loyalty',
      httpOptions
    );
  }

  getCustomerRewards(
    uniqueId?: string,
    query?: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    // If uniqueId exists, add slash, otherwise endpoint will be /customers/rewards
    uniqueId = uniqueId ? '/' + uniqueId : '';
    return this.http.get(
      environment.API_23REWARDS_URL + '/customers' + uniqueId + '/rewards',
      httpOptions
    );
  }

  getCustomerRewardsHistory(uniqueId: string): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.get(
      environment.API_23REWARDS_URL +
        '/customers/' +
        uniqueId +
        '/rewards/history',
      httpOptions
    );
  }

  getCustomerBadges(userId: string): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.get(
      environment.API_23REWARDS_URL + '/customers/' + userId + '/badges',
      httpOptions
    );
  }

  getCustomerCoupons(userId: string): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.get(
      environment.API_23REWARDS_URL + '/customers/' + userId + '/coupons',
      httpOptions
    );
  }

  getCustomerOfferCodes(userId: string): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.get(
      environment.API_23REWARDS_URL + '/customers/' + userId + '/offer_codes',
      httpOptions
    );
  }

  getTiers(
    query?: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23REWARDS_URL + '/loyalties/',
      httpOptions
    );
  }

  getLoyaltyTier(
    tierId: string,
    query?: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23REWARDS_URL + '/loyalties/' + tierId,
      httpOptions
    );
  }

  getLoyaltyTierProductRules(
    tierId: string,
    query?: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23REWARDS_URL +
        '/loyalties/' +
        tierId +
        '/rules/products',
      httpOptions
    );
  }

  getLoyaltyTierEventRules(
    tierId: string,
    query?: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23REWARDS_URL + '/loyalties/' + tierId + '/rules/events',
      httpOptions
    );
  }

  getLoyaltyTierMoneyRules(
    tierId: string,
    query?: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23REWARDS_URL + '/loyalties/' + tierId + '/rules/money',
      httpOptions
    );
  }

  getLoyaltyStats(tierId: string): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.get(
      environment.API_23REWARDS_URL + '/loyalties/' + tierId + '/stats',
      httpOptions
    );
  }

  addLoyaltyTier(loyaltyTierData: LoyaltyTierData): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.post(
      environment.API_23REWARDS_URL + '/loyalties/',
      { loyalty: loyaltyTierData },
      httpOptions
    );
  }

  updateLoyaltyTier(
    tierId: string,
    loyaltyTierData: LoyaltyTierData
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.put(
      environment.API_23REWARDS_URL + '/loyalties/' + tierId,
      { loyalty: loyaltyTierData },
      httpOptions
    );
  }

  addLoyaltyTierDetails(
    tierId: string,
    tierDetails: LoyaltyTierDetailsData
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.post(
      environment.API_23REWARDS_URL + '/loyalties/' + tierId + '/details',
      { loyalty_details: tierDetails },
      httpOptions
    );
  }

  updateLoyaltyTierDetails(
    tierId: string,
    tierDetailsId: string,
    tierDetails: LoyaltyTierDetailsData
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.put(
      environment.API_23REWARDS_URL +
        '/loyalties/' +
        tierId +
        '/details/' +
        tierDetailsId,
      { loyalty_details: tierDetails },
      httpOptions
    );
  }

  addMoneyRule(
    tierId: string,
    ruleData: MoneyRuleData
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.post(
      environment.API_23REWARDS_URL + '/loyalties/' + tierId + '/rules/money',
      { money_points: ruleData },
      httpOptions
    );
  }

  updateMoneyRule(
    tierId: string,
    ruleId: string,
    ruleData: MoneyRuleData
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.put(
      environment.API_23REWARDS_URL +
        '/loyalties/' +
        tierId +
        '/rules/money/' +
        ruleId,
      { money_points: ruleData },
      httpOptions
    );
  }

  addProductRule(
    tierId: string,
    ruleData: ProductRuleData
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.post(
      environment.API_23REWARDS_URL +
        '/loyalties/' +
        tierId +
        '/rules/products',
      { product_points: ruleData },
      httpOptions
    );
  }

  updateProductRule(
    tierId: string,
    ruleId: string,
    ruleData: ProductRuleData
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.put(
      environment.API_23REWARDS_URL +
        '/loyalties/' +
        tierId +
        '/rules/products/' +
        ruleId,
      { product_points: ruleData },
      httpOptions
    );
  }

  addEventRule(
    tierId: string,
    ruleData: EventRuleData
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.post(
      environment.API_23REWARDS_URL + '/loyalties/' + tierId + '/rules/events',
      { event_points: ruleData },
      httpOptions
    );
  }

  updateEventRule(
    tierId: string,
    ruleId: string,
    ruleData: EventRuleData
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.put(
      environment.API_23REWARDS_URL +
        '/loyalties/' +
        tierId +
        '/rules/events/' +
        ruleId,
      { event_points: ruleData },
      httpOptions
    );
  }

  addReward(customerReward: CustomerRewardData): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.post(
      environment.API_23REWARDS_URL + '/rewards',
      { reward: customerReward },
      httpOptions
    );
  }

  previewReward(customerReward: CustomerRewardData): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.post(
      environment.API_23REWARDS_URL + '/rewards/preview',
      { reward: customerReward },
      httpOptions
    );
  }

  grantBadge(badgeData: GrantBadgeData): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.post(
      environment.API_23REWARDS_URL + '/badge',
      { badge: badgeData },
      httpOptions
    );
  }

  redeemCoupon(couponData: RedeemCouponData): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.post(
      environment.API_23REWARDS_URL + '/coupon',
      { coupon: couponData },
      httpOptions
    );
  }

  redeemOfferCode(offerCodeData: OfferCodeData): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.post(
      environment.API_23REWARDS_URL + '/codes',
      { offer_code: offerCodeData },
      httpOptions
    );
  }

  refundReward(refundData: RefundRewardData): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.post(
      environment.API_23REWARDS_URL + '/refund',
      { refund: refundData },
      httpOptions
    );
  }

  enableRule(tierId: string, ruleId: string): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.put(
      environment.API_23REWARDS_URL +
        '/loyalties/' +
        tierId +
        '/rules/' +
        ruleId +
        '/enable',
      {},
      httpOptions
    );
  }

  disableRule(tierId: string, ruleId: string): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.put(
      environment.API_23REWARDS_URL +
        '/loyalties/' +
        tierId +
        '/rules/' +
        ruleId +
        '/disable',
      {},
      httpOptions
    );
  }

  getCoupons(
    query?: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23REWARDS_URL + '/coupons',
      httpOptions
    );
  }

  createCoupon(couponData: Partial<RewardCouponData>): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.post(
      environment.API_23REWARDS_URL + '/coupons',
      { coupon: couponData },
      httpOptions
    );
  }

  updateCoupon(
    couponId: string,
    couponData: Partial<RewardCouponData>
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23REWARDS_URL + '/coupons/' + couponId,
      { coupon: couponData }
    );
  }

  deleteCoupon(couponId: string): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23REWARDS_URL + '/coupons/' + couponId
    );
  }

  createBadge(badgeData: BadgeData): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.post(
      environment.API_23REWARDS_URL + '/badges',
      { badge: badgeData },
      httpOptions
    );
  }

  createOfferCode(offerCodeData: NewOfferCodeData): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.post(
      environment.API_23REWARDS_URL + '/offer_codes',
      { offer_code: offerCodeData },
      httpOptions
    );
  }

  previewCoupon(couponCode: string): Observable<ApiResponse> {
    return this.http.post(environment.API_23REWARDS_URL + '/coupon/preview', {
      coupon: { coupon_code: couponCode },
    });
  }
}
