import { InjectionToken, Provider } from '@angular/core';

export interface Rewards23blocksOptions {
  rewardsOptionsProvider?: Provider;
  apiBase?: string;
  apiPath?: string;
  APPID: string;
}

export const REWARDS_23BLOCKS_SERVICE_OPTIONS = new InjectionToken(
  'REWARDS_23BLOCKS_SERVICE_OPTIONS'
);

export interface ApiResponse {
  status?: string;
  success?: boolean;
  statusText?: string;
  data?: any;
  include?: any;
  errors?: any;
  meta?: any;
}

export interface LoyaltyTierData {
  code: string;
  name: string;
  next_tier: string;
  previous_tier: string;
  next_tier_unique_id: string;
  previous_tier_unique_id: string;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;
  payload: any;
}

export interface LoyaltyTierDetailsData {
  name: string;
  country_id: string;
  country_name: string;
  currency_unique_id: string;
  currency_code: string;
  currency_name: string;
  minimum_spend: number;
  maximum_spend: number;
  minimum_points: number;
  maximum_points: number;
  default_tier: boolean;
  is_global: boolean;
  payload?: any;
}

export interface RewardsData {
  product_unique_id: string;
  product_sku: string;
  product_code: string;
  product_name: string;
  currecy_unique_id: string;
  currency_code: string;
  currency_name: string;
  country_id: string;
  country_name: string;
  points: number;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;
}

export interface CustomerRewardData {
  customer_unique_id: string;
  customer_id: string;
  country_id: string;
  country_name: string;
  currency_unique_id: string;
  currency_code: string;
  currency_name: string;
  products: string;
  total_spent: number;
  explanation: string;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;
  event_unique_id: string;
  event_name: string;
}

export interface MoneyRuleData {
  currency_unique_id: string;
  currency_code: string;
  currency_name: string;
  country_id: string;
  country_name: string;
  spent: number;
  points: number;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;
  is_global: boolean;
  payload: string;
}

export interface ProductRuleData {
  is_full_catalog: boolean;
  product_unique_id: string;
  product_sku: string;
  product_code: string;
  product_name: string;
  product_excluded: string;
  currency_unique_id: string;
  currency_code: string;
  currency_name: string;
  is_global: boolean;
  country_id: string;
  country_name: string;
  points: number;
  multiplier: number;
  valid_from: string;
  valid_to: string;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;
  payload: string;
  spent: number;
  price_points: number;
}

export interface EventRuleData {
  currency_unique_id: string;
  currency_code: string;
  currency_name: string;
  is_global: boolean;
  country_id: string;
  country_name: string;
  country_excluded: string;
  event_unique_id: string;
  event_code: string;
  event_name: string;
  points: number;
  multiplier: number;
  valid_from: Date;
  valid_to: Date;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;
  payload: string;
}

export interface RewardProduct {
  unique_id: string;
  sku: string;
  code: string;
  name: string;
  spent: number;
}

export interface GrantBadgeData {
  customer_unique_id: string;
  customer_id: string;
  badge_unique_id: string;
  category_unique_id: string;
  category_name: string;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;
}

export interface RedeemCouponData {
  customer_unique_id: string;
  customer_id: string;
  coupon_code: string;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;
}

export interface OfferCodeData {
  customer_unique_id: string;
  customer_id: string;
  customer_email: string;
  code: string;
  delivery_method: string;
  transaction_code: string;
}

export interface RefundRewardData {
  customer_unique_id: string;
  customer_id: string;
  country_id: string;
  currency_code: string;
  total_spent: number;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;
  event_name: string;
}

export interface RewardCouponData {
  code: string;
  name: string;
  description: string;
  coupon_code: string;
  one_time: boolean;
  country_id: string;
  country_name: string;
  currency_unique_id: string;
  currency_code: string;
  currency_name: string;
  discount_money: number;
  additional_points: number;
  discount_percentage: number;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;
  payload: string;
  unique_value: string;

  expiration_date: string;
  minimum_purchase: string;
  excluded_products: string;
  product_unique_id: string;
  product_sku: string;
  product_code: string;
  product_name: string;
  brand_unique_id: string;
  brand_sku: string;
  brand_code: string;
  brand_name: string;
  all_catalog: boolean;
}

export interface BadgeData {
  code: string;
  name: string;
  points: number;
  image_url: string;
  content_url: string;
  media_url: string;
  thumbnail_url: string;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;
  payload: string;
  is_global: boolean;
  country_id: string;
  country_name: string;
  currency_unique_id: string;
  currency_code: string;
  currency_name: string;
}

export interface NewOfferCodeData {
  code: string;
  points: number;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;
  is_global: boolean;
  country_id: string;
  country_name: string;
  currency_unique_id: string;
  currency_code: string;
  currency_name: string;
}
