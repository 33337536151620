import { InjectionToken, Provider } from '@angular/core';

export interface Realtime23blocksOptions {
  realtimeOptionsProvider?: Provider;
  apiBase?: string;
  apiPath?: string;
  APPID: string;
}

export const REALTIME_23BLOCKS_SERVICE_OPTIONS = new InjectionToken(
  'REALTIME_23BLOCKS_SERVICE_OPTIONS'
);

export interface ApiResponse {
  status?: string;
  success?: boolean;
  statusText?: string;
  data?: any;
  include?: any;
  errors?: any;
  meta?: any;
}

export interface broadcastData {
  type: string;
  from?: string;
  to?: number;
  sdp?: string;
  candidate?: string;
}

export interface GroupMemberData {
  user_unique_id: string;
}

export interface GroupMemberDetailsData {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  user_unique_id: string;
}

// WIP
export interface GroupData {
  name: string;
  // type: 'board' | 'broadcast';
}

export interface ContextData {
  unique_id?: string;
  name: string;
  reference: string;
  source: string;
  source_id: string;
  source_type: string;
  source_alias: string;
  payload: string;
  // members: JSON.stringify(GroupMemberDetailsData[])
  members: string;
}

export interface SettingsData {
  mail_optin: boolean;
  sms_optin: boolean;
  whatsapp_optin: boolean;
  notifications_optin: boolean;
  location_optin: boolean;
  payload: string;
}

export interface MailTemplateData {
  template_html: string;
  template_text: string;
  from_address: string;
  from_name: string;
  from_subject: string;
}

export interface UserIdentityData {
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  phone: string;
  avatar_url?: string;
  user_unique_id: string;
  role_name: string;
  role_unique_id: string;
  payload?: string;
  stripe_id?: string;
  time_zone?: string;
  preferred_language?: string;
  email_notifications?: string;
  sms_notifications?: string;
  whatsapp_notifications?: string;
  other_notifications?: string;
}

export interface NewGroupMemberData {
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
}

export interface MessageData {
  context_id: string;
  parent_id: string;
  content: string;
  source: string;
  source_id: string;
  source_alias: string;
  target_type: string;
  target_id: string;
  source_email: string;
  source_phone: string;
  target: string;
  target_alias: string;
  source_type: string;
  target_email: string;
  target_phone: string;
  target_device_id: string;
  value: string;
  data_source: string;
  data_source_id: string;
  data_source_type: string;
  notification_content: string;
  notification_url: string;
  data_source_alias: string;
  payload: string;
}
