import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { RealtimeService } from '../services/realtime.service';
import { GatewayService } from '../../gateway';

@Injectable()
export class Realtime23blocksInterceptor implements HttpInterceptor {
  private headers: any;

  constructor(
    private realtimeService: RealtimeService,
    private tokenService: GatewayService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.match(environment.API_23REALTIME_URL)) {
      // Get auth data from local storage
      this.tokenService.getAuthDataFromStorage();

      // Add the headers if the request is going to the configured server
      const authData = this.tokenService.authData.value;

      if (authData) {
        this.headers = {
          'company-token': authData.companyToken,
          'access-token': authData.accessToken,
          client: authData.client,
          expiry: authData.expiry,
          'token-type': authData.tokenType,
          uid: authData.uid,
          appid: authData.appid,
        };
      } else {
        this.headers = {
          appid: environment.APPID,
        };
      }

      req = req.clone({
        setHeaders: this.headers,
      });
    } else return next.handle(req);

    return next
      .handle(req)
      .pipe(
        tap(
          (res) => this.handleResponse(res),
          (err) => this.handleResponse(err)
        )
      )
      .pipe(
        catchError((err) => {
          // if ([401, 403].indexOf(err.status) !== -1) {
          //   console.log('Security Error - Realtime');
          //   // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          //   console.log('Security Error - Interceptor Realtime');
          // }
          // const errors = err.error.errors || {
          //     errors: [err.error.message],
          //   } || { errors: [err.statusText] };
          // return throwError(errors);
          return throwError(err);
        })
      );
  }

  // Parse Auth data from response
  private handleResponse(
    res: HttpResponse<any> | HttpErrorResponse | HttpEvent<any>
  ): void {
    if (res instanceof HttpResponse || res instanceof HttpErrorResponse) {
      if (
        this.realtimeService.tokenOptions.apiBase === null ||
        (res.url && res.url.match(this.realtimeService.tokenOptions.apiBase))
      ) {
        // console.log('Successful response - Interceptor Realtime');
      }
    }
  }
}
