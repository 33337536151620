import { InjectionToken, Provider } from '@angular/core';

export interface GeoLocation23blocksOptions {
  geolocationOptionsProvider?: Provider;
  apiBase?: string;
  apiPath?: string;
  APPID: string;
}

export const GEOLOCATION_23BLOCKS_SERVICE_OPTIONS = new InjectionToken(
  'GEOLOCATION_23BLOCKS_SERVICE_OPTIONS'
);

export interface ApiResponse {
  status?: string;
  success?: boolean;
  statusText?: string;
  data?: any;
  include?: any;
  errors?: any;
  meta?: any;
}

export interface LocationData {
  owner_unique_id: string;
  owner_type: string;
  code: string;
  name: string;
  source: string;
  address_unique_id: string;
  area_unique_id: string;
  location_parent_id: string;
  latitude: number | string;
  longitude: number | string;
  payload: any;
}

export interface AddressData {
  owner_unique_id: string;
  owner_type: string;
  country_code: string;
  country_name: string;
  admin1_code: string;
  admin1_name: string;
  admin2_code: string;
  admin2_name: string;
  admin3_code: string;
  admin3_name: string;
  admin4_code: string;
  admin4_name: string;
  admin5_code: string;
  admin5_name: string;
  admin6_code: string;
  admin6_name: string;
  postal_code: string;
  address: string;
  premise: string;
  code: string;
  name: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  organization: string;
  latitude: string;
  longitude: string;
  qcode: string;
  payload: string;
}

export interface BookingData {
  payload: string;
  user_type: string;
  user_unique_id: string;
  check_in_time: string;
  check_out_time: string;
  // status: string;
}

export interface CheckInOrOutData {
  user_type: string;
  user_unique_id: string;
  device_unique_id: string;
  event_name: 'check_in' | 'check_out';
  event_time: string;
  payload?: string;
}
