import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';

import { WalletService } from '../services/wallet.service';
import { UserData, WalletData } from '../models/wallet.interfaces';
import {
  AllRolesLoaded,
  currentAuthUser,
  currentAuthUserId,
  RoleActionTypes,
  selectRoleById,
} from '../../gateway';
import {
  CreateWallet,
  WalletActionTypes,
  WalletLoaded,
  WalletRequested,
} from '../actions/wallet.actions';
import { normalizeApiResponse } from 'src/app/common/utils/utils';
import { Wallet } from '../models/wallet.model';

@Injectable()
export class WalletEffects {
  loadWallet$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<WalletRequested>(WalletActionTypes.WalletRequested),
        concatLatestFrom((action) => this.store.select(currentAuthUserId)),
        switchMap(([action, userId]) => this.wallet.getUserWallets(userId)),
        tap({
          next: (apiResponse) => {
            if (!apiResponse.data.length) {
              console.log('No existe wallet');
              this.store.dispatch(new CreateWallet());
              return;
            }
            const _wallets = normalizeApiResponse(
              apiResponse,
              'wallet',
              true
            ) as Wallet[];

            this.store.dispatch(
              new WalletLoaded({
                wallet: _wallets[0],
              })
            );
          },
          error: (error) => {
            console.log(error, 'Error fetching wallet');
            this.store.dispatch(new CreateWallet());
          },
        })
      ),
    { dispatch: false }
  );

  createWallet$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CreateWallet>(WalletActionTypes.CreateWallet),
        concatLatestFrom((action) => this.store.select(currentAuthUser)),
        switchMap(([action, user]) => {
          console.log('Creando Wallet...');
          return this.wallet.createWallet(
            user?.uniqueId,
            this.buildWalletData()
          );
        }),
        tap(
          (apiResponse) => {
            console.log(apiResponse, 'Se agrego wallet');
            const _wallet = normalizeApiResponse(
              apiResponse,
              'wallet',
              false
            ) as Wallet;
            this.store.dispatch(new WalletLoaded({ wallet: _wallet }));
          },
          (error) => {
            console.log(error, 'Error al agregar wallet');
          }
        )
      ),
    { dispatch: false }
  );

  buildWalletData(): WalletData {
    return { notes: 'xielo-wallet' };
  }

  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AllRolesLoaded>(RoleActionTypes.AllRolesLoaded),
        tap((action) => {
          // this.store.dispatch(new WalletRequested());
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private wallet: WalletService,
    private store: Store<AppState>,
    private router: Router
  ) {}
}
