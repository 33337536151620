import { Role } from '../models/role.model';
// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from 'src/app/core/tools/query-results.model';
import { ArrayTools } from 'src/app/core/tools/array-tools';
// State
import { RolesState } from '../reducers/role.reducers';
import * as fromRole from '../reducers/role.reducers';
import { each } from 'lodash';

export const selectRolesState = createFeatureSelector<RolesState>('roles');

export const selectRoleById = (roleId: number) =>
  createSelector(selectRolesState, (rolesState) => {
    return rolesState.entities[roleId];
  });

export const selectAllRoles = createSelector(
  selectRolesState,
  fromRole.selectAll
);

export const selectAllRolesIds = createSelector(
  selectRolesState,
  fromRole.selectIds
);

export const allRolesLoaded = createSelector(
  selectRolesState,
  (rolesState) => rolesState.isAllRolesLoaded
);

export const selectRolesPageLoading = createSelector(
  selectRolesState,
  (rolesState) => rolesState.listLoading
);

export const selectRolesActionLoading = createSelector(
  selectRolesState,
  (rolesState) => rolesState.actionsloading
);

export const selectLastCreatedRoleId = createSelector(
  selectRolesState,
  (rolesState) => rolesState.lastCreatedRoleId
);

export const selectRolesShowInitWaitingMessage = createSelector(
  selectRolesState,
  (rolesState) => rolesState.showInitWaitingMessage
);

export const selectQueryResult = createSelector(
  selectRolesState,
  (rolesState) => {
    const items: Role[] = [];
    each(rolesState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new ArrayTools();
    const result: Role[] = httpExtension.sortArray(
      items,
      rolesState.lastQuery.sortField,
      rolesState.lastQuery.sortOrder
    );

    return new QueryResultsModel(
      rolesState.queryResult,
      rolesState.queryRowsCount
    );
  }
);
