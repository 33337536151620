import { InjectionToken, Provider } from '@angular/core';

export interface CRM23blocksOptions {
  CRMOptionsProvider?: Provider;
  apiBase?: string;
  apiPath?: string;
  APPID: string;
}

export const CRM_23BLOCKS_SERVICE_OPTIONS = new InjectionToken(
  'CRM_23BLOCKS_SERVICE_OPTIONS'
);

export interface ApiResponse {
  status?: string;
  success?: boolean;
  statusText?: string;
  data?: any;
  include?: any;
  errors?: any;
  meta?: any;
}

export interface AccountDetailsData {
  phone_number: string;
  primary_email: string;
  web_site: string;
  twitter: string;
  fb: string;
  instagram: string;
  linkedin: string;
  youtube: string;
  blog: string;
  network_a: string;
  network_b: string;
  notes: string;
  tax_id: string;
  payload?: any;
}

export interface AccountData {
  account_detail: AccountDetailsData;
  label: string;
  name: string;
  code?: string;
  source?: string;
  source_alias?: string;
  source_id?: string;
  source_type?: string;

  preferred_domain?: string;
  preferred_language?: string;

  payload?: string;
  unique_id?: string;
}

export interface ContactData {
  first_name: string;
  last_name: string;
  primary_email: string;
  source_id: string;
  source: string;
  source_type: string;
  primary_phone: number;
  position: string;
}

export interface AddContactData {
  contact_unique_id: string;
}

export interface AccountLocationData {
  location_unique_id: string;
}

export interface PresignUploadData {
  filename: string;
}

export interface AccountDocumentData {
  bucket: string;
  original_name: string;
  name: string;
  url: string;
  image_url?: string;
  content_url?: string;
  media_url?: string;
  file_type: string;
  file_size: string;
  description?: string;
  original_file: string;
  category_name?: string;
  category_unique_id: string;
  payload: any;
  thumbnail_url?: string;
}

export interface NewTenantData {
  url_id?: string;
  code: string;
  name: string;
  preferred_domain: string;
  preferred_language: string;
  payload: string;
  secret?: string;
}

export interface LeadData {
  first_name: string;
  middle_name: string;
  last_name: string;
  lead_email: string;
  phone_number: string;
  source: string;
  source_type: string;
  source_alias: string;
  source_id: number | string;
  payload?: string;
}

export interface CategoryData {
  code: string;
  name: string;
  description: string;
  parent_id: string;
  image_url: string;
  content_url: string;
  display_order: string;
  payload: any;
}

export interface QuoteData {
  code: string;
  name: string;
  notes: string;
  content_url: string;
  budget: number;
  total: number;
  duration: number;
  duration_unit: number;
  duration_description: string;
  payload: string;
  next_action_at: string;
  contact_unique_id: string;
  owner_unique_id: string;
  owner_name: string;
  owner_email: string;
}

export interface FollowUpsData {
  code: string;
  notes: string;
  next_action_at: string;
  reminder: string;
  total: number;
  owner_uniqueId: string;
  owner_name: string;
  owner_email: string;
  duration: number;
  duration_unit: number;
  duration_description: string;
}

export interface AddQuoteData {
  quote_unique_id: string;
}

export interface AddFollowUpsData {
  follow_up_unique_id: string;
}

export interface OpportunityData {
  code: string;
  name: string;
  notes: string;
  content_url: string;
  budget: string;
  total: string;
  duration: string;
  duration_unit: string;
  duration_description: string;
  payload: string;
  next_action_at: string;
  owner_unique_id: string;
  owner_name: string;
  owner_email: string;
}
