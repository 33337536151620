import { Action } from '@ngrx/store';
import { Wallet } from '../models/wallet.model';

export enum WalletActionTypes {
  WalletRequested = '[Wallet Requested] API',
  WalletLoaded = '[Wallet Loaded] API',
  CreateWallet = '[Create Wallet] API',
}

export class WalletRequested implements Action {
  readonly type = WalletActionTypes.WalletRequested;
}

export class WalletLoaded implements Action {
  readonly type = WalletActionTypes.WalletLoaded;
  constructor(public payload: { wallet: Wallet }) {}
}

export class CreateWallet implements Action {
  readonly type = WalletActionTypes.CreateWallet;
}

export type WalletActions = WalletRequested | WalletLoaded | CreateWallet;
