import { Injectable, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { selectAccessToken } from '../../gateway';
import { AuthToken } from '../../gateway/models/gateway.interfaces';
import { isPlatformServer } from '@angular/common';

import { ApiResponse } from '../../models/api-response.model';
import {
  AssetData,
  AssetEventData,
  Assets23blocksOptions,
  ASSETS_23BLOCKS_SERVICE_OPTIONS,
  AWSUrl,
  EventReportData,
  File23BlocksData,
  LendAssetData,
  MaintenanceStatus,
  OperationData,
  PresignUploadData,
  RegisterFileData,
  SearchParams,
  UserIdentityData,
  VendorData,
} from '../models/assets.interfaces';
import { map } from 'rxjs/operators';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
import { Asset } from '../models/asset.model';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  public authData: BehaviorSubject<AuthToken> = new BehaviorSubject<AuthToken>(
    null
  );

  get tokenOptions(): Assets23blocksOptions {
    return this.options;
  }
  set tokenOptions(options: Assets23blocksOptions) {
    this.options = (Object as any).assign(this.options, options);
  }
  private options: Assets23blocksOptions;
  private appId: string;
  private companyToken: string;
  constructor(
    private http: HttpClient,
    @Inject(ASSETS_23BLOCKS_SERVICE_OPTIONS) config: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() private activatedRoute: ActivatedRoute,
    @Optional() private router: Router,
    private store: Store<AppState>
  ) {
    const defaultOptions: Assets23blocksOptions = {
      apiPath: null,
      apiBase: null,
      APPID: null,
    };
    const mergedOptions = (Object as any).assign(defaultOptions, config);
    this.options = mergedOptions;
    if (this.options.apiBase === null) {
      console.warn(
        `[Assets 23Blocks] You have not configured 'apiBase', which may result in security issues. ` +
          `Please refer to the documentation at https://github.com/neroniaky/angular-token/wiki`
      );
    }
  }
  private checkAuthData(authData: AuthToken): boolean {
    if (
      authData.companyToken != null &&
      authData.accessToken != null &&
      authData.client != null &&
      authData.expiry != null &&
      authData.tokenType != null &&
      authData.uid != null &&
      authData.appid != null
    ) {
      if (this.authData.value != null) {
        return authData.expiry >= this.authData.value.expiry;
      }
      return true;
    }
    return false;
  }

  public getAuthDataFromStorage(): void {
    const authData: AuthToken = {
      companyToken: localStorage.getItem('companyToken'),
      accessToken: localStorage.getItem('accessToken'),
      client: localStorage.getItem('client'),
      expiry: localStorage.getItem('expiry'),
      tokenType: localStorage.getItem('tokenType'),
      uid: localStorage.getItem('uid'),
      appid: localStorage.getItem('appid'),
    };

    if (this.checkAuthData(authData)) {
      this.authData.next(authData);
    }
  }

  createUserIdentity(
    userId: string,
    identityData: UserIdentityData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23ASSETS_URL + '/users/' + userId + '/register/',
      {
        user: identityData,
      }
    );
  }

  updateUserIdentity(
    userId: string,
    identityData: UserIdentityData
  ): Observable<ApiResponse> {
    return this.http.put(environment.API_23ASSETS_URL + '/users/' + userId, {
      user: identityData,
    });
  }

  getUserIdentities(
    query?: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(environment.API_23ASSETS_URL + '/users/', httpOptions);
  }

  getUserIdentity(userUniqueId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23ASSETS_URL + '/users/' + userUniqueId
    );
  }

  // Assets

  getAssets(searchParams: Partial<SearchParams>): Observable<ApiResponse> {
    const {
      query,
      page,
      perPage,
      searchType,
      assignedToUniqueId,
      sourceExclude,
    } = searchParams;
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(assignedToUniqueId && {
            assigned: assignedToUniqueId.toString(),
          }),
          ...(query && { search: query.toString() }),
          ...(page && { page: page.toString() }),
          ...(perPage && { records: perPage.toString() }),
          ...(searchType && { type: searchType.toString() }),
          ...(sourceExclude && {
            source_exclude: sourceExclude.toString(),
          }),
        },
      }),
    };

    return this.http.get(`${environment.API_23ASSETS_URL}/assets`, httpOptions);
  }

  getNormalizedAssets(
    searchParams: Partial<SearchParams>
  ): Observable<Asset[]> {
    return this.getAssets(searchParams).pipe(
      map((apiResponse) => {
        if (!apiResponse?.data?.length) return [];
        const data: any = normalize(apiResponse);
        const _assets: Asset[] =
          build(data, 'asset', apiResponse.data.id, {
            eager: true,
          }) || [];
        return _assets;
      })
    );
  }

  getUserAssets(
    userId: string,
    searchParams: Partial<SearchParams>
  ): Observable<ApiResponse> {
    const { query, page, perPage, searchType } = searchParams;
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
          ...(searchType && { ['type']: searchType.toString() }),
        },
      }),
    };
    return this.http.get(
      `${environment.API_23ASSETS_URL}/users/${userId}/assets`,
      httpOptions
    );
  }

  getNormalizedUserAssets(
    userId: string,
    searchParams: Partial<SearchParams>
  ): Observable<Asset[]> {
    return this.getUserAssets(userId, searchParams).pipe(
      map((apiResponse) => {
        if (!apiResponse?.data?.length) return [];
        const data: any = normalize(apiResponse);
        const _assets: Asset[] =
          build(data, 'asset', apiResponse.data.id, {
            eager: true,
          }) || [];
        return _assets;
      })
    );
  }

  getAsset(assetId: string): Observable<ApiResponse> {
    return this.http.get(environment.API_23ASSETS_URL + '/assets/' + assetId);
  }

  getNormalizedAsset(assetId: string): Observable<Asset> {
    return this.getAsset(assetId).pipe(
      map((apiResponse) => {
        if (!apiResponse?.data) return null;
        const data: any = normalize(apiResponse);
        const _asset: Asset =
          build(data, 'asset', apiResponse.data.id, {
            eager: true,
          }) || null;
        return _asset;
      })
    );
  }

  getAssetOperations(assetId: string): Observable<ApiResponse> {
    return this.http.get(
      `${environment.API_23ASSETS_URL}/assets/${assetId}/operations`
    );
  }

  getAssetOperation(
    assetId: string,
    operationId: string
  ): Observable<ApiResponse> {
    return this.http.get(
      `${environment.API_23ASSETS_URL}/assets/${assetId}/operations/${operationId}`
    );
  }

  createAssetOperation(
    assetId: string,
    operation: OperationData
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23ASSETS_URL}/assets/${assetId}/operations`,
      { operation }
    );
  }

  createAsset(
    asset: Partial<AssetData>,
    returnExisting?: boolean
  ): Observable<ApiResponse> {
    const queryParam = returnExisting ? '?show=true' : '';
    return this.http.post(
      environment.API_23ASSETS_URL + '/assets' + queryParam,
      {
        asset,
      }
    );
  }

  updateAsset(
    assetId: string,
    asset: Partial<AssetData>
  ): Observable<ApiResponse> {
    return this.http.put(environment.API_23ASSETS_URL + '/assets/' + assetId, {
      asset,
    });
  }

  deleteAsset(assetId: string): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23ASSETS_URL + '/assets/' + assetId
    );
  }

  getDeletedAssets(
    query?: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23ASSETS_URL + '/assets/trash/show',
      httpOptions
    );
  }

  addAssetToCategory(
    assetId: string,
    categoryId: string
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23ASSETS_URL + '/assets/' + assetId + '/categories',
      {
        categoryId,
      }
    );
  }

  // Events

  createAssetEvent(
    assetId: string,
    assetEvent: Partial<AssetEventData>
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23ASSETS_URL + '/assets/' + assetId + '/events',
      {
        asset_event: assetEvent,
      }
    );
  }

  getAssetEvent(assetId: string, eventId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23ASSETS_URL + '/assets/' + assetId + '/events/' + eventId
    );
  }

  getAssetEventsHistory(assetId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23ASSETS_URL + '/assets/' + assetId + '/events'
    );
  }

  // Child assets

  addParts(assetId: string, parts: string): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23ASSETS_URL + '/assets/' + assetId + '/parts',
      {
        asset: { parts },
      }
    );
  }

  deleteParts(assetId: string, parts: string): Observable<ApiResponse> {
    return this.http.request(
      'delete',
      environment.API_23ASSETS_URL + '/assets/' + assetId + '/parts',
      {
        body: {
          asset: { parts },
        },
      }
    );
  }

  // File upload

  // Assets

  // 1st step to aws
  presignUpload(
    assetId: string,
    presignUploadData: PresignUploadData
  ): Observable<AWSUrl> {
    return this.http.put<AWSUrl>(
      environment.API_23ASSETS_URL + '/assets/' + assetId + '/presign',
      presignUploadData
    );
  }

  // Upload file to amazon
  uploadFileAWS(aws_url: string, file: File): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': file.type,
      }),
    };
    return this.http.put(aws_url, file, httpOptions);
  }

  registerAssetImage(
    assetId: string,
    fileData: RegisterFileData
  ): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      environment.API_23ASSETS_URL + '/assets/' + assetId + '/images',
      { file: fileData }
    );
  }

  deleteAssetImage(assetId: string, fileId: string): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23ASSETS_URL + '/assets/' + assetId + '/images/' + fileId
    );
  }

  // Event Images

  // 1st step to aws
  presignUploadAssetEventImage(
    assetId: string,
    eventId: string,
    presignUploadData: PresignUploadData
  ): Observable<AWSUrl> {
    return this.http.put<AWSUrl>(
      `${environment.API_23ASSETS_URL}/assets/${assetId}/events/${eventId}/presign`,
      presignUploadData
    );
  }

  registerAssetEventImage(
    assetId: string,
    eventId: string,
    fileData: RegisterFileData
  ): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      `${environment.API_23ASSETS_URL}/assets/${assetId}/events/${eventId}/images`,
      { file: fileData }
    );
  }

  deleteAssetEventImage(
    assetId: string,
    eventId: string,
    fileId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23ASSETS_URL}/assets/${assetId}/events/${eventId}/images/${fileId}`
    );
  }

  /* Vendors */

  createVendor(vendor: VendorData): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.post(
      environment.API_23ASSETS_URL + '/vendors',
      { vendor },
      httpOptions
    );
  }

  updateVendor(vendorId: string, vendor: VendorData): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.put(
      environment.API_23ASSETS_URL + '/vendors/' + vendorId,
      { vendor },
      httpOptions
    );
  }

  deleteVendor(vendorId: string): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.delete(
      environment.API_23ASSETS_URL + '/vendors/' + vendorId,
      httpOptions
    );
  }

  getVendors(searchParams: Partial<SearchParams>): Observable<ApiResponse> {
    const { query, page, perPage, searchType } = searchParams;
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      `${environment.API_23ASSETS_URL}/vendors`,
      httpOptions
    );
  }

  // Maintenance Status

  updateMaintenanceStatus(
    assetId: string,
    status: MaintenanceStatus
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23ASSETS_URL}/assets/${assetId}/maintenance`,
      {
        asset: { maintenance_status: status },
      }
    );
  }

  // Alerts

  getAlert(alertId: string): Observable<ApiResponse> {
    return this.http.get(`${environment.API_23ASSETS_URL}/alerts/${alertId}`);
  }

  removeAlert(alertId: string): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23ASSETS_URL}/alerts/${alertId}`
    );
  }

  // OTP

  requestOTPToken(assetId: string): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23ASSETS_URL}/assets/${assetId}/otp`,
      {}
    );
  }

  // Lend asset

  lendAsset(
    assetId: string,
    assetData: Partial<LendAssetData>
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23ASSETS_URL}/assets/${assetId}/lend`,
      {
        asset_lend: {
          ...assetData,
          source: '23blocks Assets',
          source_alias: 'Xielo Web App Assets',
          source_id: '',
          source_type: 'assets',
        },
      }
    );
  }

  // Asset Audits

  getAssetAudits(
    assetId: string,
    searchParams: Partial<SearchParams>
  ): Observable<ApiResponse> {
    const { query, page, perPage } = searchParams;
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      `${environment.API_23ASSETS_URL}/${assetId}/audits`,
      httpOptions
    );
  }

  getAssetAudit(assetId: string, auditId: string): Observable<ApiResponse> {
    return this.http.get(
      `${environment.API_23ASSETS_URL}/${assetId}/audits/${auditId}`
    );
  }

  createAssetAudit(assetId: string, audit: any): Observable<ApiResponse> {
    return this.http.post(`${environment.API_23ASSETS_URL}/${assetId}/audits`, {
      audit,
    });
  }

  editAssetAudit(
    assetId: string,
    auditId: string,
    audit: any
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23ASSETS_URL}/${assetId}/audits/${auditId}`,
      {
        audit,
      }
    );
  }

  deleteAssetAudit(assetId: string, auditId: string): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23ASSETS_URL}/${assetId}/audits/${auditId}`
    );
  }

  // Event Reports

  getEventReportList(reportData: EventReportData): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23ASSETS_URL}/reports/events/list`,
      {
        query_params: { ...reportData },
      }
    );
  }

  getEventReportSummary(reportData: EventReportData): Observable<any> {
    return this.http.post(
      `${environment.API_23ASSETS_URL}/reports/events/summary`,
      {
        query_params: { ...reportData },
      }
    );
  }
}
