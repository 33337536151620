// Angular
import { Injectable } from '@angular/core';
// RxJS
import { of, Observable, defer, forkJoin } from 'rxjs';
import {
  mergeMap,
  map,
  withLatestFrom,
  filter,
  tap,
  take,
} from 'rxjs/operators';
// NGRX
import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
// CRUD
// Services
import { GatewayService } from '../services/gateway.service';
// State
import { AppState } from '../../../reducers';
// Selectors
import {
  isAccessGranted,
  isCompaniesLoaded,
  isCompanyLoaded,
} from '../selectors/company.selectors';
// Actions
import {
  AccessGranted,
  AccessRequested,
  CompaniesLoaded,
  CompaniesRequested,
  CompanyActionTypes,
  CompanyLoaded,
  CompanyRequested,
  // RoleActionTypes,
  // RolesPageRequested,
  // RolesPageLoaded,
  // RoleUpdated,
  // RolesPageToggleLoading,
  // RoleDeleted,
  // RoleOnServerCreated,
  // RoleCreated,
  // RolesActionToggleLoading
} from '../actions/company.actions';
import { Company } from '../models/company.model';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
import { Router } from '@angular/router';
import { currentAuthUser } from '../selectors/auth.selectors';
import { AuthActionTypes, Login, UserLoaded } from '../actions/auth.actions';

@Injectable()
export class CompanyEffects {
  // showPageLoadingDistpatcher = new RolesPageToggleLoading({ isLoading: true });
  // hidePageLoadingDistpatcher = new RolesPageToggleLoading({ isLoading: false });
  //
  // showActionLoadingDistpatcher = new RolesActionToggleLoading({ isLoading: true });
  // hideActionLoadingDistpatcher = new RolesActionToggleLoading({ isLoading: false });

  loadAllCompanies$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CompaniesRequested>(CompanyActionTypes.CompaniesRequested),
        concatLatestFrom((action) =>
          this.store.pipe(select(isCompaniesLoaded))
        ),
        // filter(([action, isAllCompaniesLoaded]) => !isAllCompaniesLoaded),
        mergeMap(([action, isAllCompaniesLoaded]) =>
          this.auth.getCompanies(action.payload.userId)
        ),
        map((authApiResponse) => {
          if (!authApiResponse?.data) return null;
          const data: any = normalize(authApiResponse);
          const _companies: Company[] =
            build(data, 'company', null, { eager: true }) || [];
          if (!_companies?.length) return null;
          return new CompaniesLoaded({ companies: _companies });
        }),
        filter((action) => !!action)
      ),
    { dispatch: true }
  );

  requestCompany$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CompaniesLoaded>(CompanyActionTypes.CompaniesLoaded),
        map(
          (action) =>
            new CompanyRequested({ urlId: action.payload.companies[0].urlId })
        )
      ),
    { dispatch: true }
  );

  loadCompany$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CompanyRequested>(CompanyActionTypes.CompanyRequested),
        mergeMap((action) => this.auth.getCompany(action.payload.urlId)),
        map((authApiResponse) => {
          if (!authApiResponse?.data) return null;
          const data: any = normalize(authApiResponse);
          const _company: Company =
            build(data, 'company', authApiResponse.data.id, {
              eager: true,
            }) || null;
          return _company;
        }),
        concatLatestFrom((company) => this.store.select(currentAuthUser)),
        map(([company, user]) => {
          if (!company) return null;
          return new CompanyLoaded({ company });
        }),
        filter((action) => !!action)
      ),
    { dispatch: true }
  );

  requestAccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CompanyLoaded>(CompanyActionTypes.CompanyLoaded),
        concatLatestFrom((action) => this.store.select(currentAuthUser)),
        map(([action, user]) => {
          return new AccessRequested({
            appId: action.payload.company.apiAccessKey,
            appUrl: action.payload.company.urlId,
            userData: {
              appid: action.payload.company.apiAccessKey,
              email: user.email,
              provider: 'email',
              unique_id: user.uniqueId,
              uid: user.uid,
            },
          });
        })
      ),
    { dispatch: true }
  );

  grantAccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AccessRequested>(CompanyActionTypes.AccessRequested),
        // withLatestFrom(this.store.pipe(select(isAccessGranted))),
        // filter(([action, _isAccessGranted]) => !_isAccessGranted),
        mergeMap((action) =>
          this.auth.accessRequest(
            action.payload.appId,
            action.payload.appUrl,
            action.payload.userData
          )
        ),
        map((authApiResponse) => {
          // console.log('Access Requested');
          if (!authApiResponse?.data) return null;

          // console.log('Access Response');
          const data: any = normalize(authApiResponse);
          const _access = build(data, 'access', authApiResponse.data.id, {
            eager: true,
          });
          // console.log(_access);
          return new AccessGranted({
            appId: _access.appId,
            companyToken: _access.companyToken,
          });
        }),
        filter((action) => !!action)
      ),
    { dispatch: true }
  );
  // @Effect()
  // loadRolesPage$ = this.actions$
  //     .pipe(
  //         ofType<RolesPageRequested>(RoleActionTypes.RolesPageRequested),
  //         mergeMap(( { payload } ) => {
  //             this.store.dispatch(this.showPageLoadingDistpatcher);
  //             const requestToServer = this.auth.findRoles(payload.page);
  //             const lastQuery = of(payload.page);
  //             return forkJoin(requestToServer, lastQuery);
  //         }),
  //         map(response => {
  //             const result: QueryResultsModel = response[0];
  //             const lastQuery: QueryParamsModel = response[1];
  //             this.store.dispatch(this.hidePageLoadingDistpatcher);
  //
  //             return new RolesPageLoaded({
  //                 roles: result.items,
  //                 totalCount: result.totalCount,
  //                 page: lastQuery
  //             });
  //         }),
  //     );

  // @Effect()
  // deleteRole$ = this.actions$
  //     .pipe(
  //         ofType<RoleDeleted>(RoleActionTypes.RoleDeleted),
  //         mergeMap(( { payload } ) => {
  //                 this.store.dispatch(this.showActionLoadingDistpatcher);
  //                 return this.auth.deleteRole(payload.id);
  //             }
  //         ),
  //         map(() => {
  //             return this.hideActionLoadingDistpatcher;
  //         }),
  //     );

  // @Effect()
  // updateRole$ = this.actions$
  //     .pipe(
  //         ofType<RoleUpdated>(RoleActionTypes.RoleUpdated),
  //         mergeMap(( { payload } ) => {
  //             this.store.dispatch(this.showActionLoadingDistpatcher);
  //             return this.auth.updateRole(payload.role);
  //         }),
  //         map(() => {
  //             return this.hideActionLoadingDistpatcher;
  //         }),
  //     );

  // @Effect()
  // createRole$ = this.actions$
  //     .pipe(
  //         ofType<RoleOnServerCreated>(RoleActionTypes.RoleOnServerCreated),
  //         mergeMap(( { payload } ) => {
  //             this.store.dispatch(this.showActionLoadingDistpatcher);
  //             return this.auth.createRole(payload.role).pipe(
  //                 tap(res => {
  //                     this.store.dispatch(new RoleCreated({ role: res }));
  //                 })
  //             );
  //         }),
  //         map(() => {
  //             return this.hideActionLoadingDistpatcher;
  //         }),
  //     );

  // @Effect()
  // init$: Observable<Action> = defer(() => {
  //   return of(new AllCompaniesRequested());
  // });

  init$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UserLoaded>(AuthActionTypes.UserLoaded),
        tap((action) => {
          this.store.dispatch(
            new CompaniesRequested({ userId: action.payload?.user?.uniqueId })
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private auth: GatewayService,
    private store: Store<AppState>
  ) {}
}
