import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { CRMService } from 'src/app/core/23blocks/crm/services/crm.service';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
import { Router } from '@angular/router';
import { AuthActionTypes, UserLoaded } from 'src/app/core/23blocks/gateway';
import {
  AccountsLoaded,
  AccountsRequested,
  CRMActionsTypes,
} from '../actions/crm.actions';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Account } from '../models/account.model';

@Injectable()
export class CRMEffects {
  accountsRequested$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AccountsRequested>(CRMActionsTypes.AccountsRequested),
        mergeMap(() => this.crmService.getAccounts('', 1, 1000)),
        map((apiResponse) => {
          //   console.log(apiResponse)
          const data: any = normalize(apiResponse);
          const _accounts: Account[] = build(data, 'account', null, {
            eager: true,
          });
          // console.log(_accounts)
          return _accounts || [];
        }),
        tap((accounts) => this.store.dispatch(new AccountsLoaded({ accounts })))
      ),
    { dispatch: false }
  );

  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UserLoaded>(AuthActionTypes.UserLoaded),
        tap((action) => {
          // this.store.dispatch(new AccountsRequested());
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private crmService: CRMService,
    private store: Store<AppState>,
    private router: Router
  ) {}
}
