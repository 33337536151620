import { Injectable, Optional, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  HttpClient,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { isPlatformServer } from '@angular/common';

import { Observable, fromEvent, interval, BehaviorSubject, of } from 'rxjs';
import { pluck, filter, share, finalize } from 'rxjs/operators';

import {
  AcceptInvitationData,
  AssignSubscriptionData,
  CompanyDetailsData,
  ConfirmationData,
  CurrencyData,
  GATEWAY_23BLOCKS_SERVICE_OPTIONS,
  InvitationData,
  MailTemplateData,
  NewCompanyData,
  SearchParams,
  TenantNameData,
  UserToTenantData,
} from '../models/gateway.interfaces';

import {
  SignInData,
  NewRegistrationData,
  UpdatePasswordData,
  ResetPasswordData,
  AuthApiResponse,
  Gateway23blocksOptions,
  AuthToken,
  AvatarData,
  ProfileData,
  ImpersonalizationData,
} from '../models/gateway.interfaces';

import { User } from '../models/user.model';

import { Store, select } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { selectAccessToken } from '../selectors/company.selectors';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../models/api-response.model';

export interface AWSUrl {
  presigned_url: string;
  public_url: string;
  signed_url: string;
  file_name: string;
}
@Injectable({
  providedIn: 'root',
})
export class GatewayService {
  get tokenOptions(): Gateway23blocksOptions {
    return this.options;
  }

  set tokenOptions(options: Gateway23blocksOptions) {
    this.options = (Object as any).assign(this.options, options);
  }

  private options: Gateway23blocksOptions;
  public authData: BehaviorSubject<AuthToken> = new BehaviorSubject<AuthToken>(
    null
  );
  public userData: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private global: Window | any;

  private localStorage: Storage | any = {};

  public userEmail: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  get signOutPath(): string {
    return this.options.signOutPath;
  }

  get signInPath(): string {
    return this.options.signInPath;
  }

  // Impersonalization
  private appId: string;
  private companyToken: string;

  constructor(
    private http: HttpClient,
    @Inject(GATEWAY_23BLOCKS_SERVICE_OPTIONS) config: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() private activatedRoute: ActivatedRoute,
    @Optional() private router: Router,
    private store: Store<AppState>
  ) {
    //Impersonalization
    this.store.pipe(select(selectAccessToken)).subscribe((_accesstokens) => {
      // console.log(_accesstokens, 'GATEWAY SERVICE');
      this.appId = _accesstokens.appId;
      this.companyToken = _accesstokens.companyToken;
    });

    this.global = typeof window !== 'undefined' ? window : {};

    if (isPlatformServer(this.platformId)) {
      // Bad pratice, needs fixing
      this.global = {
        open: (): void => null,
        location: {
          href: '/',
          origin: '/',
        },
      };

      // Bad pratice, needs fixing
      this.localStorage.setItem = (): void => null;
      this.localStorage.getItem = (): void => null;
      this.localStorage.removeItem = (): void => null;
    } else {
      this.localStorage = localStorage;
    }

    const defaultOptions: Gateway23blocksOptions = {
      apiPath: null,
      apiBase: null,
      APPID: null,

      signInRedirect: 'auth/login',
      signInPath: 'auth/sign_in',
      signInStoredUrlStorageKey: null,

      signOutPath: 'auth/sign_out',
      validateTokenPath: 'auth/validate_token',
      signOutFailedValidate: false,

      registerAccountPath: 'auth',
      deleteAccountPath: 'auth',
      registerAccountCallback: this.global.location.href,

      updatePasswordPath: 'auth/password',
      addAvatarPath: '/users/avatar',

      resetPasswordPath: 'auth/password',
      resetPasswordCallback: this.global.location.href,

      loginField: 'email',

      rolesPath: 'roles',
      permissionsPath: 'permissions',

      oAuthBase: this.global.location.origin,
      oAuthPaths: {
        github: 'auth/github',
      },
      oAuthCallbackPath: 'oauth_callback',
      oAuthWindowType: 'newWindow',
      oAuthWindowOptions: null,
    };

    const mergedOptions = (Object as any).assign(defaultOptions, config);
    this.options = mergedOptions;

    if (this.options.apiBase === null) {
      console.warn(
        `[Auth 23Blocks] You have not configured 'apiBase', which may result in security issues. ` +
          `Please refer to the documentation at https://github.com/neroniaky/angular-token/wiki`
      );
    }

    this.tryLoadAuthData();
  }

  /**
   *
   * Actions
   *
   */

  // Register User
  registerUser(
    registrationData: NewRegistrationData
  ): Observable<AuthApiResponse> {
    this.clearAuthData();
    registrationData.confirm_success_url = this.options.registerAccountCallback;
    return this.http.post<AuthApiResponse>(
      this.getApiPath() + this.options.registerAccountPath,
      registrationData
    );
  }

  // Register another User
  registerUserNoAuthDataClear(
    registrationData: NewRegistrationData
  ): Observable<AuthApiResponse> {
    registrationData.confirm_success_url = this.options.registerAccountCallback;
    return this.http.post<AuthApiResponse>(
      this.getApiPath() + this.options.registerAccountPath,
      registrationData
    );
  }

  // Delete Account
  deleteAccount(): Observable<AuthApiResponse> {
    return this.http.delete<AuthApiResponse>(
      this.getServerPath() + this.options.deleteAccountPath
    );
  }

  // Sign in request and set storage
  signIn(
    signInData: SignInData,
    additionalData?: any
    // appId?: string,
  ): Observable<AuthApiResponse> {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //    // ...(appId && {APPID: appId}),
    //     // 'company-token': this.companyToken,
    //   }),
    // };
    this.clearAuthData();
    const body = {
      [this.options.loginField]: signInData.login,
      password: signInData.password,
    };

    return this.http.post<AuthApiResponse>(
      this.getServerPath() + this.options.signInPath,
      body
    );
  }

  signInOAuth(oAuthType: string) {
    const oAuthPath: string = this.getOAuthPath(oAuthType);
    const callbackUrl = `${this.global.location.origin}/${this.options.oAuthCallbackPath}`;
    const oAuthWindowType: string = this.options.oAuthWindowType;
    const authUrl: string = this.getOAuthUrl(
      oAuthPath,
      callbackUrl,
      oAuthWindowType
    );

    if (oAuthWindowType === 'newWindow') {
      const oAuthWindowOptions = this.options.oAuthWindowOptions;
      let windowOptions = '';

      if (oAuthWindowOptions) {
        for (const key in oAuthWindowOptions) {
          if (oAuthWindowOptions.hasOwnProperty(key)) {
            windowOptions += `,${key}=${oAuthWindowOptions[key]}`;
          }
        }
      }

      const popup = window.open(
        authUrl,
        '_blank',
        `closebuttoncaption=Cancel${windowOptions}`
      );
      return this.requestCredentialsViaPostMessage(popup);
    } else if (oAuthWindowType === 'sameWindow') {
      this.global.location.href = authUrl;
      return undefined;
    } else {
      throw new Error(`Unsupported oAuthWindowType "${oAuthWindowType}"`);
    }
  }

  processOAuthCallback(): void {
    this.getAuthDataFromParams();
  }

  // Sign out request and delete storage
  signOut(): Observable<AuthApiResponse> {
    return (
      this.http
        .delete<AuthApiResponse>(
          this.getServerPath() + this.options.signOutPath
        )
        // Only remove the localStorage and clear the data after the call
        .pipe(
          finalize(() => {
            this.clearAuthData();
          })
        )
    );
  }

  // Validate token request
  validateToken(): Observable<AuthApiResponse> {
    // console.log('Validate Token');
    return this.http.get<AuthApiResponse>(
      this.getServerPath() + this.options.validateTokenPath
    );
  }

  // Update password request
  updatePassword(
    updatePasswordData: UpdatePasswordData
  ): Observable<AuthApiResponse> {
    let args: any;

    if (updatePasswordData.passwordCurrent == null) {
      args = {
        password: updatePasswordData.password,
        password_confirmation: updatePasswordData.passwordConfirmation,
      };
    } else {
      args = {
        current_password: updatePasswordData.passwordCurrent,
        password: updatePasswordData.password,
        password_confirmation: updatePasswordData.passwordConfirmation,
      };
    }

    if (updatePasswordData.resetPasswordToken) {
      args.reset_password_token = updatePasswordData.resetPasswordToken;
    }

    const body = args;
    return this.http.put<AuthApiResponse>(
      this.getServerPath() + this.options.updatePasswordPath,
      body
    );
  }

  // Reset password request
  resetPassword(
    resetPasswordData: ResetPasswordData
  ): Observable<AuthApiResponse> {
    const body = {
      [this.options.loginField]: resetPasswordData.login,
      redirect_url: this.options.resetPasswordCallback,
    };

    return this.http.post<AuthApiResponse>(
      this.getServerPath() + this.options.resetPasswordPath,
      body
    );
  }

  // Avatar
  addAvatar(avatarData: AvatarData): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23GATEWAY_URL + this.options.addAvatarPath,
      {
        avatar: avatarData,
      }
    );
  }

  addUserAvatar(
    userUniqueId: string,
    avatarData: AvatarData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23GATEWAY_URL + '/users/' + userUniqueId + '/avatar',
      {
        avatar: avatarData,
      }
    );
  }

  getAvatar(userId: string): Observable<AuthApiResponse> {
    // console.log('Avatar Service');
    return this.http.get(
      environment.API_23GATEWAY_URL + '/users/' + userId + '/avatar'
    );
  }

  getAvatarImp(userId: string): Observable<AuthApiResponse> {
    // console.log('Avatar Service');
    const httpOptions = {
      headers: new HttpHeaders({
        // APPID: this.appId,
        // 'company-token': this.companyToken,
      }),
    };
    return this.http.get(
      environment.API_23GATEWAY_URL + '/users/' + userId + '/avatar',
      httpOptions
    );
  }

  getProfile(userId: string): Observable<AuthApiResponse> {
    // console.log('Avatar Service');
    const httpOptions = {
      headers: new HttpHeaders({
        // APPID: this.appId,
        // 'company-token': this.companyToken,
      }),
    };
    return this.http.get(
      environment.API_23GATEWAY_URL + '/users/' + userId + '/profile',
      httpOptions
    );
  }

  getDevices(
    userId: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        // APPID: this.appId,
        // 'company-token': this.companyToken,
      }),
      params: new HttpParams({
        fromObject: {
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23GATEWAY_URL + '/users/' + userId + '/devices',
      httpOptions
    );
  }

  // Profile
  addProfile(profileData: ProfileData): Observable<ApiResponse> {
    return this.http.post(environment.API_23GATEWAY_URL + '/users/profile', {
      profile: profileData,
    });
  }

  // Dashboard
  getDashboardURL(): Observable<AuthApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        // APPID: this.appId,
        // 'company-token': this.companyToken,
      }),
    };
    console.log('Dashboard Service Call');
    return this.http.get(
      environment.API_23GATEWAY_URL + '/dashboard',
      httpOptions
    );
  }

  // Add user profile

  addUserProfile(
    userId: string,
    profileData: Partial<ProfileData>
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23GATEWAY_URL + '/users/' + userId + '/profile',
      {
        profile: profileData,
      }
    );
  }

  // Edit profile

  editProfile(
    userId: string,
    profileData: Partial<ProfileData>
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23GATEWAY_URL + '/users/' + userId + '/profile',
      {
        profile: profileData,
      }
    );
  }

  // Permissions
  getAllPermissions(): Observable<AuthApiResponse> {
    // console.log('Permissions Service Call');
    return this.http.get<AuthApiResponse>(
      this.getServerPath() + this.options.permissionsPath
    );
  }

  getAllRoles(): Observable<AuthApiResponse> {
    // console.log('Roles Service Call');
    return this.http.get<AuthApiResponse>(
      this.getServerPath() + this.options.rolesPath
    );
  }

  public getRoles(page?: number, perPage?: number): Observable<ApiResponse> {
    console.log(this.appId, 'appid in service');
    const httpOptions = {
      headers: new HttpHeaders({
        APPID: this.appId,
        'company-token': this.companyToken,
      }),
      params: new HttpParams({
        fromObject: {
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23GATEWAY_URL + '/roles/',
      httpOptions
    );
  }

  getUser(userId: string): Observable<AuthApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        // APPID: this.appId,
        // 'company-token': this.companyToken,
      }),
    };
    return this.http.get(
      environment.API_23GATEWAY_URL + '/users/' + userId,
      httpOptions
    );
  }

  getUsers(
    query: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        // APPID: this.appId,
        // 'company-token': this.companyToken,
      }),
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23GATEWAY_URL + '/users/',
      httpOptions
    );
  }

  getSubscriptions(
    query?: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        // APPID: this.appId,
        // 'company-token': this.companyToken,
      }),
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23GATEWAY_URL + '/subscription_models/',
      httpOptions
    );
  }

  // Account
  getCompany(urlId: string): Observable<AuthApiResponse> {
    // console.log('Get Company');
    return this.http.get(environment.API_23GATEWAY_URL + '/companies/' + urlId);
  }

  getCompanies(userId: string): Observable<AuthApiResponse> {
    // console.log('Get Accounts');
    return this.http.get(
      environment.API_23GATEWAY_URL + '/users/' + userId + '/companies'
    );
  }
  getAllCompanies(
    query: string,
    page: number,
    perPage: number
  ): Observable<AuthApiResponse> {
    // console.log('Get Accounts');
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(environment.API_23GATEWAY_URL + '/companies');
  }
  createCompany(companyData: NewCompanyData): Observable<ApiResponse> {
    console.log('Create Company Service');
    return this.http.post<ApiResponse>(
      environment.API_23GATEWAY_URL + '/companies',
      {
        company: companyData,
      }
    );
  }

  addCompanyDetails(
    companyUrlId: string,
    data: CompanyDetailsData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23GATEWAY_URL + '/companies/' + companyUrlId + '/details',
      { company: data }
    );
  }

  accessRequest(
    appId: string,
    appUrl: string,
    userData: ImpersonalizationData
  ): Observable<AuthApiResponse> {
    console.log('Access Requested');
    return this.http.post(
      environment.API_23GATEWAY_URL + '/companies/' + appUrl + '/access',
      {
        user: userData,
      }
    );
  }

  // Re-send account confirmation email

  reSendConfirmationEmail(
    userUniqueId: string,
    confirmationData: ConfirmationData
  ): Observable<ApiResponse> {
    const confirm_success_url = this.options.registerAccountCallback;
    return this.http.post(
      environment.API_23GATEWAY_URL +
        '/users/' +
        userUniqueId +
        '/confirmation',
      { confirm_success_url, user: confirmationData }
    );
  }

  // Re-send account confirmation email with email only

  reSendEmailOnly(email: string): Observable<ApiResponse> {
    const confirm_success_url = this.options.registerAccountCallback;
    return this.http.post(environment.API_23GATEWAY_URL + '/users/reconfirm', {
      user: { email },
      confirm_success_url,
    });
  }

  getAllMailTemplates(): Observable<ApiResponse> {
    return this.http.get(environment.API_23GATEWAY_URL + '/mailtemplates/');
  }

  getMailTemplate(templateId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23GATEWAY_URL + '/mailtemplates/' + templateId
    );
  }

  updateMailTemplate(
    templateId: string,
    templateData: MailTemplateData
  ): Observable<ApiResponse> {
    const body = { template: templateData };
    return this.http.put(
      environment.API_23GATEWAY_URL + '/mailtemplates/' + templateId,
      body
    );
  }

  sendRecaptchaToken(token: string): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23GATEWAY_URL + '/google/recaptcha/',
      {
        recaptcha: { site_token: token },
      }
    );
  }

  validateTenantCode(code: string): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23GATEWAY_URL + '/auth/tenant/validate/',
      {
        company: { code },
      }
    );
  }

  // Get subscription model
  getSubscription(subscriptionModelId: string): Observable<AuthApiResponse> {
    // console.log('Get Company');
    return this.http.get(
      environment.API_23GATEWAY_URL +
        '/subscription_models/' +
        subscriptionModelId
    );
  }

  // Add company subscription
  createCompanySubscription(
    urlId: string,
    subscriptionData: AssignSubscriptionData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23GATEWAY_URL + '/companies/' + urlId + '/subscription',
      { subscription: subscriptionData }
    );
  }

  // Update company subscription
  updateCompanySubscription(
    urlId: string,
    subscriptionData: AssignSubscriptionData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23GATEWAY_URL + '/companies/' + urlId + '/subscription',
      { subscription: subscriptionData }
    );
  }

  /* Currencies */

  getCurrencies(searchParams: SearchParams): Observable<ApiResponse> {
    const { query, page, perPage } = searchParams;
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23GATEWAY_URL + `/currencies`,
      httpOptions
    );
  }

  getCurrency(currencyId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23GATEWAY_URL + `/currencies/${currencyId}`
    );
  }

  addCurrency(currency: CurrencyData): Observable<ApiResponse> {
    return this.http.post(environment.API_23GATEWAY_URL + `/currencies/`, {
      currency,
    });
  }

  editCurrency(
    currencyId: string,
    currency: CurrencyData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23GATEWAY_URL + `/currencies/${currencyId}`,
      {
        currency,
      }
    );
  }

  deleteCurrency(currencyId: string): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23GATEWAY_URL + `/currencies/${currencyId}`
    );
  }

  /**
   *
   * Construct Paths / Urls
   *
   */

  private getApiPath(): string {
    let constructedPath = '';

    if (this.options.apiBase != null) {
      constructedPath += this.options.apiBase + '/';
    }

    if (this.options.apiPath != null) {
      constructedPath += this.options.apiPath + '/';
    }

    return constructedPath;
  }

  private getServerPath(): string {
    return this.getApiPath(); // + this.getUserPath();
  }

  private getOAuthPath(oAuthType: string): string {
    let oAuthPath: string;

    oAuthPath = this.options.oAuthPaths[oAuthType];

    if (oAuthPath == null) {
      oAuthPath = `/auth/${oAuthType}`;
    }

    return oAuthPath;
  }

  private getOAuthUrl(
    oAuthPath: string,
    callbackUrl: string,
    windowType: string
  ): string {
    let url: string;

    url = `${this.options.oAuthBase}/${oAuthPath}`;
    url += `?omniauth_window_type=${windowType}`;
    url += `&auth_origin_url=${encodeURIComponent(callbackUrl)}`;

    return url;
  }

  /**
   *
   * Get Auth Data
   *
   */

  // Try to load auth data
  private tryLoadAuthData(): void {
    this.getAuthDataFromStorage();

    if (this.activatedRoute) {
      this.getAuthDataFromParams();
    }

    if (this.authData) {
      // this.validateToken();
    }
  }

  // Parse Auth data from response
  public getAuthHeadersFromResponse(
    data: HttpResponse<any> | HttpErrorResponse
  ): void {
    const headers = data.headers;

    const authData: AuthToken = {
      companyToken: headers.get('company-token'),
      accessToken: headers.get('access-token'),
      client: headers.get('client'),
      expiry: headers.get('expiry'),
      tokenType: headers.get('token-type'),
      uid: headers.get('uid'),
      appid: environment.APPID,
    };

    this.setAuthData(authData);
  }

  // Parse Auth data from post message
  private getAuthDataFromPostMessage(data: any): void {
    const authData: AuthToken = {
      companyToken: data.company_token,
      accessToken: data.auth_token,
      client: data.client_id,
      expiry: data.expiry,
      tokenType: 'Bearer',
      uid: data.uid,
      appid: data.appid,
    };

    this.setAuthData(authData);
  }

  // Try to get auth data from storage.
  public getAuthDataFromStorage(): void {
    const authData: AuthToken = {
      companyToken: this.localStorage.getItem('companyToken'),
      accessToken: this.localStorage.getItem('accessToken'),
      client: this.localStorage.getItem('client'),
      expiry: this.localStorage.getItem('expiry'),
      tokenType: this.localStorage.getItem('tokenType'),
      uid: this.localStorage.getItem('uid'),
      appid: this.localStorage.getItem('appid'),
    };

    if (this.checkAuthData(authData)) {
      this.authData.next(authData);
    }
  }

  // Try to get auth data from url parameters.
  private getAuthDataFromParams(): void {
    this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      const authData: AuthToken = {
        accessToken: queryParams.get('access-token'),
        client: queryParams.get('client_id'),
        expiry: queryParams.get('expiry'),
        tokenType: 'Bearer',
        uid: queryParams.get('uid'),
        appid: queryParams.get('appid'),
        companyToken: 'NA',
      };
      if (this.checkAuthData(authData)) {
        this.setAuthData(authData);
        this.authData.next(authData);
      }
    });
  }

  /**
   *
   * Set Auth Data
   *
   */

  // Write auth data to storage
  private setAuthData(authData: AuthToken): void {
    if (this.checkAuthData(authData)) {
      this.authData.next(authData);

      this.localStorage.setItem('companyToken', authData.companyToken);
      this.localStorage.setItem('accessToken', authData.accessToken);
      this.localStorage.setItem('client', authData.client);
      this.localStorage.setItem('expiry', authData.expiry);
      this.localStorage.setItem('tokenType', authData.tokenType);
      this.localStorage.setItem('uid', authData.uid);
      this.localStorage.setItem('appid', authData.appid);
    }
  }

  private clearAuthData() {
    this.localStorage.removeItem('companyToken');
    this.localStorage.removeItem('accessToken');
    this.localStorage.removeItem('client');
    this.localStorage.removeItem('expiry');
    this.localStorage.removeItem('tokenType');
    this.localStorage.removeItem('uid');
    this.localStorage.removeItem('appid');

    this.authData.next(null);
    this.userData.next(null);
  }

  /**
   *
   * Validate Auth Data
   *
   */

  // Check if auth data complete and if response token is newer
  private checkAuthData(authData: AuthToken): boolean {
    if (
      authData.companyToken != null &&
      authData.accessToken != null &&
      authData.client != null &&
      authData.expiry != null &&
      authData.tokenType != null &&
      authData.uid != null &&
      authData.appid != null
    ) {
      if (this.authData.value != null) {
        return authData.expiry >= this.authData.value.expiry;
      }
      return true;
    }
    return false;
  }

  /**
   *
   * OAuth
   *
   */

  private requestCredentialsViaPostMessage(authWindow: any): Observable<any> {
    const pollerObserv = interval(500);

    const responseObserv = fromEvent(this.global, 'message').pipe(
      pluck('data'),
      filter(this.oAuthWindowResponseFilter)
    );

    responseObserv.subscribe(this.getAuthDataFromPostMessage.bind(this));

    const pollerSubscription = pollerObserv.subscribe(() => {
      if (authWindow.closed) {
        pollerSubscription.unsubscribe();
      } else {
        authWindow.postMessage('requestCredentials', '*');
      }
    });

    return responseObserv;
  }

  private oAuthWindowResponseFilter(data: any): any {
    if (
      data.message === 'deliverCredentials' ||
      data.message === 'authFailure'
    ) {
      return data;
    }
  }

  getCountries(): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23GATEWAY_URL + '/countries/?records=2000'
    );
  }

  getCounties(): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23GATEWAY_URL + '/counties/?records=2000'
    );
  }

  getCities(): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23GATEWAY_URL + '/cities/?records=2000'
    );
  }

  getCitiesFromCountry(countryId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23GATEWAY_URL +
        `/cities/?country=${countryId}&records=2000`
    );
  }

  getStatesFromCountry(countryId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23GATEWAY_URL +
        `/states/?country=${countryId}&records=2000`
    );
  }

  getCountiesFromCountry(countryId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23GATEWAY_URL +
        `/counties/?country=${countryId}&records=2000`
    );
  }

  createUserInvitation(
    invitationData: InvitationData
  ): Observable<ApiResponse> {
    return this.http.post(environment.API_23GATEWAY_URL + '/auth/invitation', {
      user: invitationData,
    });
  }

  getInvites(): Observable<ApiResponse> {
    return this.http.get(environment.API_23GATEWAY_URL + '/invites');
  }

  acceptInvitation(
    acceptInvitationData: AcceptInvitationData,
    token: string
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //       APPID: appId,
      //       // 'company-token': this.companyToken,
      //     }),
      params: new HttpParams({
        fromObject: {
          ...(token && { ['invitation_token']: token.toString() }),
        },
      }),
    };
    return this.http.put(
      environment.API_23GATEWAY_URL +
        '/auth/invitation?redirect_url=' +
        environment.APP_URL,
      { invitation: acceptInvitationData },
      httpOptions
    );
  }

  addUserToTenant(
    userUniqueId: string,
    data: UserToTenantData
  ): Observable<ApiResponse> {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     APPID: appId,
    //     // 'company-token': this.companyToken,
    //   }),
    // };
    return this.http.post(
      environment.API_23GATEWAY_URL + '/users/' + userUniqueId + '/tenant',
      {
        user: data,
      }
    );
  }

  getUserTenant(userEmail): Observable<ApiResponse> {
    return this.http.post(environment.API_23GATEWAY_URL + '/users/tenant', {
      user: { email: userEmail },
    });
  }

  getAllTenants(
    query: string,
    page: number,
    sizes: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(sizes && { ['records']: sizes.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23GATEWAY_URL + '/auth/tenant/children',
      httpOptions
    );
  }

  cloneTemplate(urlId: string): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23GATEWAY_URL +
        '/companies/' +
        urlId +
        '/mail_templates/clone',
      null
    );
  }

  // Get Company Secret
  getCompanySecret(urlId: string): Observable<AuthApiResponse> {
    return this.http.get(
      environment.API_23GATEWAY_URL + '/companies/' + urlId + '/secret'
    );
  }

  getUsersByRole(
    roleId: string,
    searchParams: SearchParams
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(searchParams.query && {
            ['search']: searchParams.query.toString(),
          }),
          ...(searchParams.page && { ['page']: searchParams.page.toString() }),
          ...(searchParams.perPage && {
            ['records']: searchParams.perPage.toString(),
          }),
          ...(searchParams.name && {
            ['search_name']: searchParams.name.toString(),
          }),
        },
      }),
    };
    return this.http.get(
      environment.API_23GATEWAY_URL + '/roles/' + roleId + '/users/',
      httpOptions
    );
  }

  checkTenantName(tenantNameData: TenantNameData): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23GATEWAY_URL + '/auth/tenant/search',
      {
        tenant: tenantNameData,
      }
    );
  }

  uploadPresignLogo(urlId: string, fileNameData: string): Observable<AWSUrl> {
    return this.http.put<AWSUrl>(
      environment.API_23GATEWAY_URL + '/companies/' + urlId + '/presign_logo',
      {
        filename: fileNameData,
      }
    );
  }

  uploadToAWs(presignedUrl: string, file: File): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': file.type,
      }),
    };
    return this.http.put(presignedUrl, file, httpOptions);
  }

  publishLogo(urlId: string, fileData): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23GATEWAY_URL + '/companies/' + urlId + '/logo/publish',
      {
        file: {
          name: fileData,
        },
      }
    );
  }
}
