export class UserAvailability {
  latitude: number;
  longitude: number;
  online: boolean;
  payload: any;
  role: string;
  status: string;
  updated_at: Date;
  user_id: string;
}
