import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  filter,
  first,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';

import { AssetsService } from '../services/assets.service';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
import {
  AllRolesLoaded,
  currentAuthUser,
  RoleActionTypes,
  selectRoleById,
  User,
} from '../../gateway';
import {
  AssetsActionTypes,
  CreateIdentity,
  IdentityLoaded,
  IdentityRequested,
} from '../actions/assets.actions';
import { environment } from 'src/environments/environment';
import { UserData, UserIdentityData } from '../models/assets.interfaces';

@Injectable()
export class AssetsEffects {
  loadIdentity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<IdentityRequested>(AssetsActionTypes.IdentityRequested),
        concatLatestFrom((action) => this.store.select(currentAuthUser)),
        mergeMap(([action, user]) => {
          // console.log('Fetching assets Identity');
          return this.assets.getUserIdentity(user.uniqueId);
        }),
        tap(
          (apiResponse) => {
            if (apiResponse.data) {
              // console.log(apiResponse, 'Ya existe identidad assets');
              const data: any = normalize(apiResponse);
              const _userIdentity = build(
                data,
                'userIdentity',
                apiResponse.data.id,
                {
                  eager: true,
                }
              );
              this.store.dispatch(
                new IdentityLoaded({
                  userIdentity: _userIdentity,
                })
              );
            } else {
              // console.log('No existe identidad assets');
              this.store.dispatch(new CreateIdentity());
            }
          },
          (error) => {
            this.store.dispatch(new CreateIdentity());
          }
        )
      ),
    { dispatch: false }
  );

  createIdentity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CreateIdentity>(AssetsActionTypes.CreateIdentity),
        concatLatestFrom((action) => this.store.select(currentAuthUser)),
        switchMap(([action, user]) => {
          // console.log('Creando Identidad Assets...');
          const userData = this.buildUserData(user);
          // console.log(user);
          return this.assets.createUserIdentity(user.uniqueId, userData);
        }),
        tap(
          (apiResponse) => {
            // console.log(apiResponse, 'Se agrego identidad assets');
            const data: any = normalize(apiResponse);
            const _userIdentity = build(
              data,
              'userIdentity',
              apiResponse.data.id,
              {
                eager: true,
              }
            );
            this.store.dispatch(
              new IdentityLoaded({ userIdentity: _userIdentity })
            );
          },
          (error) => {
            console.log(error, 'Error al agregar identidad assets');
          }
        )
      ),
    { dispatch: false }
  );

  buildUserData(user: User): UserIdentityData {
    return {
      first_name: user.name.split(' ')[0] || 'User Name',
      last_name: user.name.split(' ')?.[1] || 'last_name',
      phone:
        user.phone ||
        (Math.floor(Math.random() * 9000000000) + 1000000000).toString(),
      email: user.email || 'example@email.com',
      user_unique_id: user.uniqueId,
      role_name: 'User',
      role_unique_id: environment.USER_ROLE_UNIQUE_ID,
    };
  }

  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AllRolesLoaded>(RoleActionTypes.AllRolesLoaded),
        tap((action) => {
          // this.store.dispatch(new IdentityRequested());
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private assets: AssetsService,
    private store: Store<AppState>,
    private router: Router
  ) {}
}
