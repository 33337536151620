import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';
import { SearchService } from '../services/search.service';

@Injectable()
export class SearchEffects {
  constructor(
    private actions$: Actions,
    private search: SearchService,
    private store: Store<AppState>,
    private router: Router
  ) {}
}
