import { isPlatformServer } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';
import { environment } from 'src/environments/environment';
import { selectAccessToken, selectCompany } from '../../gateway';
import { AuthToken } from '../../gateway/models/gateway.interfaces';
import { ApiResponse } from '../../models/api-response.model';
import {
  AWSUrl,
  FILES_23BLOCKS_SERVICE_OPTIONS,
  StorageFileUpdateData,
  UserData,
} from '../models/files.interface';

import {
  CategoriesData,
  Files23blocksOptions,
  FilesData,
  PresignUploadData,
} from '../models/files.interface';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  get tokenOptions(): Files23blocksOptions {
    return this.options;
  }
  set tokenOptions(options: Files23blocksOptions) {
    this.options = (Object as any).assign(this.options, options);
  }
  private options: Files23blocksOptions;
  private appId: string;
  private companyToken: string;
  private companyUrlId: string;

  constructor(
    private http: HttpClient,
    @Inject(FILES_23BLOCKS_SERVICE_OPTIONS) config: any,
    private store: Store<AppState>
  ) {
    const defaultOptions: Files23blocksOptions = {
      apiPath: null,
      apiBase: null,
      APPID: null,
    };
    const mergedOptions = (Object as any).assign(defaultOptions, config);
    this.options = mergedOptions;
    if (this.options.apiBase === null) {
      console.warn(
        `[Files 23Blocks] You have not configured 'apiBase', which may result in security issues. ` +
          `Please refer to the documentation at https://github.com/neroniaky/angular-token/wiki`
      );
    }
  }

  addCategories(categoriesData: CategoriesData): Observable<ApiResponse> {
    return this.http.post(environment.API_23GATEWAY_URL + '/categories/', {
      categories: categoriesData,
    });
  }

  // 1st step to AWS
  addPresignUpload(
    userId: string,
    presignUploadData: PresignUploadData
  ): Observable<AWSUrl> {
    return this.http.put<AWSUrl>(
      environment.API_23FILES_URL + '/users/' + userId + '/presign_upload',
      presignUploadData
    );
  }
  // Upload file to AWS
  uploadFileAWS(aws_url: string, file: File): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': file.type,
      }),
    };
    return this.http.put(aws_url, file, httpOptions);
  }

  // Register file in 23blocks after AWS upload
  addFiles(userId: string, filesData: FilesData): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23FILES_URL + '/users/' + userId + '/files',
      filesData
    );
  }

  // Get all user files
  getFiles(
    userId: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23FILES_URL + '/users/' + userId + '/files',
      httpOptions
    );
  }

  getFilesAdmin(userId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23FILES_URL + '/23blocks/users/' + userId + '/files'
    );
  }

  // Get single user file
  getFile(userId: string, fileUniqueId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23FILES_URL +
        '/users/' +
        userId +
        '/files/' +
        fileUniqueId
    );
  }

  // Register user for upload
  registerForUpload(
    userId: string,
    userData: UserData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23FILES_URL + '/users/' + userId + '/register',
      { user: userData }
    );
  }

  // Get user identity
  getUserIdentity(userUniqueId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23FILES_URL + '/users/' + userUniqueId
    );
  }

  getMediaboxFile(
    userId: string,
    fileUniqueId: string
  ): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23FILES_URL + '/users/' + userId + '/aws/' + fileUniqueId
    );
  }

  //STORAGE

  getStorageFiles(): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        APPID: this.appId,
        'company-token': this.companyToken,
      }),
    };
    return this.http.get(
      environment.API_23FILES_URL +
        '/23blocks/storage/' +
        this.companyUrlId +
        '/files/',
      httpOptions
    );
  }

  getStorageFile(fileUniqueId: string): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        APPID: this.appId,
        'company-token': this.companyToken,
      }),
    };
    return this.http.get(
      environment.API_23FILES_URL +
        '/storage/' +
        this.companyUrlId +
        '/files/' +
        fileUniqueId,
      httpOptions
    );
  }

  updateStorageFile(
    fileUniqueId: string,
    fileData: StorageFileUpdateData
  ): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        APPID: this.appId,
        'company-token': this.companyToken,
      }),
    };
    const body = { file: fileData };
    return this.http.put(
      environment.API_23FILES_URL +
        '/storage/' +
        this.companyUrlId +
        '/files/' +
        fileUniqueId,
      body,
      httpOptions
    );
  }

  addStoragePresignUpload(
    presignUploadData: PresignUploadData
  ): Observable<AWSUrl> {
    const httpOptions = {
      headers: new HttpHeaders({
        APPID: this.appId,
        'company-token': this.companyToken,
      }),
    };

    return this.http.put<AWSUrl>(
      environment.API_23FILES_URL +
        '/storage/' +
        this.companyUrlId +
        '/presign_upload',
      presignUploadData,
      httpOptions
    );
  }

  addStorageFile(fileData: FilesData): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        APPID: this.appId,
        'company-token': this.companyToken,
      }),
    };
    const body = { file: fileData };
    return this.http.post(
      environment.API_23FILES_URL + '/storage/' + this.companyUrlId + '/files/',
      body,
      httpOptions
    );
  }

  getCategories(): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        APPID: this.appId,
        'company-token': this.companyToken,
      }),
    };
    return this.http.get(
      environment.API_23FILES_URL + '/categories',
      httpOptions
    );
  }

  approveStorageFile(fileId: string): Observable<AWSUrl> {
    const httpOptions = {
      headers: new HttpHeaders({
        APPID: this.appId,
        'company-token': this.companyToken,
      }),
    };
    const body = {};
    return this.http.put<AWSUrl>(
      environment.API_23FILES_URL +
        '/storage/' +
        this.companyUrlId +
        '/files/' +
        fileId +
        '/approve',
      body,
      httpOptions
    );
  }

  rejectStorageFile(fileId: string): Observable<AWSUrl> {
    const httpOptions = {
      headers: new HttpHeaders({
        APPID: this.appId,
        'company-token': this.companyToken,
      }),
    };
    const body = {};
    return this.http.put<AWSUrl>(
      environment.API_23FILES_URL +
        '/storage/' +
        this.companyUrlId +
        '/files/' +
        fileId +
        '/reject',
      body,
      httpOptions
    );
  }

  deleteStorageFile(fileId: string): Observable<AWSUrl> {
    const httpOptions = {
      headers: new HttpHeaders({
        APPID: this.appId,
        'company-token': this.companyToken,
      }),
    };
    return this.http.delete<AWSUrl>(
      environment.API_23FILES_URL +
        '/storage/' +
        this.companyUrlId +
        '/files/' +
        fileId,
      httpOptions
    );
  }

  deleteUserFile(userId: string, fileId: string): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      environment.API_23FILES_URL + '/users/' + userId + '/files/' + fileId
    );
  }
}
