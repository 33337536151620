export class Profile {
  id: number;
  uniqueId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  ethnicity: string;
  zipcode: string;
  maritalStatus: string;
  birthdate: string;
  hhi: string;
  children: string;
  source: string;
  phoneNumber: string;
  email: string;
  preferredDevice: string;
  preferredLanguage: string;
  payload: any;
  status: string;

  instagram: string;
  youtube: string;
  fb: string;
  linkedin: string;
  twitter: string;
  networkA: string;
  networkB: string;
  timeZone: string;
  webSite: string;
  blog: string;

  clear?(): void {
    this.id = undefined;
  }
}
