import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Geolocation23blocksInterceptor } from './interceptors/geolocation-23blocks.interceptor';
import {
  GeoLocation23blocksOptions,
  GEOLOCATION_23BLOCKS_SERVICE_OPTIONS,
} from './models/geolocation.interfaces';
import { GeolocationService } from './services/geolocation.service';

@NgModule({
  imports: [],
})
export class Geolocation23blocksModule {
  constructor(@Optional() @SkipSelf() parentModule: Geolocation23blocksModule) {
    if (parentModule) {
      throw new Error(
        "GeoLocation23Blocks is already loaded. It should only be imported in your application's main module."
      );
    }
  }
  static forRoot(
    options: GeoLocation23blocksOptions
  ): ModuleWithProviders<Geolocation23blocksModule> {
    return {
      ngModule: Geolocation23blocksModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: Geolocation23blocksInterceptor,
          multi: true,
        },
        options.geolocationOptionsProvider || {
          provide: GEOLOCATION_23BLOCKS_SERVICE_OPTIONS,
          useValue: options,
        },
        GeolocationService,
      ],
    };
  }
}
