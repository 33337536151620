import { InjectionToken, Provider } from '@angular/core';

export interface Files23blocksOptions {
  filesOptionsProvider?: Provider;

  apiBase?: string;
  apiPath?: string;
  APPID: string;
}

export const FILES_23BLOCKS_SERVICE_OPTIONS = new InjectionToken(
  'FILES_23BLOCKS_SERVICE_OPTIONS'
);

export interface FilesData {
  // unique_id: string;
  // user_identity_unique_id: string;
  // user_unique_id: string;
  // status: string;
  // enabled: string;
  // bucket: string;
  name: string;
  url: string;
  thumbnail_url: string;
  file_type: string;
  file_size: string;
  description: string;
  original_name: string;
  original_file: string;
  category_unique_id: string;
  category_name?: string;
  // created_by: string;
  // updated_by: string;
  payload: any;
  tags?: any;
}

export interface CategoriesData {
  unique_id: string;
  code: string;
  name: string;
  description: string;
  parent_id: number;
  display_order: number;
  image_url: string;
  content_url: string;
  status: string;
  enabled: string;
  payload: string;
}

export interface PresignUploadData {
  filename: string;
}

export interface UserData {
  name: string;
  email: string;
  phone: string;
  status: string;
  user_unique_id: string;
  role_name: string;
  role_unique_id: string;
}

export interface StorageFileUpdateData {
  original_name: string;
  thumbnail: string;
  description: string;
}

export interface ApiUploadResult {
  url: string;
}

export interface UploadResult {
  name: string;
  type: string;
  size: number;
  url: string;
}

export interface AWSUrl {
  presigned_url: string;
  public_url: string;
  signed_url: string;
  file_name: string;
}
