import { environment } from 'src/environments/environment';

export function isJSON(jsonString): boolean {
  try {
    var o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === 'object') {
      return true;
    }
  } catch (e) {}

  return false;
}

export function getRoleId(uniqueId: string): number {
  switch (uniqueId) {
    case environment.GUEST_ROLE_UNIQUE_ID:
      return 1;
    case environment.ADMIN_ROLE_UNIQUE_ID:
      return 2;
    case environment.SUPPORT_ROLE_UNIQUE_ID:
      return 3;
    case environment.USER_ROLE_UNIQUE_ID:
      return 4;
    case environment.INVESTOR_ROLE_UNIQUE_ID:
      return 5;

    default:
      break;
  }
}
export function getRoleName(id: number): string {
  switch (id) {
    case 1:
      return 'guest';
    case 2:
      return 'admin';
    case 3:
      return 'support';
    case 4:
      return 'user';
    case 5:
      return 'authorized';

    default:
      return null;
  }
}

export function getRoleNameForIdentity(id: number): string {
  switch (id) {
    case 1:
      return 'guest';
    case 2:
      return 'admin';
    case 3:
      return 'support';
    case 4:
      return 'user';
    case 5:
      return 'authorized';
    case 7:
      return 'company_admin';

    default:
      break;
  }
}

export function getRoleNameByUniqueId(
  uniqueId: string
): 'guest' | 'admin' | 'support' | 'user' | 'authorized' {
  switch (uniqueId) {
    case environment.GUEST_ROLE_UNIQUE_ID:
      return 'guest';
    case environment.ADMIN_ROLE_UNIQUE_ID:
      return 'admin';
    case environment.SUPPORT_ROLE_UNIQUE_ID:
      return 'support';
    case environment.USER_ROLE_UNIQUE_ID:
      return 'user';
    case environment.INVESTOR_ROLE_UNIQUE_ID:
      return 'authorized';

    default:
      break;
  }
}

export function getRoleUniqueId(id: number): string {
  switch (id) {
    case 1:
      return environment.GUEST_ROLE_UNIQUE_ID;
    case 2:
      return environment.ADMIN_ROLE_UNIQUE_ID;
    case 3:
      return environment.SUPPORT_ROLE_UNIQUE_ID;
    case 4:
      return environment.USER_ROLE_UNIQUE_ID;
    case 5:
      return environment.INVESTOR_ROLE_UNIQUE_ID;

    default:
      break;
  }
}
