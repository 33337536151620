import { AuthActionTypes } from '../actions/auth.actions';

export function clearState(reducer) {
  return function (state, action) {
    if (action.type === AuthActionTypes.Logout) {
      console.log('Metareducer - Logout/Clear state');
      state = undefined;
    }
    if (action.type === AuthActionTypes.ClearState) {
      console.log('Metareducer - Clear state/Clear localStorage');
      state = undefined;
      // Preserve translation service language
      const language = localStorage.getItem('language');
      localStorage.clear();
      localStorage.setItem('language', language);
    }
    return reducer(state, action);
  };
}
