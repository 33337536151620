import {
  ProductsActions,
  ProductsActionTypes,
} from '../actions/products.actions';
import { Cart } from '../models/cart.model';
import { UserIdentity } from '../models/user-identity.model';

export interface ProductsState {
  userIdentity: UserIdentity;
  isIdentityLoaded: boolean;
  cart: Cart;
  isCartRequested: boolean;
  isCartSuccess: boolean;
  isCartError: boolean;
}

export const initialProductsState: ProductsState = {
  userIdentity: undefined,
  isIdentityLoaded: false,
  cart: null,
  isCartRequested: false,
  isCartSuccess: false,
  isCartError: false,
};

export function productsReducer(
  state = initialProductsState,
  action: ProductsActions
): ProductsState {
  switch (action.type) {
    case ProductsActionTypes.IdentityLoaded: {
      return {
        ...state,
        isIdentityLoaded: true,
        userIdentity: action.payload.userIdentity,
      };
    }

    case ProductsActionTypes.CartRequested: {
      return {
        ...state,
        isCartRequested: true,
        isCartSuccess: false,
        isCartError: false,
      };
    }
    case ProductsActionTypes.CartLoaded: {
      return {
        ...state,
        cart: action.payload.cart,
        isCartRequested: false,
        isCartSuccess: true,
        isCartError: false,
      };
    }
    case ProductsActionTypes.CartError: {
      return {
        ...state,
        isCartRequested: false,
        isCartSuccess: false,
        isCartError: true,
      };
    }

    default:
      return state;
  }
}
