import { InjectionToken, Provider } from '@angular/core';

export interface Forms23blocksOptions {
  formsOptionsProvider?: Provider;
  apiBase?: string;
  apiPath?: string;
  APPID: string;
}

export const FORMS_23BLOCKS_SERVICE_OPTIONS = new InjectionToken(
  'FORMS_23BLOCKS_SERVICE_OPTIONS'
);

export interface ApiResponse {
  status?: string;
  success?: boolean;
  statusText?: string;
  data?: any;
  include?: any;
  errors?: any;
  meta?: any;
}

export interface SurveyFormData {
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  phone_number: number | string;
  notes: string;
  form_unique_id: string;
  form_fields: any;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;
  visitor_unique_id: string;
  visitor_type: string;
  touch_id: string;
  touch_reference_id: string;
  form_type_unique_id: string;
}

export interface GetSurveyData {
  email: string;
}

export interface LandingFormData {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  selected_option: string;
  notes: string;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;
  form_fields: any;
  form_type_unique_id?: string;
  preferred_language?: string;

  middle_name?: string;

  message?: string;

  visitor_unique_id?: string;
  visitor_type?: string;

  touch_id?: string;
  touch_reference_id?: string;
}

export interface UserIdentityData {
  user_unique_id: string;
  name: string;
  email: string;
  phone: string;
  role_name: string;
  role_unique_id: string;
  avatar_url?: string;
}

export interface StatusData {
  status: string;
}
