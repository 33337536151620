import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  shareReplay,
  withLatestFrom,
} from 'rxjs/operators';

import {
  DestroyAndCreateCart,
  RemoveCartDetail,
  UpdateCart,
  UpdateCartSingle,
} from '../actions/products.actions';
import { CartDetails } from '../models/cart-details.model';
import { Category } from '../models/category.model';
import { Product } from '../models/product.model';
import { ProductsService } from '../services/products.service';
import { normalizeApiResponse } from 'src/app/common/utils/utils';

@Injectable()
export class ProductsFacade {
  snapshot: RouterStateSnapshot;

  categories$: Observable<Category[]> = this.products
    .getCategories({ query: '', page: 1, perPage: 30 })
    .pipe(
      map((apiResponse) => normalizeApiResponse(apiResponse, 'category', true))
    );

  public getCategory(categoryName: string): Observable<Product[]> {
    return this.products
      .getNormalizedCategories({
        page: 1,
        perPage: 200,
        query: '',
      })
      .pipe(
        mergeMap((categories) => {
          const jumpsCategory = categories.find(
            (cat) => cat.name === categoryName
          );
          if (!jumpsCategory) return of([]);
          return this.products
            .getNormalizedCategory(jumpsCategory.uniqueId)
            .pipe(map((category) => category.products));
        }),
        shareReplay()
      )
      .pipe(catchError((err) => of([])));
  }

  public getCategoryProducts(categoryId: string): Observable<Product[]> {
    return this.products
      .getNormalizedCategory(categoryId)
      .pipe(map((category) => category.products))
      .pipe(catchError((err) => of([])));
  }

  addProductToCart(product: Product, qty: number) {
    this.store.dispatch(
      new UpdateCart({ sku: product.sku, qty, category: product.categories[0] })
    );
  }

  removeProductFromCart(product: Product, qty: number) {
    this.addProductToCart(product, -qty);
  }

  addProductToCartSingle(product: Product, qty: number, userId: string) {
    this.store.dispatch(
      new UpdateCartSingle({
        sku: product.sku,
        qty,
        category: product.categories[0],
        productNotes: userId,
      })
    );
  }

  addCartDetailToCart(cartDetail: CartDetails, qty: number) {
    this.store.dispatch(
      new UpdateCart({
        sku: cartDetail.sku,
        qty,
        category: {
          name: cartDetail.categoryName,
          uniqueId: cartDetail.categoryUniqueId,
        } as Category,
      })
    );
  }

  removeCartDetailFromCart(cartDetail: CartDetails, qty: number) {
    this.addCartDetailToCart(cartDetail, -qty);
  }

  removeCartDetail(cartDetail: CartDetails) {
    this.store.dispatch(new RemoveCartDetail(cartDetail));
  }

  clearCart() {
    this.store.dispatch(new DestroyAndCreateCart());
  }

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private router: Router,
    private products: ProductsService
  ) {}
}
