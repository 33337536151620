import { Provider } from '@angular/core';
import { InjectionToken } from '@angular/core';

export interface Gateway23blocksOptions {
  gatewayOptionsProvider?: Provider;

  apiBase?: string;
  apiPath?: string;
  APPID: string;

  signInPath?: string;
  signInRedirect?: string;
  signInStoredUrlStorageKey?: string;

  signOutPath?: string;
  validateTokenPath?: string;
  signOutFailedValidate?: boolean;

  deleteAccountPath?: string;
  registerAccountPath?: string;
  registerAccountCallback?: string;

  updatePasswordPath?: string;
  addAvatarPath?: string;

  resetPasswordPath?: string;
  resetPasswordCallback?: string;

  loginField?: string;

  rolesPath?: string;
  permissionsPath?: string;

  oAuthBase?: string;
  oAuthPaths?: { [key: string]: string };
  oAuthCallbackPath?: string;
  oAuthWindowType?: string;
  oAuthWindowOptions?: { [key: string]: string };
}

export const GATEWAY_23BLOCKS_SERVICE_OPTIONS = new InjectionToken(
  'GATEWAY_23BLOCKS_SERVICE_OPTIONS'
);

export interface AuthToken {
  companyToken: string;
  accessToken: string;
  client: string;
  expiry: string;
  tokenType: string;
  uid: string;
  appid: string;
}

// Actions

export interface SignInData {
  login: string;
  password: string;
}

export interface NewUserData {
  uid: string;
  provider: string;
  email: string;
  password: string;
  username: string;
  name: string;
  role_id?: number;
  payload?: string;
}
export interface NewRegistrationData {
  user: NewUserData;
  confirm_success_url: string;
  subscription?: string;
}

export interface UpdatePasswordData {
  password: string;
  passwordConfirmation: string;
  passwordCurrent?: string;
  resetPasswordToken?: string;
}

export interface ResetPasswordData {
  login: string;
}

// API Response Format

export interface AuthApiResponse {
  status?: string;
  success?: boolean;
  statusText?: string;
  data?: any;
  include?: any;
  errors?: any;
  meta?: any;
}

// Configuration Options

export interface AvatarData {
  original_name: string;
  name: string;
  url: string;
  thumbnail: string;
  file_type: string;
  file_size: string;
  description: string;
  original_file: string;
  is_public: boolean;
}

export interface ProfileData {
  first_name: string;
  middle_name?: string;
  last_name: string;
  gender?: string;
  ethnicity?: string;
  zipcode?: string;
  marital_status?: string;
  birthdate?: string;
  hhi?: string;
  children?: string;
  source?: string;
  phone_number?: string;
  email?: string;
  preferred_device?: string;
  preferred_language?: string;
  payload?: any;

  fb?: string;
  instagram?: string;
  linkedin?: string;
  twitter?: string;
  network_a?: string;
  network_b?: string;
  time_zone?: string;
  youtube?: string;
  web_site?: string;
  blog?: string;
}

export interface DeviceData {
  id: string;
  pushId: string;
  uniqueId: string;
  deviceType: string;
  status: string;
  osType: string;
  defaultDevice: string;
  locationEnabled: string;
  notificationsEnabled: string;
  user?: any;
}

export interface ImpersonalizationData {
  appid: string;
  unique_id: string;
  provider: string;
  uid: string;
  email: string;
  role_id?: number;
  elevation?: boolean;
}

export interface ConfirmationData {
  email: string;
  username: string;
  uid: string;
  role_id: number;
}

export interface MailTemplateData {
  template_html: string;
  template_text: string;
  from_address: string;
  from_name: string;
  from_subject: string;
}

export interface NewCompanyData {
  url_id?: string;
  code: string;
  name: string;
  preferred_domain: string;
  preferred_language: string;
  payload: string;
  elevation: string;
  admin_role_id?: number;
  admin_password?: string;
}

export interface CompanyDetailsData {
  company_name?: string;
  company_code?: string;

  address_line1: string;
  address_line2?: string;
  city_name: string;
  city_id?: string;
  state_name: string;
  state_id?: string;
  zipcode: string;
  admin4_code?: string;
  admin4_name?: string;
  admin5_code?: string;
  admin5_name?: string;
  admin6_code?: string;
  admin6_name?: string;
  postal_code?: string;
  country_name: string;
  country_id: string;
  phone_number: string;
  logo_url: string;
  preferred_color: string;
  currency_unique_id: string;
  currency_code: string;
  currency_name: string;
  payload: string;

  primary_email?: string;
  web_site?: string;
  twitter?: string;
  fb?: string;
  instagram?: string;
  linkedin?: string;
  youtube?: string;
  blog?: string;
  network_a?: string;
  network_b?: string;
  notes?: string;
  tax_id?: string;
}

export interface InvitationData {
  email: string;
  name: string;
  invitation_token: string;
  provider: string;
  skip_invitation: string;
  role_id: number;
  appid: string;
  accept_invitation_url: string;
}

export interface AcceptInvitationData {
  invitation_token: string;
  password: string;
  user_name: string;
  name: string;
  subscription: string;
  phone_number?: number;
}

export interface UserToTenantData {
  id: string;
  unique_id: string;
  name: string;
  email: string;
  app_id: string;
  parent_app_id: string;
}

export interface TenantNameData {
  name: string;
}

export type SearchParams = {
  query: string;
  page: number;
  perPage: number;
  name?: string;
  categoryId?: string;
};

export interface AssignSubscriptionData {
  subscription_unique_id: string;
  payload: string;
  number?: string;
  unique_id?: string;
}

export interface CurrencyData {
  name: string;
  symbol: string;
  code: string;
}
