import {
  RealtimeActions,
  RealtimeActionTypes,
} from '../actions/realtime.actions';
import { UserIdentity } from '../models/user-identity.model';

export interface RealtimeState {
  userIdentity: UserIdentity;
  isIdentityLoaded: boolean;
}

export const initialRealtimeState: RealtimeState = {
  userIdentity: undefined,
  isIdentityLoaded: false,
};

export function realtimeReducer(
  state = initialRealtimeState,
  action: RealtimeActions
): RealtimeState {
  switch (action.type) {
    case RealtimeActionTypes.IdentityLoaded: {
      return {
        ...state,
        isIdentityLoaded: true,
        userIdentity: action.payload.userIdentity,
      };
    }
    default:
      return state;
  }
}
