import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ApiResponse,
  Realtime23blocksOptions,
  REALTIME_23BLOCKS_SERVICE_OPTIONS,
} from '..';
import {
  ContextData,
  GroupData,
  MailTemplateData,
  MessageData,
  NewGroupMemberData,
  SettingsData,
  UserIdentityData,
} from '../models/realtime.interfaces';

@Injectable({
  providedIn: 'root',
})
export class RealtimeService {
  get tokenOptions(): Realtime23blocksOptions {
    return this.options;
  }

  set tokenOptions(options: Realtime23blocksOptions) {
    this.options = (Object as any).assign(this.options, options);
  }

  private options: Realtime23blocksOptions;
  constructor(
    private http: HttpClient,
    @Inject(REALTIME_23BLOCKS_SERVICE_OPTIONS) config: any
  ) {
    const defaultOptions: Realtime23blocksOptions = {
      apiBase: null,
      apiPath: null,
      APPID: null,
    };

    const mergedOptions = (Object as any).assign(defaultOptions, config);
    this.options = mergedOptions;

    if (this.options.apiBase === null) {
      console.warn(
        `[Realtime 23Blocks] You have not configured 'apiBase', which may result in security issues. ` +
          `Please refer to the documentation at https://github.com/neroniaky/angular-token/wiki`
      );
    }
  }

  // Get all user notifications
  getNotifications(userId: string): Observable<ApiResponse> {
    // console.log('Calling Service Notifications');
    const url =
      environment.API_23REALTIME_URL + '/users/' + userId + '/notifications';
    return this.http.get<ApiResponse>(url);
  }

  // Check notification as read
  readNotification(notificationUniqueId: string): Observable<ApiResponse> {
    const url = environment.API_23REALTIME_URL + '/notifications/';
    const body = {
      notification: {
        unique_id: notificationUniqueId,
        screen: 'Laptop',
        app: 'Webapp',
      },
    };
    return this.http.put<ApiResponse>(url, body);
  }

  // Get single notification
  getNotification(uniqueId: string): Observable<ApiResponse> {
    const url = environment.API_23REALTIME_URL + '/notifications/' + uniqueId;
    return this.http.get<ApiResponse>(url);
  }

  // Get conversation
  getConversation(contextId: string): Observable<ApiResponse> {
    const url = environment.API_23REALTIME_URL + '/conversations/' + contextId;
    return this.http.get<ApiResponse>(url);
  }

  // Get single message
  getMessage(
    conversationUniqueId: string,
    messageId: string
  ): Observable<ApiResponse> {
    const url =
      environment.API_23REALTIME_URL +
      '/conversations/' +
      conversationUniqueId +
      '/messages/' +
      messageId;
    return this.http.get<ApiResponse>(url);
  }

  getUserStatus(userId: string): Observable<ApiResponse> {
    const url = environment.API_23REALTIME_URL + '/users/' + userId + '/status';
    return this.http.get<ApiResponse>(url);
  }

  getGroupConversationContext(members: string): Observable<ApiResponse> {
    const url =
      environment.API_23REALTIME_URL + '/groups/conversations/contexts';
    const body = { group: { members } };
    return this.http.post<ApiResponse>(url, body);
  }

  getUserGroups(userId: string): Observable<ApiResponse> {
    const url = environment.API_23REALTIME_URL + '/users/' + userId + '/groups';
    return this.http.get<ApiResponse>(url);
  }

  addUserToGroup(
    groupId: string,
    userId: string,
    memberData: NewGroupMemberData
  ): Observable<ApiResponse> {
    const url =
      environment.API_23REALTIME_URL +
      '/groups/' +
      groupId +
      '/users/' +
      userId;
    const body = { member: memberData };
    return this.http.put<ApiResponse>(url, body);
  }

  deleteUserFromGroup(
    groupId: string,
    userId: string
  ): Observable<ApiResponse> {
    const url =
      environment.API_23REALTIME_URL +
      '/groups/' +
      groupId +
      '/users/' +
      userId;
    return this.http.delete<ApiResponse>(url);
  }

  updateGroup(groupId: string, groupData: GroupData): Observable<ApiResponse> {
    const url = environment.API_23REALTIME_URL + '/groups/' + groupId;
    const body = { group: groupData };
    return this.http.put<ApiResponse>(url, body);
  }

  getUserConversations(userId: string): Observable<ApiResponse> {
    const url =
      environment.API_23REALTIME_URL + '/users/' + userId + '/conversations';
    return this.http.get<ApiResponse>(url);
  }

  // Create context, will not create one with same context Code
  addContextNoRepeat(contextData: ContextData): Observable<ApiResponse> {
    const url = environment.API_23REALTIME_URL + '/conversations';
    const body = { context: contextData };
    return this.http.post<ApiResponse>(url, body);
  }

  // Create context, does not check for context Code
  addContext(contextData: ContextData): Observable<ApiResponse> {
    const url = environment.API_23REALTIME_URL + '/contexts';
    const body = { context: contextData };
    return this.http.post<ApiResponse>(url, body);
  }

  getNotificationsSettings(userId: string): Observable<ApiResponse> {
    const url =
      environment.API_23REALTIME_URL +
      '/users/' +
      userId +
      '/notifications/settings';
    return this.http.get<ApiResponse>(url);
  }

  updateNotificationsSettings(
    userId: string,
    settingsData: SettingsData
  ): Observable<ApiResponse> {
    const url =
      environment.API_23REALTIME_URL +
      '/users/' +
      userId +
      '/notifications/settings';
    const body = { settings: settingsData };
    return this.http.put<ApiResponse>(url, body);
  }

  getAllMailTemplates(): Observable<ApiResponse> {
    return this.http.get(environment.API_23REALTIME_URL + '/mailtemplates/');
  }

  getMailTemplate(templateId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23REALTIME_URL + '/mailtemplates/' + templateId
    );
  }

  updateMailTemplate(
    templateId: string,
    templateData: MailTemplateData
  ): Observable<ApiResponse> {
    const body = { template: templateData };
    return this.http.put(
      environment.API_23REALTIME_URL + '/mailtemplates/' + templateId,
      body
    );
  }

  updateUserIdentity(
    userUniqueId: string,
    userIdentityData: Partial<UserIdentityData>
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23REALTIME_URL + '/users/' + userUniqueId,
      { user: userIdentityData }
    );
  }

  addMessage(contextId: string, message: MessageData): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23REALTIME_URL +
        '/conversations/' +
        contextId +
        '/messages',
      { message }
    );
  }

  getSources(sourceId: string): Observable<ApiResponse> {
    const url = environment.API_23REALTIME_URL + '/sources/' + sourceId;
    return this.http.get<ApiResponse>(url);
  }

  createUserIdentity(
    userUniqueId: string,
    dataForm: UserIdentityData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23REALTIME_URL + '/users/' + userUniqueId + '/register/',
      {
        user: dataForm,
      }
    );
  }

  getUserIdentity(userUniqueId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23REALTIME_URL + '/users/' + userUniqueId
    );
  }
}
