import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  AuthActionTypes,
  currentAuthUser,
  selectRoleById,
  User,
  UserLoaded,
} from '../../gateway';

import {
  getRoleNameForIdentity,
  getRoleUniqueId,
} from 'src/app/core/tools/helper-functions';
import { normalizeApiResponse } from 'src/app/common/utils/utils';
import { UserIdentity } from '../models/user-identity.model';
import {
  CreateIdentity,
  IdentityLoaded,
  IdentityRequested,
  ContentActionTypes,
} from '../actions/content.actions';
import { UserIdentityData } from '../models/content.interfaces';
import { ContentService } from '../services/content.service';

@Injectable()
export class ContentEffects {
  loadIdentity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<IdentityRequested>(ContentActionTypes.IdentityRequested),
        concatLatestFrom((action) => this.store.select(currentAuthUser)),
        filter(([action, user]) => [3, 4].includes(user.roleId)),
        mergeMap(([action, user]) => {
          // console.log('Fetching Content Identity');
          return this.content.getUserIdentity(user.uniqueId);
        }),
        tap(
          (apiResponse) => {
            if (apiResponse.data) {
              // console.log('Ya existe identidad Content');

              const _userIdentity = normalizeApiResponse(
                apiResponse,
                'userIdentity',
                false
              ) as UserIdentity;
              this.store.dispatch(
                new IdentityLoaded({
                  userIdentity: _userIdentity,
                })
              );
            } else {
              console.log('No existe identidad Content');
              this.store.dispatch(new CreateIdentity());
            }
          },
          (error) => {
            console.log('Error fetching identidad Content');
            // this.store.dispatch(
            //   new IdentityLoaded({ userIdentity: null, firstTime: false })
            // );
            this.store.dispatch(new CreateIdentity());
          }
        )
      ),
    { dispatch: false }
  );

  createIdentity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CreateIdentity>(ContentActionTypes.CreateIdentity),
        concatLatestFrom((action) => this.store.select(currentAuthUser)),
        switchMap(([action, user]) => {
          const userIdentityData = this.buildUserData(user);
          return this.content.createUserIdentity(
            user.uniqueId,
            userIdentityData
          );
        }),
        tap(
          (apiResponse) => {
            console.log(apiResponse, 'Se agrego identidad Content');
            const _userIdentity = normalizeApiResponse(
              apiResponse,
              'userIdentity',
              false
            ) as UserIdentity;
            this.store.dispatch(
              new IdentityLoaded({ userIdentity: _userIdentity })
            );
          },
          (error) => {
            console.log(error, 'Error al agregar identidad Content');
          }
        )
      ),
    { dispatch: false }
  );

  buildUserData(user: User): UserIdentityData {
    const role_name = getRoleNameForIdentity(user.roleId);
    const role_unique_id = getRoleUniqueId(user.roleId);
    let fullname = user.name.split(' ');
    return {
      user: {
        last_name: fullname.pop(),
        first_name: fullname.join(' '),
        phone:
          user.phone ||
          (Math.floor(Math.random() * 9000000000) + 1000000000).toString(),
        email: user.email || 'example@email.com',
        user_unique_id: user.uniqueId,
        role_name,
        role_unique_id,
      },
      tag: {
        tag: 'na',
        unique_id: null,
      },
    } as UserIdentityData;
  }

  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UserLoaded>(AuthActionTypes.UserLoaded),
        tap((action) => {
          this.store.dispatch(new IdentityRequested());
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private content: ContentService,
    private store: Store
  ) {}
}
