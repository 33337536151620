import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RealtimeState } from '../reducers/realtime.reducers';

export const selectRealtimeState =
  createFeatureSelector<RealtimeState>('realtime');

export const selectUserIdentity = createSelector(
  selectRealtimeState,
  (realtimeState) => realtimeState.userIdentity
);
export const isIdentityLoaded = createSelector(
  selectRealtimeState,
  (realtimeState) => realtimeState.isIdentityLoaded
);
