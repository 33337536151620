import { CRMActions, CRMActionsTypes } from '../actions/crm.actions';
import { Account } from '../models/account.model';

export interface CRMState {
  isAccountsLoaded: boolean;
  accounts: Account[];
}

export const initialCRMState: CRMState = {
  isAccountsLoaded: false,
  accounts: [],
};

export function crmReducer(
  state = initialCRMState,
  action: CRMActions
): CRMState {
  switch (action.type) {
    case CRMActionsTypes.AccountsLoaded: {
      return {
        ...state,
        isAccountsLoaded: true,
        accounts: action.payload.accounts,
      };
    }
    default:
      return state;
  }
}
