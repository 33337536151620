import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import {
  CRM23blocksOptions,
  CRM_23BLOCKS_SERVICE_OPTIONS,
} from './models/crm.interfaces';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CRM23blocksInterceptor } from './interceptors/crm-23blocks.interceptor';
import { CRMService } from './services/crm.service';
import { StoreModule } from '@ngrx/store';
import { crmReducer } from './reducers/crm.reducer';
import { CRMEffects } from './effects/crm.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [],
  imports: [
    [
      StoreModule.forFeature('crm', crmReducer),
      EffectsModule.forFeature([CRMEffects]),
    ],
  ],
})
export class CRM23blocksModule {
  constructor(@Optional() @SkipSelf() parentModule: CRM23blocksModule) {
    if (parentModule) {
      throw new Error(
        "CRM23Blocks is already loaded. It should only be imported in your application's main module."
      );
    }
  }
  static forRoot(
    options: CRM23blocksOptions
  ): ModuleWithProviders<CRM23blocksModule> {
    return {
      ngModule: CRM23blocksModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CRM23blocksInterceptor,
          multi: true,
        },
        options.CRMOptionsProvider || {
          provide: CRM_23BLOCKS_SERVICE_OPTIONS,
          useValue: options,
        },
        CRMService,
      ],
    };
  }
}
