import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SearchEffects } from './effects/search.effects';
import {
  Search23blocksOptions,
  SEARCH_23BLOCKS_SERVICE_OPTIONS,
} from './models/search.interfaces';
import { Search23blocksInterceptor } from './interceptors/search.interceptors';
import { SearchService } from './services/search.service';
import { searchReducer } from './reducers/search.reducers';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('search', searchReducer),
    EffectsModule.forFeature([SearchEffects]),
  ],
})
export class Search23blocksModule {
  constructor(@Optional() @SkipSelf() parentModule: Search23blocksModule) {
    if (parentModule) {
      throw new Error(
        "Search23blocksModule is already loaded. It should only be imported in your application's main module."
      );
    }
  }
  static forRoot(
    options: Search23blocksOptions
  ): ModuleWithProviders<Search23blocksModule> {
    return {
      ngModule: Search23blocksModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: Search23blocksInterceptor,
          multi: true,
        },
        options.SearchOptionsProvider || {
          provide: SEARCH_23BLOCKS_SERVICE_OPTIONS,
          useValue: options,
        },
        SearchService,
      ],
    };
  }
}
