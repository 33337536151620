import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  Onboarding23blocksOptions,
  ONBOARDING_23BLOCKS_SERVICE_OPTIONS,
} from './models/onboarding.interfaces';
import { Onboarding23blocksInterceptor } from './interceptors/onboarding.interceptors';
import { OnboardingService } from './services/onboarding.service';
import { StoreModule } from '@ngrx/store';
import { onboardingReducer } from './reducers/onboarding.reducers';
import { EffectsModule } from '@ngrx/effects';
import { OnboardingEffects } from './effects/onboarding.effects';
import { OnboardingGuard } from './guards/onboarding.guard';
import { OnboardingFacade } from './facades/onboarding.facade';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('onboarding', onboardingReducer),
    EffectsModule.forFeature([OnboardingEffects]),
  ],
})
export class Onboarding23blocksModule {
  constructor(@Optional() @SkipSelf() parentModule: Onboarding23blocksModule) {
    if (parentModule) {
      throw new Error(
        "Onboarding23blocksModule is already loaded. It should only be imported in your application's main module."
      );
    }
  }
  static forRoot(
    options: Onboarding23blocksOptions
  ): ModuleWithProviders<Onboarding23blocksModule> {
    return {
      ngModule: Onboarding23blocksModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: Onboarding23blocksInterceptor,
          multi: true,
        },
        options.OnboardingOptionsProvider || {
          provide: ONBOARDING_23BLOCKS_SERVICE_OPTIONS,
          useValue: options,
        },
        OnboardingService,
        OnboardingGuard,
        OnboardingFacade,
      ],
    };
  }
}
