import { Action } from '@ngrx/store';
import { Account } from '../models/account.model';

export enum CRMActionsTypes {
    AccountsRequested = '[Request Accounts] API',
    AccountsLoaded = '[Load Accounts] API',
}


export class AccountsRequested implements Action {
    readonly type = CRMActionsTypes.AccountsRequested;
  }
  export class AccountsLoaded implements Action {
    readonly type = CRMActionsTypes.AccountsLoaded;
    constructor(public payload: { accounts: Account[] }) {}
  }

export type CRMActions = 
| AccountsRequested
| AccountsLoaded