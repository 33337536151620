import normalize from 'json-api-normalizer';
import build from 'redux-object';
import { ApiResponse } from 'src/app/core/23blocks/models/api-response.model';

export const createUUID = (): string => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

export function humanFileSize(bytes: number): string {
  if (Math.abs(bytes) < 1024) {
    return bytes + ' B';
  }
  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  do {
    bytes /= 1024;
    u++;
  } while (Math.abs(bytes) >= 1024 && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
}

export const normalizeApiResponse = (
  apiResponse: ApiResponse,
  objectName: string,
  isCollection: boolean,
  withMetadata?: boolean
) => {
  const defaultDataValue = isCollection ? [] : null;

  if (!withMetadata) {
    if (!apiResponse?.data) return defaultDataValue;
    if (isCollection && !apiResponse?.data?.length) return defaultDataValue;
    const data = normalize(apiResponse);
    if (!data) return defaultDataValue;
    const _normalized =
      build(data, objectName, isCollection ? null : apiResponse.data.id, {
        eager: true,
      }) || defaultDataValue;
    return _normalized;
  }
  if (withMetadata) {
    const defaultValue = { data: defaultDataValue, meta: null };
    if (!apiResponse?.data) return defaultValue;
    if (isCollection && !apiResponse?.data?.length) return defaultValue;
    const data = normalize(apiResponse);
    if (!data) return defaultValue;
    const _normalized =
      build(data, objectName, isCollection ? null : apiResponse.data.id, {
        eager: true,
      }) || defaultDataValue;
    return { data: _normalized, meta: apiResponse.meta };
  }
};

export const capitalizeWord = (word: string) => {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
};


export const goBack = (): void => {
  history.back();
};
