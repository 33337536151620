import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpInterceptor,
  HttpHandler,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { FilesService } from '../services/files.service';
import { GatewayService } from '../../gateway';

@Injectable()
export class Files23blocksInterceptor implements HttpInterceptor {
  private headers: any;
  constructor(
    private filesService: FilesService,
    private gateway: GatewayService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.match(environment.API_23FILES_URL)) {
      if (
        req.headers.get('appid') === null &&
        req.headers.get('company-token') === null
      ) {
        // Get auth data from local storage
        this.gateway.getAuthDataFromStorage();

        // Add the headers if the request is going to the configured server
        const authData = this.gateway.authData.value;

        if (authData) {
          this.headers = {
            'company-token': authData.companyToken,
            // 'access-token': authData.accessToken,
            // client: authData.client,
            // expiry: authData.expiry,
            // 'token-type': authData.tokenType,
            // uid: authData.uid,
            appid: authData.appid,
          };
        } else {
          this.headers = {
            appid: environment.APPID,
          };
        }

        req = req.clone({
          setHeaders: this.headers,
        });
      }
    } else return next.handle(req);

    return next
      .handle(req)
      .pipe(
        tap(
          (res) => this.handleResponse(res),
          (err) => this.handleResponse(err)
        )
      )
      .pipe(
        catchError((err) => {
          // if ([401, 403].indexOf(err.status) !== -1) {
          //   console.log('Out of HERE - Auth');
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // this.filesService.signOut().subscribe();
          // }
          // const errors = err.error
          //   ? err.error?.errors || {
          //       errors: [err.error?.message],
          //     } || { errors: [err.statusText] }
          //   : { ...err };
          // return throwError(errors);
          return throwError(err);
        })
      );
  }

  private handleResponse(
    res: HttpResponse<any> | HttpErrorResponse | HttpEvent<any>
  ): void {
    if (res instanceof HttpResponse || res instanceof HttpErrorResponse) {
      // tslint:disable-next-line: max-line-length
      if (
        this.filesService.tokenOptions.apiBase === null ||
        (res.url && res.url.match(this.filesService.tokenOptions.apiBase))
      ) {
        // console.log('Successful response - Interceptor Files');
      }
    }
  }
}
