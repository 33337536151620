import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  CONTENT_23BLOCKS_SERVICE_OPTIONS,
  Content23blocksOptions,
} from './models/content.interfaces';
import { ContentService } from './services/content.service';
import { contentReducer } from './reducers/content.reducers';
import { ContentEffects } from './effects/content.effects';
import { Content23blocksInterceptor } from './interceptors/content.interceptors';
import { ContentGuard } from './guards/content.guard';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('content', contentReducer),
    EffectsModule.forFeature([ContentEffects]),
  ],
})
export class Content23blocksModule {
  constructor(@Optional() @SkipSelf() parentModule: Content23blocksModule) {
    if (parentModule) {
      throw new Error(
        "Content23blocksModule is already loaded. It should only be imported in your application's main module."
      );
    }
  }
  static forRoot(
    options: Content23blocksOptions
  ): ModuleWithProviders<Content23blocksModule> {
    return {
      ngModule: Content23blocksModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: Content23blocksInterceptor,
          multi: true,
        },
        options.ContentOptionsProvider || {
          provide: CONTENT_23BLOCKS_SERVICE_OPTIONS,
          useValue: options,
        },
        ContentService,
        ContentGuard,
      ],
    };
  }
}
