import { Action } from '@ngrx/store';
import { UserIdentity } from '../models/user-identity.model';

export enum SearchActionTypes {
  IdentityRequested = '[Request User Search Identity] API',
  IdentityLoaded = '[Load User Search Identity] API',
  CreateIdentity = '[Create User Search Identity] API',
}

export class IdentityRequested implements Action {
  readonly type = SearchActionTypes.IdentityRequested;
}
export class IdentityLoaded implements Action {
  readonly type = SearchActionTypes.IdentityLoaded;
  constructor(public payload: { userIdentity: UserIdentity }) {}
}

export class CreateIdentity implements Action {
  readonly type = SearchActionTypes.CreateIdentity;
}

export type SearchActions = IdentityRequested | IdentityLoaded | CreateIdentity;
