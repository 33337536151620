import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { SendStep } from '../actions/onboarding.actions';
import {
  isOnboardingConfigLoaded,
  selectOnboardingConfig,
} from '../selectors/onboarding.selectors';

@Injectable()
export class OnboardingFacade {
  snapshot: RouterStateSnapshot;

  currentStep$ = this.store.pipe(
    select(isOnboardingConfigLoaded),
    filter((isLoaded) => isLoaded),
    withLatestFrom(this.store.select(selectOnboardingConfig)),
    map(([isLoaded, onboardingConfig]) => {
      const currentUrl = this.router.routerState.snapshot.url;
      return onboardingConfig.steps.find((step) => step.stepUrl === currentUrl);
    })
  );

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private router: Router
  ) {}

  completeStep(stepId: string, params?: { [k: string]: any }) {
    this.store.dispatch(
      new SendStep({
        stepId,
        status: 'complete',
        ...params,
      })
    );
  }

  failedStep(stepId: string, params?: any) {
    this.store.dispatch(new SendStep({ stepId, status: 'failed' }));
  }
}
