import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';
import { environment } from 'src/environments/environment';
import {
  AuthActionTypes,
  currentAuthUser,
  currentAuthUserId,
  selectRoleById,
  User,
  UserLoaded,
} from '../../gateway';
import {
  CartError,
  CartLoaded,
  CartRequested,
  CreateCart,
  CreateIdentity,
  DestroyAndCreateCart,
  IdentityLoaded,
  IdentityRequested,
  OrderCart,
  ProductsActionTypes,
  RemoveCartDetail,
  UpdateCart,
  UpdateCartSingle,
} from '../actions/products.actions';
import { ProductsService } from '../services/products.service';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
import {
  getRoleNameForIdentity,
  getRoleUniqueId,
} from 'src/app/core/tools/helper-functions';
import { UserIdentityData } from '../models/products.interfaces';
import {
  selectCart,
  selectUserIdentity,
} from '../selectors/products.selectors';
import { ProductsFacade } from '../facades/products.facade';

@Injectable()
export class ProductsEffects {
  loadIdentity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<IdentityRequested>(ProductsActionTypes.IdentityRequested),
        concatLatestFrom((action) => this.store.select(currentAuthUser)),
        // filter(([action, user]) => [3, 4].includes(user.roleId)),
        mergeMap(([action, user]) => {
          return this.products.getUserIdentity(user.uniqueId);
        }),
        tap(
          (apiResponse) => {
            if (!apiResponse?.data) {
              console.log('No existe identidad products');
              this.store.dispatch(new CreateIdentity());
              return;
            }

            const data: any = normalize(apiResponse);
            const _userIdentity = build(
              data,
              'userIdentity',
              apiResponse.data.id,
              {
                eager: true,
              }
            );
            this.store.dispatch(
              new IdentityLoaded({
                userIdentity: _userIdentity,
              })
            );
          },
          (error) => {
            console.log('Error fetching identidad products');
            // this.store.dispatch(
            //   new IdentityLoaded({ userIdentity: null, firstTime: false })
            // );
            this.store.dispatch(new CreateIdentity());
          }
        )
      ),
    { dispatch: false }
  );

  createIdentity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CreateIdentity>(ProductsActionTypes.CreateIdentity),
        concatLatestFrom((action) => this.store.select(currentAuthUser)),
        switchMap(([action, user]) => {
          const userData = this.buildUserData(user);
          return this.products.createUserIdentity(user.uniqueId, userData);
        }),
        tap(
          (apiResponse) => {
            console.log(apiResponse, 'Se agrego identidad products');
            const data: any = normalize(apiResponse);
            const _userIdentity = build(
              data,
              'userIdentity',
              apiResponse.data.id,
              {
                eager: true,
              }
            );
            this.store.dispatch(
              new IdentityLoaded({ userIdentity: _userIdentity })
            );
          },
          (error) => {
            console.log(error, 'Error al agregar identidad products');
          }
        )
      ),
    { dispatch: false }
  );

  buildUserData(user: User): UserIdentityData {
    const role_name = getRoleNameForIdentity(user.roleId);
    const role_unique_id = getRoleUniqueId(user.roleId);

    return {
      name: user.name || 'User Name',
      first_name: user.name.split(' ')[0],
      last_name:
        user.name.split(' ').length > 1 ? user.name.split(' ')[1] : null,
      phone:
        user.phone ||
        (Math.floor(Math.random() * 9000000000) + 1000000000).toString(),
      email: user.email || 'example@email.com',
      user_unique_id: user.uniqueId,
      role_name,
      role_unique_id,
    };
  }

  loadCart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CartRequested>(ProductsActionTypes.CartRequested),
        concatLatestFrom((action) => this.store.select(selectUserIdentity)),
        switchMap(([action, userIdentity]) => {
          return this.products.getCart(userIdentity.userUniqueId);
        }),
        tap(
          (apiResponse) => {
            if (!apiResponse?.data) {
              this.store.dispatch(new CreateCart());
              return;
            }
            const data: any = normalize(apiResponse);
            const _cart = build(data, 'cart', apiResponse.data.id, {
              eager: true,
            });
            this.store.dispatch(new CartLoaded({ cart: _cart }));
          },
          (err) => {
            console.log('Error fetching cart');
            this.store.dispatch(new CreateCart());
          }
        )
      ),
    { dispatch: false }
  );

  createCart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CreateCart>(ProductsActionTypes.CreateCart),
        concatLatestFrom((action) => this.store.select(selectUserIdentity)),
        switchMap(([action, userIdentity]) => {
          console.log('request cart');
          return this.products.createCart(userIdentity.userUniqueId);
        }),
        tap(
          (apiResponse) => {
            const data: any = normalize(apiResponse);
            const _cart = build(data, 'cart', apiResponse.data.id, {
              eager: true,
            });
            this.store.dispatch(new CartLoaded({ cart: _cart }));
          },
          (err) => {
            console.log('Error creating cart');
            this.store.dispatch(new CartError());
          }
        )
      ),
    { dispatch: false }
  );

  updateCart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateCart>(ProductsActionTypes.UpdateCart),
        concatLatestFrom((action) => this.store.select(selectUserIdentity)),
        concatMap(([action, userIdentity]) => {
          console.log('update cart');
          const { sku, qty, isVariation } = action.payload;
          const category = action.payload.category;
          return this.products.updateCartItemQtyUpdate(
            userIdentity.userUniqueId,
            sku,
            qty,
            category,
            isVariation
          );
        }),
        tap(
          (apiResponse) => {
            const data: any = normalize(apiResponse);
            const _cart = build(data, 'cart', apiResponse.data.id, {
              eager: true,
            });
            this.store.dispatch(new CartLoaded({ cart: _cart }));
            console.log('cart was updated and loaded', _cart);
          },
          (err) => {
            console.log('Error updating cart');
            this.store.dispatch(new CartError());
          }
        )
      ),
    { dispatch: false }
  );

  updateCartSingle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateCartSingle>(ProductsActionTypes.UpdateCartSingle),
        concatLatestFrom((action) => this.store.select(selectUserIdentity)),
        concatMap(([action, userIdentity]) => {
          console.log('update cart single');
          const { sku, qty, isVariation, productNotes } = action.payload;
          const category = action.payload.category;
          return this.products.updateCartItemQtyNoUpdate(
            userIdentity.userUniqueId,
            sku,
            qty,
            category,
            isVariation,
            productNotes
          );
        }),
        tap(
          (apiResponse) => {
            const data: any = normalize(apiResponse);
            const _cart = build(data, 'cart', apiResponse.data.id, {
              eager: true,
            });
            this.store.dispatch(new CartLoaded({ cart: _cart }));
            console.log('cart was updated and loaded', _cart);
          },
          (err) => {
            console.log('Error updating cart');
            this.store.dispatch(new CartError());
          }
        )
      ),
    { dispatch: false }
  );

  removeCartDetail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RemoveCartDetail>(ProductsActionTypes.RemoveCartDetail),
        concatMap((action) => {
          console.log('remove cart detail');
          const { cartUnique, uniqueId: detailUniqueId } = action.payload;
          return this.products.removeCartDetail(cartUnique, detailUniqueId);
        }),
        tap(
          (apiResponse) => {
            const data: any = normalize(apiResponse);
            const _cart = build(data, 'cart', apiResponse.data.id, {
              eager: true,
            });
            this.store.dispatch(new CartLoaded({ cart: _cart }));
            console.log('cart detail removed - cart loaded', _cart);
          },
          (err) => {
            console.log('Error removing cart detail cart');
            this.store.dispatch(new CartError());
          }
        )
      ),
    { dispatch: false }
  );

  orderCart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<OrderCart>(ProductsActionTypes.OrderCart),
        concatLatestFrom((action) => [
          this.store.select(selectUserIdentity),
          this.store.select(selectCart),
        ]),
        switchMap(([action, userIdentity, cart]) => {
          console.log('order cart');
          return this.products.orderCart(
            userIdentity.userUniqueId,
            cart.uniqueId,
            action.payload
          );
        }),
        tap(
          (apiResponse) => {
            console.log(apiResponse, 'order cart resp');
            const data: any = normalize(apiResponse);
            const _cart = build(data, 'cart', apiResponse.data.id, {
              eager: true,
            });
            this.store.dispatch(new CartLoaded({ cart: _cart }));
          },
          (err) => {
            console.log('Error ordering cart');
            this.store.dispatch(new CartError());
          }
        )
      ),
    { dispatch: false }
  );

  cartRequested$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<IdentityLoaded>(ProductsActionTypes.IdentityLoaded),
        tap((action) => {
          this.store.dispatch(new CartRequested());
        })
      ),
    { dispatch: false }
  );

  destroyAndCreateCart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DestroyAndCreateCart>(ProductsActionTypes.DestroyAndCreateCart),
        concatLatestFrom((action) => [this.store.select(currentAuthUserId)]),
        mergeMap(([action, userId]) => this.products.deleteCart(userId)),
        tap(() => this.store.dispatch(new CreateCart()))
      ),
    { dispatch: false }
  );

  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UserLoaded>(AuthActionTypes.UserLoaded),
        tap((action) => {
          // this.store.dispatch(new IdentityRequested());
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private products: ProductsService,
    private store: Store<AppState>,
    private productsFacade: ProductsFacade
  ) {}
}
