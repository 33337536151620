import { InjectionToken, Provider } from '@angular/core';
import { CartDetails } from './cart-details.model';
import { Category } from './category.model';
import { Product } from './product.model';

export interface Products23blocksOptions {
  productsOptionsProvider?: Provider;
  apiBase?: string;
  apiPath?: string;
  APPID: string;
}

export const PRODUCTS_23BLOCKS_SERVICE_OPTIONS = new InjectionToken(
  'PRODUCTS_23BLOCKS_SERVICE_OPTIONS'
);

export interface ApiResponse {
  status?: string;
  success?: boolean;
  statusText?: string;
  data?: any;
  include?: any;
  errors?: any;
  meta?: any;
}

export interface UserIdentityData {
  user_unique_id: string;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  role_name: string;
  role_unique_id: string;
  avatar_url?: string;
  email_notifications?: boolean;
  sms_notifications?: boolean;
  whatsapp_notifications?: boolean;
  other_notifications?: boolean;
}

export interface ProductData {
  sku: string;
  name: string;
  description: string;
  image_url: string;
  content_url: string;
  discount: string;
  price: string;
  tax: string;
  open_price: boolean;
  open_stock: boolean;
  payload: string;
  product_type: string;
  enforce_stock: boolean;
  allow_proximity: boolean;
  stock?: number;
  stock_unit?: string;
  slug?: string;
  cost?: string;
  // notes?: string;
  vendor_discount?: number;
  vendor_discount_value?: string;
  vendor_price?: string;
}

export interface ProductStockData {
  vendor_unique_id: string;
  warehouse_unique_id: string;
}

export interface StockData {
  vendor_unique_id: string;
  warehouse_unique_id: string;
  enforce_availability: boolean;
  stock_unit: 'units';
  available: string;

  priority?: string;
  reserved?: string;
  on_transaction?: string;
  on_transit?: string;
  prime?: string;
  payload?: string;
  source?: string;
  source_alias?: string;
  source_id?: string;
  source_type?: string;
}

export interface CategoryData {
  code: string;
  name: string;
  description: string;
  image_url: string;
  content_url: string;
  parent_id: string;
  display_order: number;
  payload: string;
  slug?: string;
}

export interface AWSUrl {
  presigned_url: string;
  public_url: string;
  signed_url: string;
  file_name: string;
}

export interface RegisterFileData {
  name: string;
  url: string;
  thumbnail_url: string;
  file_type: string;
  file_size: number;
  description: string;
  original_name: string;
  original_file: string;
  is_public: boolean;
  is_main_image: boolean;
}

export interface NewTenantData {
  url_id?: string;
  code: string;
  name: string;
  preferred_domain: string;
  preferred_language: string;
  payload: string;
  secret?: string;
}

export interface ChannelData {
  code: string;
  name: string;
  payload: string;

  meta_title: string;
  meta_description: string;
  meta_keywords: string;

  slug: string;

  currency_unique_id: string;
  currency_code: string;
  currency_name: string;

  api_description: string;
  api_url: string;

  api_keys_description: string;
  api_keys_id: string;
}

export interface SearchParams {
  query: string;
  page: number;
  perPage: number;
  name: string;
  categoryId: string;
  sku: string;
}

export interface CompanyKeysData {
  description: string;
  provider: string;
  api_key: string;
  api_secret: string;
  api_region: string;
}

export interface WarehouseData {
  vendor_unique_id: string;
  code: string;
  name: string;
  payload: string;
  is_global: boolean;
  address_unique_id: string;
  location_unique_id: string;
  is_multichannel: boolean;
  channel_unique_id: string;
  channel_code: string;
  channel_name: string;
  meta_title: string;
  meta_description: string;
  meta_keywords: string;
  slug: string;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;
}

export interface VendorData {
  code: string;
  name: string;
  email: string;
  phone: string;
  contact_name: string;
  thumbnail_url: string;
  image_url: string;
  content_url: string;
  media_url: string;
  payload: string;
  slug: string;
}

export interface CatalogData {
  name: string;
  code: string;
  thumbnail_url: string;
  image_url: string;
  content_url: string;
  media_url: string;
  is_global: boolean;
  country_id: string;
  country_name: string;
  payload: string;

  currency_unique_id: string;
  currency_code: string;
  currency_name: string;

  is_multichannel: boolean;
  channel_unique_id: string;
  channel_code: string;
  channel_name: string;
  meta_title: string;
  meta_description: string;
  meta_keywords: string;
}

export interface PromotionData {
  price_unique_id: string;
  code: string;
  name: string;
  description: string;
  discount_money: string;
  discount_percentage: string;
  additional_points: string;
  minimum_purchase: string;
  discount_money_field: string;
  discount_percentage_field: string;
  valid_from: string;
  valid_to: string;
  content_url: string;
  image_url: string;
  media_url: string;
  thumbnail_url: string;
  qcode: string;
  status: string;
  payload: string;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;
  time_zone: string;
}

export interface PriceData {
  is_global: boolean;
  country_id: string;
  country_name: string;
  currency_unique_id: string;
  currency_code: string;
  currency_name: string;
  is_multichannel: boolean;
  channel_unique_id: string;
  channel_code: string;
  channel_name: string;
  payload: string;
  price: string;
  price_with_fees: string;
  price_with_taxes: string;
  discount: string;
  discount_value: string;
  tax: string;
  tax_value: string;
  fees: string;
  fees_value: string;
  status: string;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;

  vendor_discount: number;
  vendor_discount_value: string;
  vendor_price: string;
}

export interface UpdateCartData {
  sku: string;
  qty: number;
  category: Category;
  isVariation?: boolean;
}

export interface UpdateCartDataSingle {
  sku: string;
  qty: number;
  category: Category;
  productNotes: string;
  isVariation?: boolean;
}

export interface OrderCartData {
  order_unique_id: string;
  order_system: string;
  order_display_id: string;
  order_status: string;
}
