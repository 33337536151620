export const BUSINESS_BREAKDOWNS = [
  {
    uniqueId: 'b84281ff-b1df-4a88-bb15-c2930f607df2',
    name: 'Strategies',
    slug: 'strategies',
  },
  {
    uniqueId: '91276483-08d6-4b2f-8a94-acab9573e5a4',
    name: 'Value Proposition',
    slug: 'value-proposition',
  },
  {
    uniqueId: '1d2f9ed0-0568-40f3-abb4-74b77f6df177',
    name: 'Technology and IP',
    slug: 'technology-and-ip',
  },
  {
    uniqueId: '85e683a0-c1ec-4d31-9eb8-25809bb3fc11',
    name: 'Marketing',
    slug: 'marketing',
  },
  {
    uniqueId: 'a2fbfa88-cdf3-44d7-98f6-6ce63fbf36f3',
    name: 'Ecosystem Participants',
    slug: 'ecosystem-participants',
  },
  {
    uniqueId: 'c46ce8db-7742-44f5-a1f7-05b408499347',
    name: 'Competitive Landscape',
    slug: 'competitive-landscape',
  },
];

export const PROJECT_DISCLOSURES_SLUGS = {
  RISK_FACTORS: 'risk-factors',
  TOKEN: 'token',
  BUSINESS: 'business',
  HISTORY_AND_STRUCTURE: 'history-and-structure',
  GOVERNANCE: 'governance',
  TEAM: 'team',
  FINANCIAL_INFORMATION: 'financial-information',
  LEGAL: 'legal',
  ADDITIONAL_INFORMATION: 'additional-information',
};

export const GLOBAL_PROJECT_DISCLOSURES = [
  {
    name: 'Risk Factors',
    uniqueId: '1dad688f-414e-4e54-b331-28dc300f2e88',
    slug: PROJECT_DISCLOSURES_SLUGS.RISK_FACTORS,
    breakdowns: [],
  },
  {
    name: 'Token',
    uniqueId: 'de146f9f-674e-443c-ba92-3518f74f6f42',
    slug: PROJECT_DISCLOSURES_SLUGS.TOKEN,
    breakdowns: [],
  },
  {
    name: 'Business',
    uniqueId: '65b85a42-fafd-402c-b594-72236b9c3a66',
    slug: PROJECT_DISCLOSURES_SLUGS.BUSINESS,
    breakdowns: BUSINESS_BREAKDOWNS,
  },
  {
    name: 'History and Structure',
    uniqueId: '3b3d5cf6-3ac3-4a52-892a-41cd5f443a2f',
    slug: PROJECT_DISCLOSURES_SLUGS.HISTORY_AND_STRUCTURE,
    breakdowns: [],
  },
  {
    name: 'Governance',
    uniqueId: 'd4c1c972-4153-4c82-8021-d25488d54f24',
    slug: PROJECT_DISCLOSURES_SLUGS.GOVERNANCE,
    breakdowns: [],
  },
  {
    name: 'Team',
    uniqueId: '93b9b51d-3084-4d73-8909-ac2a2fa8f0f9',
    slug: PROJECT_DISCLOSURES_SLUGS.TEAM,
    breakdowns: [],
  },
  {
    name: 'Financial Information',
    uniqueId: '8032ec73-8bf0-4a1d-8378-1482c7ceeb14',
    slug: PROJECT_DISCLOSURES_SLUGS.FINANCIAL_INFORMATION,
    breakdowns: [],
  },
  {
    name: 'Legal',
    uniqueId: 'b82d1934-b05c-498a-bef6-728cf166adee',
    slug: PROJECT_DISCLOSURES_SLUGS.LEGAL,
    breakdowns: [],
  },
  {
    name: 'Additional Information',
    uniqueId: '9fbf916e-f2d8-45e5-9091-ba56c8de9c05',
    slug: PROJECT_DISCLOSURES_SLUGS.ADDITIONAL_INFORMATION,
    breakdowns: [],
  },
];
