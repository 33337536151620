import { SalesActions, SalesActionTypes } from '../actions/sales.actions';
import { UserIdentity } from '../models/user-identity.model';

export interface SalesState {
  paymentStatus: string;
  isPaymentStatusLoaded: boolean;
  userIdentity: UserIdentity;
  isIdentityLoaded: boolean;
}

export const initialSalesState: SalesState = {
  paymentStatus: '',
  isPaymentStatusLoaded: false,
  userIdentity: undefined,
  isIdentityLoaded: false,
};

export function salesReducer(
  state = initialSalesState,
  action: SalesActions
): SalesState {
  switch (action.type) {
    case SalesActionTypes.IdentityLoaded: {
      return {
        ...state,
        isIdentityLoaded: true,
        userIdentity: action.payload.userIdentity,
      };
    }
    case SalesActionTypes.PaymentStatusLoaded: {
      return {
        ...state,
        paymentStatus: action.payload.paymentStatus,
        isPaymentStatusLoaded: true,
      };
    }
    default:
      return state;
  }
}
