import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpInterceptor,
  HttpHandler,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { OnboardingService } from '../services/onboarding.service';

@Injectable()
export class Onboarding23blocksInterceptor implements HttpInterceptor {
  private headers: any;
  constructor(private onboarding: OnboardingService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.match(environment.API_23ONBOARDING_URL)) {
      // Get auth data from local storage
      this.onboarding.getAuthDataFromStorage();

      // Add the headers if the request is going to the configured server
      const authData = this.onboarding.authData.value;

      if (authData) {
        this.headers = {
          'company-token': authData.companyToken,
          appid: authData.appid,
        };
      } else {
        this.headers = {
          appid: environment.APPID,
        };
      }

      req = req.clone({
        setHeaders: this.headers,
      });
    } else return next.handle(req);
    return next
      .handle(req)
      .pipe(
        tap(
          (res) => this.handleResponse(res),
          (err) => this.handleResponse(err)
        )
      )
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  private handleResponse(
    res: HttpResponse<any> | HttpErrorResponse | HttpEvent<any>
  ): void {
    if (res instanceof HttpResponse || res instanceof HttpErrorResponse) {
      if (
        this.onboarding.tokenOptions.apiBase === null ||
        (res.url && res.url.match(this.onboarding.tokenOptions.apiBase))
      ) {
        // console.log('Successful response - Interceptor Onboarding');
      }
    }
  }
}
