import { Injectable, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { selectAccessToken } from '../../gateway';
import { AuthToken } from '../../gateway/models/gateway.interfaces';
import { isPlatformServer } from '@angular/common';

import { map } from 'rxjs/operators';
import { normalizeApiResponse } from 'src/app/common/utils/utils';
import {
  AWSUrl,
  ApiResponse,
  ChatContextData,
  ChatMessageData,
  MOONCHECK_23BLOCKS_SERVICE_OPTIONS,
  MetaData,
  Mooncheck23blocksOptions,
  ProjectAnnouncementData,
  ProjectContributionData,
  ProjectData,
  ProjectDiscussionData,
  ProjectDiscussionMessageData,
  ProjectEventData,
  ProjectMilestoneData,
  ProjectMilestoneStatusData,
  RiskFactorData,
  RiskFactorSourceData,
  RiskObjectiveData,
  SearchParams,
  UpdateProjectObjectiveData,
  UserIdentityData,
} from '../models/mooncheck.interfaces';
import {
  GlobalBreakdownObjective,
  GlobalBreakdownObjectivePrompt,
  GlobalDisclosure,
  GlobalDisclosureBreakdown,
  Project,
  ProjectAnnouncement,
  ProjectBreakdownObjective,
  ProjectDisclosure,
  ProjectDisclosureBreakdown,
  ProjectFollower,
  RiskFactor,
  RiskFactorSource,
  RiskObjective,
} from '../models/mooncheck.models';
import { ProjectMilestone } from '../models/mooncheck.models';
import { ProjectMilestoneStatus } from '../models/mooncheck.models';
import { ProjectContribution } from '../models/mooncheck.models';
import { ProjectEvent } from '../models/mooncheck.models';
import { ProjectDiscussion } from '../models/mooncheck.models';
import { ProjectDiscussionMessage } from '../models/mooncheck.models';
import { normalizedProjects } from '../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class MooncheckService {
  private localStorage: Storage | any = {};
  private global: Window | any;

  public authData: BehaviorSubject<AuthToken> = new BehaviorSubject<AuthToken>(
    null
  );

  get tokenOptions(): Mooncheck23blocksOptions {
    return this.options;
  }
  set tokenOptions(options: Mooncheck23blocksOptions) {
    this.options = (Object as any).assign(this.options, options);
  }
  private options: Mooncheck23blocksOptions;
  constructor(
    private http: HttpClient,
    @Inject(MOONCHECK_23BLOCKS_SERVICE_OPTIONS) config: any,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    const defaultOptions: Mooncheck23blocksOptions = {
      apiPath: null,
      apiBase: null,
      APPID: null,
    };
    const mergedOptions = (Object as any).assign(defaultOptions, config);
    this.options = mergedOptions;
    if (this.options.apiBase === null) {
      console.warn(
        `[Mooncheck 23Blocks] You have not configured 'apiBase', which may result in security issues. ` +
          `Please refer to the documentation at https://github.com/neroniaky/angular-token/wiki`
      );
    }
  }
  private checkAuthData(authData: AuthToken): boolean {
    if (
      authData.companyToken != null &&
      authData.accessToken != null &&
      authData.client != null &&
      authData.expiry != null &&
      authData.tokenType != null &&
      authData.uid != null &&
      authData.appid != null
    ) {
      if (this.authData.value != null) {
        return authData.expiry >= this.authData.value.expiry;
      }
      return true;
    }
    return false;
  }

  public getAuthDataFromStorage(): void {
    const authData: AuthToken = {
      companyToken: localStorage.getItem('companyToken'),
      accessToken: localStorage.getItem('accessToken'),
      client: localStorage.getItem('client'),
      expiry: localStorage.getItem('expiry'),
      tokenType: localStorage.getItem('tokenType'),
      uid: localStorage.getItem('uid'),
      appid: localStorage.getItem('appid'),
    };

    if (this.checkAuthData(authData)) {
      this.authData.next(authData);
    }
  }

  createUserIdentity(
    userUniqueId: string,
    userIdentityData: UserIdentityData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23MOONCHECK_URL +
        '/identities/' +
        userUniqueId +
        '/register/',
      {
        ...userIdentityData,
      }
    );
  }

  getUserIdentity(userUniqueId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23MOONCHECK_URL + '/identities/' + userUniqueId
    );
  }

  updateUserIdentity(
    userUniqueId: string,
    userIdentityData: UserIdentityData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23MOONCHECK_URL + '/identities/' + userUniqueId,
      { user: userIdentityData }
    );
  }

  /* Project */

  createProject(projectData: Partial<ProjectData>): Observable<ApiResponse> {
    return this.http.post(`${environment.API_23MOONCHECK_URL}/projects`, {
      project: projectData,
    });
  }

  updateProject(
    projectUniqueId: string,
    projectData: ProjectData
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/projects/${projectUniqueId}`,
      {
        project: projectData,
      }
    );
  }

  getProjects(
    searchParams?: Partial<SearchParams>
  ): Observable<{ data: Project[]; meta: MetaData }> {
    const { query, page, records } = searchParams || {};
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(records && { ['records']: records.toString() }),
        },
      }),
    };
    return this.http
      .get(`${environment.API_23MOONCHECK_URL}/projects`, httpOptions)
      .pipe(map(normalizedProjects));
  }

  getProject(projectId: string): Observable<Project> {
    return this.http
      .get(`${environment.API_23MOONCHECK_URL}/projects/${projectId}`)
      .pipe(
        map((apiResponse: ApiResponse) => {
          const _project: Project = normalizeApiResponse(
            apiResponse,
            'project',
            false
          );
          return _project;
        })
      );
  }

  deleteProject(projectId: string): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}`
    );
  }

  /* end Project */

  /* Project Users */

  addUserToProject(
    userId: string,
    projectId: string,
    dates: { joinedAt: string; leftAt?: string },
    role: string
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23MOONCHECK_URL}/identities/${userId}/projects`,
      {
        project: {
          unique_id: projectId,
          joined_at: dates.joinedAt,
          left_at: dates.leftAt,
          role,
        },
      }
    );
  }

  removeUserFromPRoject(
    userId: string,
    projectId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23MOONCHECK_URL}/identities/${userId}/projects/${projectId}`
    );
  }

  /* end Project Users */

  /* Risk Factor */

  createRiskFactor(
    projectId: string,
    riskFactorData: RiskFactorData
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/risks`,
      {
        risk_factor: riskFactorData,
      }
    );
  }

  updateRiskFactor(
    projectId: string,
    riskFactorId: string,
    riskFactorData: RiskFactorData
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/risks/${riskFactorId}`,
      {
        risk_factor: riskFactorData,
      }
    );
  }

  getRiskFactors(projectId: string): Observable<RiskFactor[]> {
    return this.http
      .get(`${environment.API_23MOONCHECK_URL}/projects/${projectId}/risks`)
      .pipe(
        map((apiResponse: ApiResponse) => {
          const _riskFactors: RiskFactor[] = normalizeApiResponse(
            apiResponse,
            'riskFactor',
            true
          );
          return _riskFactors;
        })
      );
  }

  getRiskFactor(
    projectId: string,
    riskFactorId: string
  ): Observable<RiskFactor> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/risks/${riskFactorId}`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _riskFactor: RiskFactor = normalizeApiResponse(
            apiResponse,
            'riskFactor',
            false
          );
          return _riskFactor;
        })
      );
  }

  deleteRiskFactor(
    projectId: string,
    riskFactorId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/risks/${riskFactorId}`
    );
  }

  /* end Risk Factor */
  /* Risk Factor Source */

  createRiskFactorSource(
    projectId: string,
    riskFactorId: string,
    riskFactorSourceData: RiskFactorSourceData
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/risks/${riskFactorId}/sources`,
      {
        source: riskFactorSourceData,
      }
    );
  }

  updateRiskFactorSource(
    projectId: string,
    riskFactorId: string,
    riskFactorSourceId: string,
    riskFactorSourceData: RiskFactorSourceData
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/risks/${riskFactorId}/sources/${riskFactorSourceId}`,
      {
        source: riskFactorSourceData,
      }
    );
  }

  getRiskFactorSources(
    projectId: string,
    riskFactorId: string
  ): Observable<RiskFactorSource[]> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/risks/${riskFactorId}/sources`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          const _riskFactorSources: RiskFactorSource[] = normalizeApiResponse(
            apiResponse,
            'riskFactorSource',
            true
          );
          return _riskFactorSources;
        })
      );
  }

  getRiskFactorSource(
    projectId: string,
    riskFactorId: string,
    riskFactorSourceId: string
  ): Observable<RiskFactorSource> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/risks/${riskFactorId}/sources/${riskFactorSourceId}`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _riskFactorSource: RiskFactorSource = normalizeApiResponse(
            apiResponse,
            'riskFactorSource',
            false
          );
          return _riskFactorSource;
        })
      );
  }

  deleteRiskFactorSource(
    projectId: string,
    riskFactorId: string,
    riskFactorSourceId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/risks/${riskFactorId}/sources/${riskFactorSourceId}`
    );
  }
  /* end Risk Factor Source */
  /* Risk Objective */

  createRiskObjective(
    projectId: string,
    riskFactorId: string,
    riskObjectiveData: RiskObjectiveData
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/risks/${riskFactorId}/objectives`,
      {
        objective: riskObjectiveData,
      }
    );
  }

  updateRiskObjective(
    projectId: string,
    riskFactorId: string,
    riskObjectiveId: string,
    riskObjectiveData: RiskObjectiveData
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/risks/${riskFactorId}/objectives/${riskObjectiveId}`,
      {
        objective: riskObjectiveData,
      }
    );
  }

  getRiskObjectives(
    projectId: string,
    riskFactorId: string
  ): Observable<RiskObjective[]> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/risks/${riskFactorId}/objectives`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          const _riskObjectives: RiskObjective[] = normalizeApiResponse(
            apiResponse,
            'riskObjective',
            true
          );
          return _riskObjectives;
        })
      );
  }

  getRiskObjective(
    projectId: string,
    riskFactorId: string,
    riskObjectiveId: string
  ): Observable<RiskObjective> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/risks/${riskFactorId}/objectives/${riskObjectiveId}`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _riskObjective: RiskObjective = normalizeApiResponse(
            apiResponse,
            'riskObjective',
            false
          );
          return _riskObjective;
        })
      );
  }

  deleteRiskObjective(
    projectId: string,
    riskFactorId: string,
    riskObjectiveId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/risks/${riskFactorId}/objectives/${riskObjectiveId}`
    );
  }

  /* end Risk Objective */
  /* Announcements */

  createAnnouncement(
    projectId: string,
    announcement: Partial<ProjectAnnouncementData>
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/announcements`,
      {
        announcement,
      }
    );
  }

  updateAnnouncement(
    projectId: string,
    announcementId: string,
    announcement: Partial<ProjectAnnouncementData>
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/announcements/${announcementId}`,
      {
        announcement,
      }
    );
  }

  getAnnouncements(
    projectId: string
  ): Observable<{ data: ProjectAnnouncement[]; meta: MetaData }> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/announcements`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data?.length)
            return { data: [], meta: { totalPages: 0, totalRecords: 0 } };
          const _announcementsWithMeta: {
            data: ProjectAnnouncement[];
            meta: MetaData;
          } = normalizeApiResponse(
            apiResponse,
            'projectAnnouncement',
            true,
            true
          );
          return _announcementsWithMeta;
        })
      );
  }

  getAnnouncement(
    projectId: string,
    announcementId: string
  ): Observable<ProjectAnnouncement> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/announcements/${announcementId}`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _announcement: ProjectAnnouncement = normalizeApiResponse(
            apiResponse,
            'projectAnnouncement',
            false
          );
          return _announcement;
        })
      );
  }

  deleteAnnouncement(
    projectId: string,
    announcementId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/announcements/${announcementId}`
    );
  }

  /* end Announcements */
  /* Milestones */

  createMilestone(
    projectId: string,
    milestoneData: Partial<ProjectMilestoneData>
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/milestones`,
      {
        milestone: milestoneData,
      }
    );
  }

  updateMilestone(
    projectId: string,
    milestoneId: string,
    milestoneData: Partial<ProjectMilestoneData>
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/milestones/${milestoneId}`,
      {
        milestone: milestoneData,
      }
    );
  }

  getMilestones(projectId: string): Observable<ProjectMilestone[]> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/milestones`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          const _milestones: ProjectMilestone[] = normalizeApiResponse(
            apiResponse,
            'projectMilestone',
            true
          );
          return _milestones;
        })
      );
  }

  getMilestone(
    projectId: string,
    milestoneId: string
  ): Observable<ProjectMilestone> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/milestones/${milestoneId}`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _milestone: ProjectMilestone = normalizeApiResponse(
            apiResponse,
            'projectMilestone',
            false
          );
          return _milestone;
        })
      );
  }

  deleteMilestone(
    projectId: string,
    milestoneId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/milestones/${milestoneId}`
    );
  }

  /* end Milestones */
  /* Milestone Status */

  createMilestoneStatus(
    projectId: string,
    milestoneId: string,
    milestoneStatusData: Partial<ProjectMilestoneStatusData>
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/milestones/${milestoneId}/status`,
      {
        status: milestoneStatusData,
      }
    );
  }

  updateMilestoneStatus(
    projectId: string,
    milestoneId: string,
    milestoneStatusId: string,
    milestoneStatusData: Partial<ProjectMilestoneStatusData>
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/milestones/${milestoneId}/status/${milestoneStatusId}`,
      {
        status: milestoneStatusData,
      }
    );
  }

  getMilestoneStatuses(
    projectId: string,
    milestoneId: string
  ): Observable<ProjectMilestoneStatus[]> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/milestones/${milestoneId}/status`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          const _milestoneStatuses: ProjectMilestoneStatus[] =
            normalizeApiResponse(apiResponse, 'milestoneStatus', true);
          return _milestoneStatuses;
        })
      );
  }

  getMilestoneStatus(
    projectId: string,
    milestoneId: string,
    milestoneStatusId: string
  ): Observable<ProjectMilestoneStatus> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/milestones/${milestoneId}/status/${milestoneStatusId}`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _milestoneStatus: ProjectMilestoneStatus = normalizeApiResponse(
            apiResponse,
            'milestoneStatus',
            false
          );
          return _milestoneStatus;
        })
      );
  }

  deleteMilestoneStatus(
    projectId: string,
    milestoneId: string,
    milestoneStatusId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/milestones/${milestoneId}/status/${milestoneStatusId}`
    );
  }

  /* end Milestone Status */
  /* Project Contribution */

  createProjectContribution(
    projectId: string,
    contributionData: ProjectContributionData
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/contributions`,
      {
        contribution: contributionData,
      }
    );
  }

  updateProjectContribution(
    projectId: string,
    contributionId: string,
    contributionData: ProjectContributionData
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/contributions/${contributionId}`,
      {
        contribution: contributionData,
      }
    );
  }

  getProjectContributions(
    projectId: string
  ): Observable<ProjectContribution[]> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/contributions`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          const _contributions: ProjectContribution[] = normalizeApiResponse(
            apiResponse,
            'projectContribution',
            true
          );
          return _contributions;
        })
      );
  }

  getProjectContribution(
    projectId: string,
    contributionId: string
  ): Observable<ProjectContribution> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/contributions/${contributionId}`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _contribution: ProjectContribution = normalizeApiResponse(
            apiResponse,
            'projectContribution',
            false
          );
          return _contribution;
        })
      );
  }

  deleteProjectContribution(
    projectId: string,
    contributionId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/contributions/${contributionId}`
    );
  }

  /* end Project Contribution */

  /* Project Event */

  createProjectEvent(
    projectId: string,
    eventData: ProjectEventData
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/events`,
      {
        event: eventData,
      }
    );
  }

  updateProjectEvent(
    projectId: string,
    eventId: string,
    eventData: ProjectEventData
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/events/${eventId}`,
      {
        event: eventData,
      }
    );
  }

  getProjectEvents(projectId: string): Observable<ProjectEvent[]> {
    return this.http
      .get(`${environment.API_23MOONCHECK_URL}/projects/${projectId}/events`)
      .pipe(
        map((apiResponse: ApiResponse) => {
          const _events: ProjectEvent[] = normalizeApiResponse(
            apiResponse,
            'projectEvent',
            true
          );
          return _events;
        })
      );
  }

  getProjectEvent(
    projectId: string,
    eventId: string
  ): Observable<ProjectEvent> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/events/${eventId}`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _event: ProjectEvent = normalizeApiResponse(
            apiResponse,
            'projectEvent',
            false
          );
          return _event;
        })
      );
  }

  deleteProjectEvent(
    projectId: string,
    eventId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/events/${eventId}`
    );
  }

  /* end Project Event */
  /* Project Discussion */

  createProjectDiscussion(
    projectId: string,
    discussionData: ProjectDiscussionData
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/discussions`,
      {
        discussion: discussionData,
      }
    );
  }

  updateProjectDiscussion(
    projectId: string,
    discussionId: string,
    discussionData: ProjectDiscussionData
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/discussions/${discussionId}`,
      {
        discussion: discussionData,
      }
    );
  }

  getProjectDiscussions(projectId: string): Observable<ProjectDiscussion[]> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/discussions`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          const _discussions: ProjectDiscussion[] = normalizeApiResponse(
            apiResponse,
            'projectDiscussion',
            true
          );
          return _discussions;
        })
      );
  }

  getProjectDiscussion(
    projectId: string,
    discussionId: string
  ): Observable<ProjectDiscussion> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/discussions/${discussionId}`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _discussion: ProjectDiscussion = normalizeApiResponse(
            apiResponse,
            'projectDiscussion',
            false
          );
          return _discussion;
        })
      );
  }

  deleteProjectDiscussion(
    projectId: string,
    discussionId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/discussions/${discussionId}`
    );
  }

  /* end Project Discussion */
  /* Project Discussion Message */

  createDiscussionMessage(
    projectId: string,
    discussionId: string,
    discussionMessageData: ProjectDiscussionMessageData
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/discussions/${discussionId}/messages`,
      {
        message: discussionMessageData,
      }
    );
  }

  updateDiscussionMessage(
    projectId: string,
    discussionId: string,
    discussionMessageId: string,
    discussionMessageData: ProjectDiscussionMessageData
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/discussions/${discussionId}/messages/${discussionMessageId}`,
      {
        message: discussionMessageData,
      }
    );
  }

  getDiscussionMessages(
    projectId: string,
    discussionId: string
  ): Observable<ProjectDiscussionMessage[]> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/discussions/${discussionId}/messages`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          const _discussionMessages: ProjectDiscussionMessage[] =
            normalizeApiResponse(apiResponse, 'discussionMessage', true);
          return _discussionMessages;
        })
      );
  }

  getDiscussionMessage(
    projectId: string,
    discussionId: string,
    discussionMessageId: string
  ): Observable<ProjectDiscussionMessage> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/discussions/${discussionId}/messages/${discussionMessageId}`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _discussionMessage: ProjectDiscussionMessage =
            normalizeApiResponse(apiResponse, 'discussionMessage', false);
          return _discussionMessage;
        })
      );
  }

  deleteDiscussionMessage(
    projectId: string,
    discussionId: string,
    discussionMessageId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/discussions/${discussionId}/messages/${discussionMessageId}`
    );
  }

  /* end Project Discussion Message */

  /* Disclosures */

  createGlobalDisclosure(disclosure: {
    name: string;
    description: string;
    order: number;
  }): Observable<ApiResponse> {
    return this.http.post(`${environment.API_23MOONCHECK_URL}/disclosures`, {
      disclosure,
    });
  }

  updateGlobalDisclosure(
    disclosure: {
      name: string;
      description: string;
      order: number;
    },
    uniqueId: string
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/disclosures/${uniqueId}`,
      {
        disclosure,
      }
    );
  }

  getGlobalDisclosures(): Observable<{
    data: GlobalDisclosure[];
    meta: MetaData;
  }> {
    return this.http.get(`${environment.API_23MOONCHECK_URL}/disclosures`).pipe(
      map((apiResponse: ApiResponse) => {
        if (!apiResponse?.data?.length)
          return { data: [], meta: { totalPages: 0, totalRecords: 0 } };
        const _disclosures: {
          data: GlobalDisclosure[];
          meta: MetaData;
        } = normalizeApiResponse(apiResponse, 'disclosure', true, true);
        return _disclosures;
      })
    );
  }

  getGlobalDisclosure(disclosureId: string): Observable<GlobalDisclosure> {
    return this.http
      .get(`${environment.API_23MOONCHECK_URL}/disclosures/${disclosureId}`)
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _disclosure: GlobalDisclosure = normalizeApiResponse(
            apiResponse,
            'disclosure',
            false
          );
          return _disclosure;
        })
      );
  }
  deleteDisclosure(disclosureId: string): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23MOONCHECK_URL}/disclosures/${disclosureId}`
    );
  }

  /* end Disclosures */

  /* Breakdowns */

  createGlobalBreakdown(
    breakdown: {
      name: string;
      description: string;
      why: string;
      order: number;
    },
    disclosureId: string
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23MOONCHECK_URL}/disclosures/${disclosureId}/breakdowns`,
      {
        breakdown,
      }
    );
  }

  updateGlobalBreakdown(
    breakdown: {
      name: string;
      description: string;
      why: string;
      order: number;
    },
    disclosureId: string,
    breakdownId: string
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/disclosures/${disclosureId}/breakdowns/${breakdownId}`,
      {
        breakdown,
      }
    );
  }

  getGlobalBreakdowns(
    disclosureId: string
  ): Observable<{ data: GlobalDisclosureBreakdown[]; meta: MetaData }> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/disclosures/${disclosureId}/breakdowns`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data?.length)
            return { data: [], meta: { totalPages: 0, totalRecords: 0 } };
          const _breakdowns: {
            data: GlobalDisclosureBreakdown[];
            meta: MetaData;
          } = normalizeApiResponse(apiResponse, 'breakdown', true, true);
          return _breakdowns;
        })
      );
  }

  getGlobalBreakdown(
    disclosureId: string,
    breakdownId: string
  ): Observable<GlobalDisclosureBreakdown> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/disclosures/${disclosureId}/breakdowns/${breakdownId}`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _breakdown: GlobalDisclosureBreakdown = normalizeApiResponse(
            apiResponse,
            'breakdown',
            false
          );
          return _breakdown;
        })
      );
  }

  deleteBreakdown(
    disclosureId: string,
    breakdownId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23MOONCHECK_URL}/disclosures/${disclosureId}/breakdowns/${breakdownId}`
    );
  }
  /* end Breakdowns */

  /* Objectives */

  getGlobalObjectives(
    disclosureId: string,
    breakdownId: string
  ): Observable<{
    data: GlobalBreakdownObjective[];
    meta: MetaData;
  }> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/disclosures/${disclosureId}/breakdowns/${breakdownId}/objectives`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data?.length)
            return { data: [], meta: { totalPages: 0, totalRecords: 0 } };
          const _objectives: {
            data: GlobalBreakdownObjective[];
            meta: MetaData;
          } = normalizeApiResponse(apiResponse, 'objective', true, true);
          return _objectives;
        })
      );
  }

  getGlobalObjective(
    disclosureId: string,
    breakdownId: string,
    objectiveId: string
  ): Observable<GlobalBreakdownObjective> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/disclosures/${disclosureId}/breakdowns/${breakdownId}/objectives/${objectiveId}`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _objective: GlobalBreakdownObjective = normalizeApiResponse(
            apiResponse,
            'objective',
            false
          );
          console.log({ _objective });
          return _objective;
        })
      );
  }

  createGlobalObjective(
    objective: {
      initial_question: string;
      guidance: string;
      objective: string;
      payload: string;
      order: number;
      name: string;
    },
    disclosureId: string,
    breakdownId: string
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23MOONCHECK_URL}/disclosures/${disclosureId}/breakdowns/${breakdownId}/objectives`,
      {
        objective,
      }
    );
  }

  updateGlobalObjective(
    objective: {
      initial_question: string;
      guidance: string;
      objective: string;
      payload: string;
      order: number;
      name: string;
    },
    disclosureId: string,
    breakdownId: string,
    objectiveId: string
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/disclosures/${disclosureId}/breakdowns/${breakdownId}/objectives/${objectiveId}`,
      {
        objective,
      }
    );
  }
  deleteGlobalObjective(
    disclosureId: string,
    breakdownId: string,
    objectiveId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23MOONCHECK_URL}/disclosures/${disclosureId}/breakdowns/${breakdownId}/objectives/${objectiveId}`
    );
  }

  updateProjectObjective(
    projectId: string,
    objectiveId: string,
    postUniqueId: string,
    objectiveData?: Partial<UpdateProjectObjectiveData>
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/objectives/${objectiveId}`,
      {
        objective: {
          ...(objectiveData || {}),
          content_url: `${environment.API_23CONTENT_URL}/posts/${postUniqueId}`,
        },
      }
    );
  }

  toggleIsProjectObjectiveApplicable(
    projectId: string,
    objectiveId: string,
    objectiveData: {
      isNotApplicable: boolean;
      reason: string;
      markAsCompleted: boolean;
    }
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/objectives/${objectiveId}`,
      {
        objective: {
          not_applicable: objectiveData.isNotApplicable,
          not_applicable_reason: objectiveData.reason,
          is_completed: objectiveData.markAsCompleted,
        },
      }
    );
  }
  /* end Objectives */

  /* Prompts */

  getGlobalPrompts(
    disclosureId: string,
    breakdownId: string,
    objectiveId: string
  ): Observable<{
    data: GlobalBreakdownObjectivePrompt[];
    meta: MetaData;
  }> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/disclosures/${disclosureId}/breakdowns/${breakdownId}/objectives/${objectiveId}/prompts`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data?.length)
            return { data: [], meta: { totalPages: 0, totalRecords: 0 } };
          const _prompts: {
            data: GlobalBreakdownObjectivePrompt[];
            meta: MetaData;
          } = normalizeApiResponse(apiResponse, 'prompt', true, true);
          return _prompts;
        })
      );
  }

  createGlobalPrompt(
    prompt: {
      prompt: string;
      version: string;
      content_url: string;
      payload: string;
    },

    disclosureId: string,
    breakdownId: string,
    objectiveId: string
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23MOONCHECK_URL}/disclosures/${disclosureId}/breakdowns/${breakdownId}/objectives/${objectiveId}/prompts`,
      {
        prompt,
      }
    );
  }

  updateGlobalPrompt(
    prompt: {
      global: string;
      conversation: string;
      level1: string;
      level2: string;
      prompt: string;
      reference1: string;
      version: string;
      content_url: string;
      payload: string;
    },
    disclosureId: string,
    breakdownId: string,
    objectiveId: string,
    promptId: string
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/disclosures/${disclosureId}/breakdowns/${breakdownId}/objectives/${objectiveId}/prompts/${promptId}`,
      {
        prompt,
      }
    );
  }

  deleteGlobalPrompt(
    disclosureId: string,
    breakdownId: string,
    objectiveId: string,
    promptId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23MOONCHECK_URL}/disclosures/${disclosureId}/breakdowns/${breakdownId}/objectives/${objectiveId}/prompts/${promptId}`
    );
  }

  /* Project disclosures, breakdowns, objectives */

  getProjectDisclosures(projectId: string): Observable<ProjectDisclosure[]> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/disclosures`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _disclosures: ProjectDisclosure[] = normalizeApiResponse(
            apiResponse,
            'disclosureRequirement',
            true
          );
          return _disclosures;
        })
      );
  }

  getProjectDisclosure(
    projectId: string,
    disclosureId: string
  ): Observable<ProjectDisclosure> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/disclosures/${disclosureId}`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _disclosure: ProjectDisclosure = normalizeApiResponse(
            apiResponse,
            'disclosureRequirement',
            false
          );
          return _disclosure;
        })
      );
  }

  getProjectDisclosureBreakdowns(
    projectId: string,
    disclosureId: string
  ): Observable<ProjectDisclosureBreakdown[]> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/disclosures/${disclosureId}/breakdowns`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _breakdowns: ProjectDisclosureBreakdown[] =
            normalizeApiResponse(apiResponse, 'disclosureBreakdown', true);
          return _breakdowns;
        })
      );
  }

  getProjectDisclosureBreakdown(
    projectId: string,
    disclosureId: string,
    breakdownId: string
  ): Observable<ProjectDisclosureBreakdown> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/disclosures/${disclosureId}/breakdowns/${breakdownId}`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _breakdown: ProjectDisclosureBreakdown = normalizeApiResponse(
            apiResponse,
            'disclosureBreakdown',
            false
          );
          return _breakdown;
        })
      );
  }

  getProjectBreakdownObjectives(
    projectId: string,
    disclosureId: string,
    breakdownId: string
  ): Observable<ProjectBreakdownObjective[]> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/disclosures/${disclosureId}/breakdowns/${breakdownId}/objectives`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _objectives: ProjectBreakdownObjective[] = normalizeApiResponse(
            apiResponse,
            'disclosureBreakdownObjective',
            true
          );
          return _objectives;
        })
      );
  }

  getProjectBreakdownObjective(
    projectId: string,
    disclosureId: string,
    breakdownId: string,
    objectiveId: string
  ): Observable<ProjectBreakdownObjective> {
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/disclosures/${disclosureId}/breakdowns/${breakdownId}/objectives/${objectiveId}`
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data) return null;
          const _objective: ProjectBreakdownObjective = normalizeApiResponse(
            apiResponse,
            'disclosureBreakdownObjective',
            false
          );
          return _objective;
        })
      );
  }

  /* end Project disclosures, breakdowns, objectives */

  // Upload file to amazon
  uploadFileAWS(aws_url: string, file: File): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': file.type,
      }),
    };
    return this.http.put(aws_url, file, httpOptions);
  }

  /* Upload Source Files */

  // 1st step to AWS
  presignSourcesFileUpload(
    projectId: string,
    objectiveId: string,
    fileName: string
  ): Observable<AWSUrl> {
    return this.http.put<AWSUrl>(
      `${environment.API_23MOONCHECK_URL}/project/${projectId}/objectives/${objectiveId}/sources/presign`,
      { fileName }
    );
  }

  /* end Upload Source Files */

  /* ChatGPT services */

  getChatContext(contextId: string): Observable<ApiResponse> {
    return this.http.get(
      `${environment.API_23MOONCHECK_URL}/jarvis/contexts/${contextId}`
    );
  }

  createChatContext(
    context: Partial<ChatContextData>
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23MOONCHECK_URL}/jarvis/contexts`,
      {
        context,
      }
    );
  }

  sendChatMessage(
    contextId: string,
    message: Partial<ChatMessageData>
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23MOONCHECK_URL}/jarvis/contexts/${contextId}/messages`,
      { message }
    );
  }

  /* end ChatGPT services */

  /* Follow projects */

  followProject(projectId: string): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/follow`,
      {}
    );
  }

  unfollowProject(projectId: string): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23MOONCHECK_URL}/projects/${projectId}/unfollow`,
      {}
    );
  }

  getProjectFollowers(
    projectId: string,
    searchParams?: Partial<SearchParams>
  ): Observable<{ data: ProjectFollower[]; meta: MetaData }> {
    const { query, page, records } = searchParams || {};
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(records && { ['records']: records.toString() }),
        },
      }),
    };
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/projects/${projectId}/followers`,
        httpOptions
      )
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (!apiResponse?.data?.length)
            return { data: [], meta: { totalPages: 0, totalRecords: 0 } };
          const _followers: {
            data: ProjectFollower[];
            meta: MetaData;
          } = normalizeApiResponse(apiResponse, 'projectFollower', true, true);
          return _followers;
        })
      );
  }

  // gets the projects the user is member of
  getMemberProjects(
    userId: string,
    searchParams?: Partial<SearchParams>
  ): Observable<{ data: Project[]; meta: MetaData }> {
    const { query, page, records } = searchParams || {};
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(records && { ['records']: records.toString() }),
        },
      }),
    };
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/identities/${userId}/projects/member`,
        httpOptions
      )
      .pipe(map(normalizedProjects));
  }

  // gets the projects the user is following
  getFollowingProjects(
    userId: string,
    searchParams?: Partial<SearchParams>
  ): Observable<{ data: Project[]; meta: MetaData }> {
    const { query, page, records } = searchParams || {};
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(records && { ['records']: records.toString() }),
        },
      }),
    };
    return this.http
      .get(
        `${environment.API_23MOONCHECK_URL}/identities/${userId}/projects/following`,
        httpOptions
      )
      .pipe(map(normalizedProjects));
  }
}
