export class Message {
	mailRExp: '^\\S+@\\S+$';
	// regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
	regexp = new RegExp(
		'^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$'
	);

	constructor(messagesData: any) {
		// const obfuscatedContent: string = messagesData.content;
		// obfuscatedContent.replace(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]) | (?:(?:\+?([1-9]|[0-9][0-9]|[0-9][0-9][0-9])\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([0-9][1-9]|[0-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/g, '[removed]');
		// console.log (obfuscatedContent);

		this.id = messagesData.id;
		this.uniqueId = messagesData.unique_id;
		this.content = messagesData.content
			// tslint:disable-next-line:max-line-length
			.replace(
				/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]) | (?:(?:\+?([1-9]|[0-9][0-9]|[0-9][0-9][0-9])\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([0-9][1-9]|[0-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/g,
				'[removed]'
			);
		// .replace(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, '[phone]');
		this.sourceAlias = messagesData.source_alias;
		this.targetAlias = messagesData.target_alias;
		this.createdAt = messagesData.created_at;

		this.contextId = messagesData.context_id;
		this.parentId = messagesData.parent_id;

		this.source = messagesData.source;
		this.sourceEmail = messagesData.source_email;
		this.sourcePhone = messagesData.source_phone;
		this.target = messagesData.target;

		this.targetEmail = messagesData.target_email;
		this.targetPhone = messagesData.target_phone;
		this.value = messagesData.value;
		this.dataSource = messagesData.data_source;

		this.dataSourceId = messagesData.data_source_id;
		this.dataSourceType = messagesData.data_source_type;
		this.dataSourceAlias = messagesData.data_source_alias;
		this.createdBy = messagesData.created_by;

		this.updatedBy = messagesData.updated_by;
		this.updatedAt = messagesData.updated_at;
		this.payload = messagesData.payload;

		this.targetId = messagesData.target_id;
		this.targetDeviceId = messagesData.target_device_id;
		this.targetType = messagesData.target_type;
	}

	id: number;
	uniqueId: string;
	content: string;
	sourceAlias: string;
	targetAlias: string;
	createdAt: Date;

	contextId: string;
	parentId: string;

	source: string;
	sourceEmail: string;
	sourcePhone: string;
	target: string;

	targetEmail: string;
	targetPhone: string;
	value: string;
	dataSource: string;

	dataSourceId: string;
	dataSourceType: string;
	dataSourceAlias: string;
	createdBy: string;

	updatedBy: string;
	updatedAt: Date;

	payload: any;

	targetId: string;
	targetDeviceId: string;
	targetType: string;
}
