import {
  MooncheckActionTypes,
  MooncheckActions,
} from '../actions/mooncheck.actions';
import { UserIdentity } from '../models/user-identity.model';

export interface MooncheckState {
  userIdentity: UserIdentity;
  isIdentityLoaded: boolean;
}

export const initialMooncheckState: MooncheckState = {
  userIdentity: undefined,
  isIdentityLoaded: false,
};

export function mooncheckReducer(
  state = initialMooncheckState,
  action: MooncheckActions
): MooncheckState {
  switch (action.type) {
    case MooncheckActionTypes.IdentityLoaded: {
      return {
        ...state,
        isIdentityLoaded: true,
        userIdentity: action.payload.userIdentity,
      };
    }
    default:
      return state;
  }
}
