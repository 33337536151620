import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OnboardingState } from '../reducers/onboarding.reducers';

export const selectOnboardingState =
  createFeatureSelector<OnboardingState>('onboarding');
export const selectUserJourney = createSelector(
  selectOnboardingState,
  (onboardingState) => onboardingState.userJourney
);
export const selectOnboardingConfig = createSelector(
  selectOnboardingState,
  (onboardingState) => onboardingState.onboardingConfig
);

export const selectOnboardingSteps = createSelector(
  selectOnboardingConfig,
  (config) =>
    [...(config?.steps || [])].sort((a, b) => Number(a.order) - Number(b.order))
);

export const selectOnboardingStep = createSelector(
  selectOnboardingState,
  (onboardingState) => onboardingState.onboardingStep
);
export const selectUserIdentity = createSelector(
  selectOnboardingState,
  (onboardingState) => onboardingState.userIdentity
);
export const isIdentityLoaded = createSelector(
  selectOnboardingState,
  (onboardingState) => onboardingState.isIdentityLoaded
);

export const isOnboardingComplete = createSelector(
  selectOnboardingState,
  (onboardingState) => onboardingState.isOnboardingComplete
);

export const isUserJourneyFetched = createSelector(
  selectOnboardingState,
  (onboardingState) => onboardingState.isUserJourneyFetched
);

export const isUserJourneyLoaded = createSelector(
  selectOnboardingState,
  (onboardingState) => onboardingState.isUserJourneyLoaded
);

export const isCurrentStepComplete = createSelector(
  selectOnboardingState,
  (onboardingState) => onboardingState.isCurrentStepComplete
);

export const isOnboardingConfigLoaded = createSelector(
  selectOnboardingState,
  (onboardingState) => onboardingState.isOnboardingConfigLoaded
);

export const isUserJourneyLoading = createSelector(
  selectOnboardingState,
  (onboardingState) => onboardingState.isUserJourneyLoading
);
