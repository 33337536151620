import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  filter,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';
import { environment } from 'src/environments/environment';
import {
  AllRolesLoaded,
  AuthActionTypes,
  currentAuthUser,
  RoleActionTypes,
  selectRoleById,
  User,
  UserLoaded,
} from 'src/app/core/23blocks/gateway';
import {
  CreateIdentity,
  FormsActionTypes,
  IdentityLoaded,
  IdentityRequested,
} from '../actions/forms.actions';
import { FormsService } from '../services/forms.service';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
import { UserIdentityData } from '../models/forms.interfaces';
import {
  getRoleNameForIdentity,
  getRoleUniqueId,
} from 'src/app/core/tools/helper-functions';

@Injectable()
export class FormsEffects {
  loadIdentity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<IdentityRequested>(FormsActionTypes.IdentityRequested),
        concatLatestFrom((action) => this.store.select(currentAuthUser)),
        mergeMap(([action, user]) => {
          // console.log('Fetching forms Identity');
          return this.forms.getUserIdentity(user.uniqueId);
        }),
        tap(
          (apiResponse) => {
            if (apiResponse.data) {
              // console.log('Ya existe identidad forms');
              const data: any = normalize(apiResponse);
              const _userIdentity = build(
                data,
                'userIdentity',
                apiResponse.data.id,
                {
                  eager: true,
                }
              );
              this.store.dispatch(
                new IdentityLoaded({
                  userIdentity: _userIdentity,
                })
              );
            } else {
              // console.log('No existe identidad FORMS');
              this.store.dispatch(new CreateIdentity());
            }
          },
          (error) => {
            // console.log('Error fetching identidad FORMS');
            // this.store.dispatch(
            //   new IdentityLoaded({ userIdentity: null, firstTime: false })
            // );
            this.store.dispatch(new CreateIdentity());
          }
        )
      ),
    { dispatch: false }
  );

  // @Effect({ dispatch: false })
  createIdentity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CreateIdentity>(FormsActionTypes.CreateIdentity),
        concatLatestFrom((action) => this.store.select(currentAuthUser)),
        switchMap(([action, user]) => {
          // console.log('Creando Identidad Forms...');
          const userData = this.buildUserData(user);
          return this.forms.createUserIdentity(user.uniqueId, userData);
        }),
        tap(
          (apiResponse) => {
            console.log('Se agrego identidad Forms');
            const data: any = normalize(apiResponse);
            const _userIdentity = build(
              data,
              'userIdentity',
              apiResponse.data.id,
              {
                eager: true,
              }
            );
            this.store.dispatch(
              new IdentityLoaded({ userIdentity: _userIdentity })
            );
          },
          (error) => {
            console.log(error, 'Error al agregar identidad Forms');
          }
        )
      ),
    { dispatch: false }
  );

  buildUserData(user: User): UserIdentityData {
    const role_name = getRoleNameForIdentity(user.roleId);
    const role_unique_id = getRoleUniqueId(user.roleId);

    return {
      name: user.name || 'User Name',
      phone:
        user.phone ||
        (Math.floor(Math.random() * 9000000000) + 1000000000).toString(),
      email: user.email || 'example@email.com',
      user_unique_id: user.uniqueId,
      role_name,
      role_unique_id,
    };
  }

  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AllRolesLoaded>(RoleActionTypes.AllRolesLoaded),
        tap((action) => {
          // this.store.dispatch(new IdentityRequested());
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private forms: FormsService,
    private store: Store<AppState>
  ) {}
}
