import { Injectable, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { selectAccessToken } from '../../gateway';
import { AuthToken } from '../../gateway/models/gateway.interfaces';
import { isPlatformServer } from '@angular/common';
import {
  Onboarding23blocksOptions,
  OnboardingStepData,
  OnboardingData,
  ONBOARDING_23BLOCKS_SERVICE_OPTIONS,
  NewOnboardingStepData,
  MailTemplateData,
} from '../models/onboarding.interfaces';
import { ApiResponse } from '../../models/api-response.model';
import { UserIdentityData } from '../models/onboarding.interfaces';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  public authData: BehaviorSubject<AuthToken> = new BehaviorSubject<AuthToken>(
    null
  );

  get tokenOptions(): Onboarding23blocksOptions {
    return this.options;
  }
  set tokenOptions(options: Onboarding23blocksOptions) {
    this.options = (Object as any).assign(this.options, options);
  }
  private options: Onboarding23blocksOptions;
  private appId: string;
  private companyToken: string;
  constructor(
    private http: HttpClient,
    @Inject(ONBOARDING_23BLOCKS_SERVICE_OPTIONS) config: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() private activatedRoute: ActivatedRoute,
    @Optional() private router: Router,
    private store: Store<AppState>
  ) {
    const defaultOptions: Onboarding23blocksOptions = {
      apiPath: null,
      apiBase: null,
      APPID: null,
    };
    const mergedOptions = (Object as any).assign(defaultOptions, config);
    this.options = mergedOptions;
    if (this.options.apiBase === null) {
      console.warn(
        `[Onboarding 23Blocks] You have not configured 'apiBase', which may result in security issues. ` +
          `Please refer to the documentation at https://github.com/neroniaky/angular-token/wiki`
      );
    }
  }
  private checkAuthData(authData: AuthToken): boolean {
    if (
      authData.companyToken != null &&
      authData.accessToken != null &&
      authData.client != null &&
      authData.expiry != null &&
      authData.tokenType != null &&
      authData.uid != null &&
      authData.appid != null
    ) {
      if (this.authData.value != null) {
        return authData.expiry >= this.authData.value.expiry;
      }
      return true;
    }
    return false;
  }

  public getAuthDataFromStorage(): void {
    const authData: AuthToken = {
      companyToken: localStorage.getItem('companyToken'),
      accessToken: localStorage.getItem('accessToken'),
      client: localStorage.getItem('client'),
      expiry: localStorage.getItem('expiry'),
      tokenType: localStorage.getItem('tokenType'),
      uid: localStorage.getItem('uid'),
      appid: localStorage.getItem('appid'),
    };

    if (this.checkAuthData(authData)) {
      this.authData.next(authData);
    }
  }

  createOnboarding(onboardingData: OnboardingData): Observable<ApiResponse> {
    return this.http.post(environment.API_23ONBOARDING_URL + '/onboardings', {
      onboarding: onboardingData,
    });
  }

  createUserIdentity(
    userId: string,
    onboardingId: string,
    dataForm: UserIdentityData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23ONBOARDING_URL +
        '/users/' +
        userId +
        '/register/' +
        onboardingId,
      {
        user: dataForm,
      }
    );
  }

  getUserIdentity(userUniqueId: string): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        APPID: this.appId,
        'company-token': this.companyToken,
      }),
    };
    return this.http.get(
      environment.API_23ONBOARDING_URL + '/users/' + userUniqueId,
      httpOptions
    );
  }

  addStepToOnboarding(
    onboardingUniqueId: string,
    newStepData: NewOnboardingStepData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23ONBOARDING_URL +
        '/onboardings/' +
        onboardingUniqueId +
        '/steps',
      {
        step: newStepData,
      }
    );
  }
  // Get onboarding configuration
  getOnboardingConfiguration(
    onboardingUniqueId: string
  ): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23ONBOARDING_URL + '/onboardings/' + onboardingUniqueId
    );
  }

  // Get User Journey
  getUserJourney(onboardingUniqueId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23ONBOARDING_URL + '/onboard/' + onboardingUniqueId
    );
  }

  // Create User Journey (for compatibility)
  createUserJourney(
    onboardingUniqueId: string,
    userId: string
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23ONBOARDING_URL}/onboard/${onboardingUniqueId}/start`,
      { user: { user_unique_id: userId } }
    );
  }

  getOnboardDetails(onboardingUniqueId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23ONBOARDING_URL +
        '/onboard/' +
        onboardingUniqueId +
        '/details'
    );
  }

  stepOnboard(
    onboardingUniqueId: string,
    stepData: OnboardingStepData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23ONBOARDING_URL + '/onboard/' + onboardingUniqueId,
      { onboard: stepData }
    );
  }

  // ADMIN TOOL
  getUserOnboardings(userUniqueId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23ONBOARDING_URL + '/users/' + userUniqueId + '/journey'
    );
  }

  // ADMIN TOOL
  stepUserOnboard(
    onboardingUniqueId: string,
    userUniqueId: string,
    stepData: OnboardingStepData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23ONBOARDING_URL +
        '/onboardings/' +
        onboardingUniqueId +
        '/users/' +
        userUniqueId,
      { onboard: stepData }
    );
  }

  getAllMailTemplates(): Observable<ApiResponse> {
    return this.http.get(environment.API_23ONBOARDING_URL + '/mailtemplates/');
  }

  getMailTemplate(templateId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23ONBOARDING_URL + '/mailtemplates/' + templateId
    );
  }

  updateMailTemplate(
    templateId: string,
    templateData: MailTemplateData
  ): Observable<ApiResponse> {
    const body = { template: templateData };
    return this.http.put(
      environment.API_23ONBOARDING_URL + '/mailtemplates/' + templateId,
      body
    );
  }

  updateUserIdentity(
    userUniqueId: string,
    userIdentityData: UserIdentityData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23ONBOARDING_URL + '/users/' + userUniqueId,
      { user: userIdentityData }
    );
  }
}
