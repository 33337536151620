import { Action } from '@ngrx/store';
import { UserIdentity } from '../models/user-identity.model';

export enum ContentActionTypes {
  IdentityRequested = '[Request User Content Identity] API',
  IdentityLoaded = '[Load User Content Identity] API',
  CreateIdentity = '[Create User Content Identity] API',
}

export class IdentityRequested implements Action {
  readonly type = ContentActionTypes.IdentityRequested;
}
export class IdentityLoaded implements Action {
  readonly type = ContentActionTypes.IdentityLoaded;
  constructor(public payload: { userIdentity: UserIdentity }) {}
}

export class CreateIdentity implements Action {
  readonly type = ContentActionTypes.CreateIdentity;
}

export type ContentActions =
  | IdentityRequested
  | IdentityLoaded
  | CreateIdentity;
