import { Inject, Injectable } from '@angular/core';
import { AppState } from 'src/app/core/reducers';
import { Store, select } from '@ngrx/store';
import { selectAccessToken } from '../../gateway';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ApiResponse } from '../../models/api-response.model';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { AuthToken } from '../../gateway/models/gateway.interfaces';
import {
  AWSUrl,
  CatalogData,
  CategoryData,
  ChannelData,
  CompanyKeysData,
  NewTenantData,
  OrderCartData,
  PriceData,
  ProductData,
  Products23blocksOptions,
  ProductStockData,
  PRODUCTS_23BLOCKS_SERVICE_OPTIONS,
  PromotionData,
  RegisterFileData,
  StockData,
  UserIdentityData,
  VendorData,
  WarehouseData,
  SearchParams,
} from '../models/products.interfaces';
import { Category, Product } from '..';
import { catchError, map, mergeMap } from 'rxjs/operators';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
import { CartDetails } from '../models/cart-details.model';
import { normalizeApiResponse } from 'src/app/common/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  public authData: BehaviorSubject<AuthToken> = new BehaviorSubject<AuthToken>(
    null
  );

  get tokenOptions(): Products23blocksOptions {
    return this.options;
  }

  set tokenOptions(options: Products23blocksOptions) {
    this.options = (Object as any).assign(this.options, options);
  }

  private options: Products23blocksOptions;
  private appId: string;
  private companyToken: string;

  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    @Inject(PRODUCTS_23BLOCKS_SERVICE_OPTIONS) config: any
  ) {
    const defaultOptions: Products23blocksOptions = {
      apiPath: null,
      apiBase: null,
      APPID: null,
    };

    const mergedOptions = (Object as any).assign(defaultOptions, config);
    this.options = mergedOptions;

    if (this.options.apiBase === null) {
      console.warn(
        `[Products 23Blocks] You have not configured 'apiBase', which may result in security issues. ` +
          `Please refer to the documentation at https://github.com/neroniaky/angular-token/wiki`
      );
    }
  }

  private checkAuthData(authData: AuthToken): boolean {
    if (
      authData.companyToken != null &&
      authData.accessToken != null &&
      authData.client != null &&
      authData.expiry != null &&
      authData.tokenType != null &&
      authData.uid != null &&
      authData.appid != null
    ) {
      if (this.authData.value != null) {
        return authData.expiry >= this.authData.value.expiry;
      }
      return true;
    }
    return false;
  }

  public getAuthDataFromStorage(): void {
    const authData: AuthToken = {
      companyToken: localStorage.getItem('companyToken'),
      accessToken: localStorage.getItem('accessToken'),
      client: localStorage.getItem('client'),
      expiry: localStorage.getItem('expiry'),
      tokenType: localStorage.getItem('tokenType'),
      uid: localStorage.getItem('uid'),
      appid: localStorage.getItem('appid'),
    };

    if (this.checkAuthData(authData)) {
      this.authData.next(authData);
    }
  }

  createUserIdentity(
    userUniqueId: string,
    dataForm: UserIdentityData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23PRODUCTS_URL + '/users/' + userUniqueId + '/register/',
      {
        user: dataForm,
      }
    );
  }

  getUserIdentity(userUniqueId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23PRODUCTS_URL + '/users/' + userUniqueId
    );
  }

  getCarts(
    query?: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23PRODUCTS_URL + '/carts',
      httpOptions
    );
  }

  // Returns 'cart' and 'cartDetail' object
  getCart(userId: string): Observable<ApiResponse> {
    return this.http.get(environment.API_23PRODUCTS_URL + '/carts/' + userId);
  }

  deleteCart(userId: string): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23PRODUCTS_URL + '/carts/' + userId
    );
  }

  getProducts(
    searchParams: Partial<SearchParams>,
    withPrice?: boolean
  ): Observable<ApiResponse> {
    const { query, page, perPage, categoryId, sku } = searchParams;
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
          ...(categoryId && { ['fcategory']: categoryId.toString() }),
          ...(withPrice && { ['with_price']: true }),
          ...(sku && { ['sku']: sku }),
        },
      }),
    };
    return this.http.get(
      environment.API_23PRODUCTS_URL + '/products',
      httpOptions
    );
  }

  getNormalizedProducts(
    searchParams: Partial<SearchParams>,
    withPrice?: boolean
  ): Observable<Product[]> {
    return this.getProducts(searchParams, withPrice)
      .pipe(
        map(
          (apiResponse) =>
            normalizeApiResponse(apiResponse, 'product', true) as Product[]
        )
      )
      .pipe(catchError((err) => of([])));
  }

  getProduct(productId: string): Observable<ApiResponse> {
    return this.http.get(
      `${environment.API_23PRODUCTS_URL}/products/${productId}?with_cost=true`
    );
  }

  getNormalizedProduct(productId: string): Observable<Product> {
    return this.getProduct(productId)
      .pipe(
        map((apiResponse) =>
          normalizeApiResponse(apiResponse, 'product', false)
        )
      )
      .pipe(catchError((err) => of(null)));
  }

  getCategories(searchParams: Partial<SearchParams>): Observable<ApiResponse> {
    const { query, page, perPage } = searchParams;
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23PRODUCTS_URL + '/categories',
      httpOptions
    );
  }

  getNormalizedCategories(
    searchParams: Partial<SearchParams>
  ): Observable<Category[]> {
    return this.getCategories(searchParams)
      .pipe(
        map((apiResponse) => {
          const data: any = normalize(apiResponse);
          const _categories: Category[] =
            build(data, 'category', null, {
              eager: true,
            }) || [];
          return _categories;
        })
      )
      .pipe(catchError((err) => of([])));
  }

  getCategory(
    categoryId: string,
    withStock?: boolean,
    searchParams?: Partial<SearchParams>
  ): Observable<ApiResponse> {
    const stockParam = withStock ? '?with_stock=true' : '';
    const { query, page, perPage } = searchParams || {};
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23PRODUCTS_URL + '/categories/' + categoryId + stockParam,
      httpOptions
    );
  }

  getNormalizedCategory(
    categoryId: string,
    withStock?: boolean
  ): Observable<Category> {
    return this.getCategory(categoryId, withStock)
      .pipe(
        map((apiResponse) => {
          if (!apiResponse?.data) return null;
          const data: any = normalize(apiResponse);
          const _category: Category =
            build(data, 'category', apiResponse.data.id, {
              eager: true,
            }) || null;
          return _category;
        })
      )
      .pipe(catchError((err) => of(null)));
  }

  createProduct(
    productData: ProductData,
    stockData?: ProductStockData
  ): Observable<ApiResponse> {
    return this.http.post(environment.API_23PRODUCTS_URL + '/products', {
      product: productData,
      ...(stockData && { ['stock']: stockData }),
    });
  }

  createCart(userId: string, notes?: string): Observable<ApiResponse> {
    return this.http.post(environment.API_23PRODUCTS_URL + '/carts', {
      cart: {
        user_unique_id: userId,
        notes,
        delivery: 0,
      },
    });
  }

  updateProduct(
    productUniqueId: string,
    productData: ProductData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23PRODUCTS_URL + '/products/' + productUniqueId,
      { product: productData }
    );
  }

  deleteProduct(productUniqueId: string): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23PRODUCTS_URL + '/products/' + productUniqueId
    );
  }

  createCategory(categoryData: CategoryData): Observable<ApiResponse> {
    return this.http.post(environment.API_23PRODUCTS_URL + '/categories', {
      category: categoryData,
    });
  }

  updateCategory(
    categoryUniqueId: string,
    categoryData: CategoryData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23PRODUCTS_URL + '/categories/' + categoryUniqueId,
      { category: categoryData }
    );
  }

  addProductToCategory(
    productUniqueId: string,
    categoryUniqueId: string
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23PRODUCTS_URL +
        '/products/' +
        productUniqueId +
        '/categories',
      { product: { category_unique_id: categoryUniqueId } }
    );
  }

  removeProductFromCategory(
    productUniqueId: string,
    categoryUniqueId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23PRODUCTS_URL}/products/${productUniqueId}/categories/${categoryUniqueId}`
    );
  }

  addRecommendedProduct(
    productUniqueId: string,
    recommendedProductUniqueId: string
  ): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        APPID: this.appId,
        'company-token': this.companyToken,
      }),
    };
    return this.http.post(
      environment.API_23PRODUCTS_URL +
        '/products/' +
        productUniqueId +
        '/suggestions',
      { product: { product_unique_id: recommendedProductUniqueId } },
      httpOptions
    );
  }

  getProductSuggestions(productId: string): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        APPID: this.appId,
        'company-token': this.companyToken,
      }),
    };
    return this.http.get(
      environment.API_23PRODUCTS_URL +
        '/products/' +
        productId +
        '/suggestions',
      httpOptions
    );
  }

  presignUploadProductFile(
    productId: string,
    filename: string
  ): Observable<AWSUrl> {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     APPID: this.appId,
    //     'company-token': this.companyToken,
    //   }),
    // };
    return this.http.put<AWSUrl>(
      environment.API_23PRODUCTS_URL + '/products/' + productId + '/presign',
      { filename }
      // httpOptions
    );
  }

  presignUploadCategoryFile(
    productId: string,
    filename: string
  ): Observable<AWSUrl> {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     APPID: this.appId,
    //     'company-token': this.companyToken,
    //   }),
    // };
    return this.http.put<AWSUrl>(
      environment.API_23PRODUCTS_URL + '/categories/' + productId + '/presign',
      { filename }
      // httpOptions
    );
  }

  // Upload file to amazon
  uploadFileAWS(awsPresignedUrl: string, file: File): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': file.type,
      }),
    };
    return this.http.put(awsPresignedUrl, file, httpOptions);
  }

  addImageToProduct(
    productId: string,
    fileData: RegisterFileData
  ): Observable<ApiResponse> {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     APPID: this.appId,
    //     'company-token': this.companyToken,
    //   }),
    // };
    return this.http.post(
      environment.API_23PRODUCTS_URL + '/products/' + productId + '/images',
      { file: fileData }
      // httpOptions
    );
  }

  addImageToCategory(
    categoryId: string,
    fileData: RegisterFileData
  ): Observable<ApiResponse> {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     APPID: this.appId,
    //     'company-token': this.companyToken,
    //   }),
    // };
    return this.http.post(
      environment.API_23PRODUCTS_URL + '/categories/' + categoryId + '/images',
      { file: fileData }
      // httpOptions
    );
  }

  deleteProductImage(
    productId: string,
    imageId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23PRODUCTS_URL +
        `/products/${productId}/images/${imageId}`
    );
  }

  /* Vendors */

  createVendor(vendor: VendorData): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: this.appId,
      //   'company-token': this.companyToken,
      // }),
    };
    return this.http.post(
      environment.API_23PRODUCTS_URL + '/vendors',
      { vendor },
      httpOptions
    );
  }

  getVendors(): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        // APPID: this.appId,
        // 'company-token': this.companyToken,
      }),
    };
    return this.http.get(
      environment.API_23PRODUCTS_URL + '/vendors',
      httpOptions
    );
  }

  /* Services */

  removeCartDetail(
    cartId: string,
    cartDetailId: string
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23PRODUCTS_URL}/carts/${cartId}/details/${cartDetailId}/cancel`,
      {}
    );
  }

  updateCartItemQtyNoUpdate(
    userId: string,
    sku: string,
    qty: number,
    category: Category,
    isVariation: boolean,
    productNotes: string
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23PRODUCTS_URL + '/carts/menus/noupdate',
      {
        cart: {
          user_unique_id: userId,
          notes: 'notes',
        },
        product: {
          sku,
          notes: productNotes,
          quantity: qty,
          category_name: category.name,
          category_unique_id: category.uniqueId,
          is_variation: isVariation,
        },
      }
    );
  }

  updateCartItemQtyUpdate(
    userId: string,
    sku: string,
    qty: number,
    category: Category,
    isVariation: boolean
  ): Observable<ApiResponse> {
    return this.http.put(environment.API_23PRODUCTS_URL + '/carts', {
      cart: {
        user_unique_id: userId,
        notes: 'notes',
      },
      product: {
        sku,
        // 'notes': item.notes || '',
        quantity: qty,
        category_name: category.name,
        category_unique_id: category.uniqueId,
        is_variation: isVariation,
      },
    });
  }

  updateCartPayload(userId: string, payload: string): Observable<ApiResponse> {
    return this.http.put(environment.API_23PRODUCTS_URL + `/carts/${userId}`, {
      cart: {
        user_unique_id: userId,
        payload,
      },
    });
  }

  orderCart(
    userId: string,
    cartId: string,
    orderData: OrderCartData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23PRODUCTS_URL + `/carts/${userId}/order`,
      {
        order: {
          user_unique_id: userId,
          cart_unique_id: cartId,
          notes: '23blocks Order Cart',
          order_unique_id: orderData.order_unique_id,
          order_system: orderData.order_system,
          order_display_id: orderData.order_display_id,
          order_status: orderData.order_status,
        },
      }
    );
  }

  // ASSOCIATE Order to Cart
  associateOrderToCart(
    userId: string,
    orderId: string,
    displayId: string,
    orderStatus: string
  ): Observable<ApiResponse> {
    return this.http.put(environment.API_23PRODUCTS_URL + `/carts/${userId}`, {
      cart: {
        order_unique_id: orderId,
        order_display_id: displayId,
        order_status: orderStatus,
        order_system: '23Blocks Sales',
      },
    });
  }

  // Order cart product
  orderCartProduct(
    userId: string,
    orderId: string,
    cartId: string,
    productId: string,
    orderData: OrderCartData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23PRODUCTS_URL +
        '/carts/' +
        userId +
        '/products/' +
        productId +
        '/order',
      {
        order: {
          user_unique_id: userId,
          cart_unique_id: cartId,
          order_unique_id: orderId,
          product_unique_id: productId,
          notes: '23blocks order cart product',
          order_system: orderData.order_system,
          order_display_id: orderData.order_display_id,
          order_status: orderData.order_status,
        },
      }
    );
  }

  /* Variations */

  // get product variations
  getProductVariations(productId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23PRODUCTS_URL + `/products/${productId}/variations`
    );
  }

  // add product variation to product
  addProductVariation(productId: string, variation): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23PRODUCTS_URL + `/products/${productId}/variations`,
      {
        variation: {
          name: variation.name,
          price: variation.price,
          discount: variation.discount || 0,
          tax: variation.tax || 0.19,
        },
      }
    );
  }

  // WIP: update product variation
  updateProductVariation(
    productId: string,
    variationId: string,
    variation: ProductData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23PRODUCTS_URL +
        `/products/${productId}/variations/${variationId}`,
      {
        variation,
      }
    );
  }

  addBlock(
    tenantAppId: string,
    companyData: NewTenantData
  ): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        blockAppId: tenantAppId,
      }),
    };
    return this.http.post<ApiResponse>(
      environment.API_23PRODUCTS_URL + '/companies',
      { company: companyData },
      httpOptions
    );
  }

  /* Channels */

  getChannels(searchParams: Partial<SearchParams>): Observable<ApiResponse> {
    const { query, page, perPage } = searchParams;
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23PRODUCTS_URL + `/channels`,
      httpOptions
    );
  }

  getChannel(channelId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23PRODUCTS_URL + `/channels/${channelId}`
    );
  }

  addChannel(channel: ChannelData): Observable<ApiResponse> {
    return this.http.post(environment.API_23PRODUCTS_URL + `/channels`, {
      channel,
    });
  }

  editChannel(
    channelId: string,
    channel: ChannelData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23PRODUCTS_URL + `/channels/${channelId}`,
      {
        channel,
      }
    );
  }

  /* Warehouses */

  getWarehouses(searchParams: Partial<SearchParams>): Observable<ApiResponse> {
    const { query, page, perPage } = searchParams;
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23PRODUCTS_URL + '/warehouses',
      httpOptions
    );
  }

  getWarehouse(warehouseId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23PRODUCTS_URL + `/warehouses/${warehouseId}`
    );
  }

  addWarehouse(warehouse: WarehouseData): Observable<ApiResponse> {
    return this.http.post(environment.API_23PRODUCTS_URL + `/warehouses`, {
      warehouse,
    });
  }

  editWarehouse(
    warehouseId: string,
    warehouse: WarehouseData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23PRODUCTS_URL + `/warehouses/${warehouseId}`,
      {
        warehouse,
      }
    );
  }

  // Keys

  addCompanyKeys(
    // impersonalizationData: { appId: string; companyToken: string },
    companyUniqueId: string,
    companyKeysData: CompanyKeysData
  ): Observable<ApiResponse> {
    const httpOptions = {
      // headers: new HttpHeaders({
      //   APPID: impersonalizationData.appId,
      //   'company-token': impersonalizationData.companyToken,
      // }),
    };
    return this.http.post<ApiResponse>(
      environment.API_23PRODUCTS_URL +
        '/companies/' +
        companyUniqueId +
        '/keys',
      { company_key: companyKeysData },
      httpOptions
    );
  }

  deleteCompanyKeys(
    companyId: string,
    keysId: string
  ): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      environment.API_23PRODUCTS_URL +
        '/companies/' +
        companyId +
        '/keys/' +
        keysId
    );
  }

  getCompanies(userId: string): Observable<ApiResponse> {
    // console.log('Get Accounts');
    return this.http.get(
      environment.API_23PRODUCTS_URL + '/users/' + userId + '/companies'
    );
  }

  // Account
  getCompany(urlId: string): Observable<ApiResponse> {
    // console.log('Get Company');
    return this.http.get(
      environment.API_23PRODUCTS_URL + '/companies/' + urlId
    );
  }

  /* Catalogs */

  getCatalogs(searchParams: Partial<SearchParams>): Observable<ApiResponse> {
    const { query, page, perPage } = searchParams;
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23PRODUCTS_URL + '/catalogs',
      httpOptions
    );
  }

  getCatalog(
    catalogId: string,
    withPrice?: boolean,
    withStock?: boolean
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(withPrice && { ['with_price']: true }),
          ...(withStock && { ['with_stock']: true }),
        },
      }),
    };
    return this.http.get(
      environment.API_23PRODUCTS_URL + '/catalogs/' + catalogId,
      httpOptions
    );
  }

  addCatalog(catalog: CatalogData): Observable<ApiResponse> {
    return this.http.post(environment.API_23PRODUCTS_URL + '/catalogs', {
      catalog,
    });
  }

  editCatalog(catalogId, catalog: CatalogData): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23PRODUCTS_URL + '/catalogs/' + catalogId,
      {
        catalog,
      }
    );
  }

  deleteCatalog(catalogId: string): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23PRODUCTS_URL + '/catalogs/' + catalogId
    );
  }

  addProductToCatalog(
    productId: string,
    catalogId: string
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23PRODUCTS_URL + `/products/${productId}/catalogs`,
      {
        catalog: {
          unique_id: catalogId,
        },
      }
    );
  }

  /* Promotions */

  getProductPromotions(productId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23PRODUCTS_URL + `/products/${productId}/promotions`
    );
  }

  getProductPromotion(
    productId: string,
    promotionId: string
  ): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23PRODUCTS_URL +
        `/products/${productId}/promotions/${promotionId}`
    );
  }

  createProductPromotion(
    productId: string,
    promotion: PromotionData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23PRODUCTS_URL + `/products/${productId}/promotions`,
      { promotion }
    );
  }

  editProductPromotion(
    productId: string,
    promotionId: string,
    promotion: PromotionData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23PRODUCTS_URL +
        `/products/${productId}/promotions/${promotionId}`,
      { promotion }
    );
  }

  deleteProductPromotion(
    productId: string,
    promotionId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23PRODUCTS_URL +
        `/products/${productId}/promotions/${promotionId}`
    );
  }

  /* Prices */

  getProductPrices(productId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23PRODUCTS_URL + `/products/${productId}/prices`
    );
  }

  getProductPrice(productId: string, priceId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23PRODUCTS_URL +
        `/products/${productId}/prices/${priceId}`
    );
  }

  createProductPrice(
    productId: string,
    price: PriceData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23PRODUCTS_URL + `/products/${productId}/prices`,
      { price }
    );
  }

  editProductPrice(
    productId: string,
    price: PriceData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23PRODUCTS_URL + `/products/${productId}/prices`,
      { price }
    );
  }

  /* Product Inventory */

  createProductStock(
    productId: string,
    stock: StockData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23PRODUCTS_URL + `/products/${productId}/stock`,
      { stock }
    );
  }

  updateProductStock(
    productId: string,
    stockId: string,
    stock: StockData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23PRODUCTS_URL +
        `/products/${productId}/stock/${stockId}`,
      { stock }
    );
  }
}
