// USA
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      COURSES: 'Courses',
      PROFILE: 'Profile',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: "Don't have an account yet?",
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
        GOOGLE_RECAPTCHA: 'This site is protected by reCAPTCHA and the Google',
        GOOGLE_RECAPTCHA1: 'Privacy Policy',
        GOOGLE_RECAPTCHA2: 'Terms of Service',
        GOOGLE_RECAPTCHA_AND: 'and',
        GOOGLE_RECAPTCHA_APPLY: ' apply.',
      },
      LOGIN: {
        TITLE: 'Sign In',
        BUTTON: 'Sign In',
        PASSWORD_OR_USER_INVALID: 'Invalid identity or password.',
      },
      FORGOT: {
        TITLE: 'Forgot Password',
        DESC: 'We will send you an email with instructions on how to reset your password',
        SUCCESS:
          'You will receive an email with instructions on how to reset your password in a few minutes.',
        FORGOT_BUTTON: 'Email Me',
        FORGOT_EMAIL: `I don't remember my email`,
        RESEND_EMAIL_LINK: 'Resend confirmation Email',
        RESEND_EMAIL_SUCCESS:
          'Success!! Confirmation email was sent with instructions on how to activate your account',
        EMAIL_AND_SUBJECT: 'mailto:inhance@inhance.life?subject=Access problem',
        ERROR_NOT_FOUND_EMAIL_MESSAGE: 'Could not find a user with that email',
      },
      CHANGE_PASSWORD: {
        TITLE: 'Change Password',
        DESC: 'We will send you an email with instructions on how to reset your password',
        SUCCESS: 'Your password has been changed successfully.',
        FAILURE:
          'We can not change update your password at this time, please contact our support channel',
        CHANGE_BUTTON: 'Change Password',
        ONE_MORE_TRY: 'One more try?',
      },

      REGISTER: {
        TITLE: 'Sign Up',
        FUN_JUMPER_TITLE: 'Sign Up as Fun Jumper',
        AFF_TITLE: 'Sign Up as AFF',
        DESC: 'Enter your details to create your account',
        SUCCESS:
          'Your account has been successfully registered. We will send you an email with instructions on how to activate your account',
        INVITATION_SUCCESS:
          'Your account has been successfully registered. Now, you can Sign In',
        REGISTER_BUTTON: 'Agree & Join',
        BACK_TO_SIGNIN: 'Sign In',
        ACCOUNT_QUESTION: `Have an account?`,
        CONDITION_MESSAGE: 'By Clicking Sign Up, you agree to our',
        TERMS: 'Terms',
        PRIVACY: 'Privacy Policy',
        COOKIES: 'Cookies Policy',
        ERROR_MESSAGE: `The email is already registered. If you can't enter, <a href="/auth/forgot-password" class="text-white"> click here.</a>`,
        INVITATION_ERROR_MESSAGE: 'There was an error',
        WITHOUT_INVITATION: `You can't register without an invite`,
      },
      EMAIL_CONFIRMATION: {
        CONFIRM_EMAIL: 'Please confirm your email address',
        ACCOUNT_CREATED: 'Your account has been succesfully registered',
        WILL_RECEIVE_EMAIL:
          'We will send you an email with instructions on how to activate your account',
        NO_EMAIL: "Didn't get an email in your inbox?",
        RESEND_EMAIL_BUTTON: 'Resend email',
        RESEND: 'Resend',
        ENTER_EMAIL:
          'Enter your email and we will re-send you an email to confirm your account',
      },
      STEP2: {
        TITLE: 'We are all set',
        ALREADY_CONFIRMED:
          'Your email was already confirmed, please try signing in',
        CONFIRMATION_COMPLETE: 'Email confirmation completed. Thank you',
      },
      INPUT: {
        EMAIL: 'Email',
        NAME: 'Name',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username',
        NEW_PASSWORD: 'New Password',
        CONFIRM_NEW_PASSWORD: 'Confirm New Password',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
        PASSWORDS_NOT_MATCH: "Passsword and ConfirmPassword didn't match.",
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields',
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted',
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created',
        },
      },
    },
    GENERAL: {
      DAY: 'Day',
      LOADING: 'Loading',
      CONTENT_NOT_AVAILABLE: 'Content is not available yet',
      COURSES: 'Courses',
      START: 'Start',
      NEXT: 'Next',
      ERROR_MESSAGE: 'There was an error',
      DURATION: 'Duration',
      HELP: 'Help',
      INFO_USER: 'Info-user',
      STEP: 'Step',
    },
    ONBOARDING: {
      WIZARD_FIRST_TITLE: 'Select a course',
      WIZARD_SECOND_TITLE: 'Review and start',
      WIZARD_THIRD_TITLE: 'Validate your code',
      FIRST_STEP_TITLE: 'Select your prefer course',
      CONTENT_TITLE: 'What includes?',
      LIMITED_TIME_ACCESS: 'Limited time access',
      CONTACT_EMAIL:
        'If you have any questions, email us at inhance@inhance.life',
      SECOND_STEP_TITLE: 'Access your code',
      INSTRUCTIONS: 'Instructions:',
      INSTRUCTION_STEP_ONE:
        'Pay your course following the instructions on our web site www.inhance.life.',
      CODE_READY: 'If you already have a code, click',
      HERE: 'here',
      TO_VALIDATE: 'to validate',
      INSTRUCTION_STEP_TWO:
        'Once your payment is completed, you will received a code in the next 24 hours.',
      INSTRUCTION_STEP_THREE:
        'Once you have the code, log back on to the website and copy it into the "Validate Code" box (click NEXT).',
      INSTRUCTION_STEP_FOUR: 'Now you can start your couse.',
      THIRD_STEP_TITLE:
        'We need to validate your payment before you can enjoy the course',
      CODE: 'Code',
      CODE_REQUIRED: 'The code is required',
      COURSE_SELECTED_CORRECTLY: 'The course was selected correctly',
      COURSE_NOT_SELECTED: 'You did not select any course',
      CODE_VALIDATED: 'Your code has been successfully validated',
      CODE_INVALID: 'Invalid code, please try again',
      CODE_NOT_VALIDATED: 'You did not validate the code',
    },
    TOPBAR: {
      HELP_MESSAGE:
        'If you need help, please write to us at inhance@inhance.life',
    },
    QUICK_PANEL: {
      USER: 'User',
      LOGOUT_BUTTON: 'Logout',
    },
    PROFILE: {
      SECTION: 'Profile',
      TITLE: 'Basic Information',
      NAME: 'Name',
      EMAIL: 'Email',
      NOT_AVAILABLE: 'Not Available',
      QUESTION_NOT_AVAILABLE: 'Question ot available',
      ANSWER: 'Answer',
      NOT_ANSWER: 'Not Answer',
    },
    HEADER: {
      TITLE: 'Happiness University',
    },
  },
};
