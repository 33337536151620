import { normalizeApiResponse } from 'src/app/common/utils/utils';
import {
  BUSINESS_BREAKDOWNS,
  GLOBAL_PROJECT_DISCLOSURES,
} from '../constants/constants';
import { ApiResponse, MetaData } from '../models/mooncheck.interfaces';
import { Project } from '../models/mooncheck.models';

export const getBreakdownUniqueIdBySlug = (
  disclosureSlug: string,
  breakdownSlug: string
): string => {
  return GLOBAL_PROJECT_DISCLOSURES.find(
    (pd) => pd.slug === disclosureSlug
  ).breakdowns.find((bd) => bd.slug === breakdownSlug).uniqueId;
};

export const getDisclosureUniqueIdBySlug = (disclosureSlug: string): string => {
  return GLOBAL_PROJECT_DISCLOSURES.find((pd) => pd.slug === disclosureSlug)
    .uniqueId;
};

export const getPostUniqueIdFromContentUrl = (contentUrl: string) => {
  if (!contentUrl) return '';
  return contentUrl.split('/posts/')[1];
};

export function getFirstXWords(str: string, x: number): string {
  // Split the string by spaces into an array of words
  let words = str.split(' ');
  // Slice the array to get the first X elements
  let firstXWords = words.slice(0, x);
  // Join the array elements with spaces into a new string
  let result = firstXWords.join(' ');
  // Return the result
  return result;
}

export function getSingularOrPlural(
  amount: number,
  singularWord: string,
  pluralWord: string
) {
  if (!amount) return pluralWord;
  return amount > 1 ? pluralWord : singularWord;
}

export function preProcessDraft(str: string): string {
  const lines = str.trim().split(/\r?\n/);
  const h1 = lines.shift();
  const p = lines.join('</p><p>');

  // turns first line into h1, others into p tags
  const preProcessed = (h1 ? `<h1>${h1}</h1><p>${p}</p>` : p).replaceAll(
    '<p></p>',
    ''
  );

  // replace *** with h1 tags
  const preProcessedWithH1 = replaceEvenOdd(preProcessed, '<h1>', '</h1>');

  //remove extra *** chars
  const preProceseedWithRemovedExtraChars = preProcessedWithH1.replaceAll(
    '*',
    ''
  );
  return preProceseedWithRemovedExtraChars;
}

// replaces *** occurences
export function replaceEvenOdd(
  str: string,
  evenStr: string,
  oddStr: string
): string {
  let count = 0;
  return str.replace(new RegExp(/\*\*\*/, 'g'), (match) => {
    count++;
    return count % 2 === 0 ? evenStr : oddStr;
  });
}

export const normalizedProjects = (apiResponse: ApiResponse) => {
  if (!apiResponse?.data?.length)
    return { data: [], meta: { totalPages: 0, totalRecords: 0 } };

  const _projectsWithMeta: { data: Project[]; meta: MetaData } =
    normalizeApiResponse(apiResponse, 'project', true, true);
  return _projectsWithMeta;
};
