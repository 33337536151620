// Angular
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  CanActivateChild,
  UrlTree,
} from '@angular/router';
// RxJS
import { Observable, of } from 'rxjs';
// NGRX
import { Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState } from '../../../reducers';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import {
  isCurrentStepComplete,
  isOnboardingComplete,
  isUserJourneyFetched,
  selectUserJourney,
  isUserJourneyLoaded,
  selectOnboardingConfig,
  isUserJourneyLoading,
} from '../selectors/onboarding.selectors';
import { UserJourney } from '../models/user-journey.model';
import { Onboarding } from '../models/onboarding.model';
import { currentAuthUserRoleId } from '../../gateway/selectors/auth.selectors';
import { concatLatestFrom } from '@ngrx/effects';

@Injectable()
export class OnboardingGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.store.pipe(
      concatLatestFrom((state) => [
        this.store.select(isOnboardingComplete),
        this.store.select(isUserJourneyFetched),
        this.store.select(isUserJourneyLoaded),
        this.store.select(isCurrentStepComplete),
        this.store.select(selectUserJourney),
        this.store.select(selectOnboardingConfig),
        this.store.select(currentAuthUserRoleId),
      ]),
      filter(
        ([
          state,
          isOnboardingComplete,
          isFetched,
          isLoaded,
          isCurrentStepDone,
          userJourney,
          onboardConfig,
          roleId,
        ]) => (isFetched && isLoaded) || roleId === 1
      ),
      map(
        ([
          state,
          isOnboardingComplete,
          isFetched,
          isLoaded,
          isCurrentStepDone,
          userJourney,
          onboardConfig,
          roleId,
        ]) => {
          console.log('Onboard Guard');
          if (roleId === 1) {
            console.log('is ADMIN, guard pass');
            return true;
          }
          const currentUrl = routerState.url;
          if (isOnboardingComplete) {
            if (this.urlNotAllowed(currentUrl, onboardConfig)) {
              console.log(
                "Guard Onboarding FAIL - Can't go back to Onboarding"
              );
              return false;
            }
            console.log('Guard Onboarding PASS');
            return true;
          } else {
            if (
              (!isCurrentStepDone && currentUrl === userJourney.stepUrl) ||
              (isCurrentStepDone && currentUrl === userJourney.nextStepUrl)
            ) {
              console.log('Guard Onboarding PASS - Step is correct');
              return true;
            } else {
              console.log('Guard Onboarding FAIL');
              let url: UrlTree;
              if (isCurrentStepDone) {
                url = this.router.parseUrl(userJourney.nextStepUrl);
              } else {
                url = this.router.parseUrl(userJourney.stepUrl);
              }
              return url;
            }
          }
        }
      )
    );
  }

  urlNotAllowed(url: string, onboardingConfig: Onboarding): boolean {
    const foundItemIndex = onboardingConfig.steps.findIndex(
      (step) => step.stepUrl === url
    );
    console.log(foundItemIndex, 'urlNotAllowed check');
    return foundItemIndex >= 0 ? true : false;
  }
}
