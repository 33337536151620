import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './modules/auth/services/auth.guard';
import { GatewayGuard } from './core/23blocks/gateway';
import { OnboardingGuard } from './core/23blocks/onboarding/guards/onboarding.guard';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },

  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },

  {
    path: 'onboarding',
    loadChildren: () =>
      import('./modules/onboardings/onboardings.module').then(
        (m) => m.OnboardingsModule
      ),
    canActivate: [GatewayGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./layout-23blocks/layout-23blocks.module').then(
        (m) => m.Layout23BlocksModule
      ),
  },

  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
