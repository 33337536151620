import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  AddressData,
  BookingData,
  CheckInOrOutData,
  GeoLocation23blocksOptions,
  GEOLOCATION_23BLOCKS_SERVICE_OPTIONS,
  LocationData,
} from '../models/geolocation.interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthApiResponse,
  AuthToken,
  Gateway23blocksOptions,
} from '../../gateway/models/gateway.interfaces';
import { environment } from '../../../../../environments/environment';
import { ApiResponse } from '../../models/api-response.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { Location } from '../models/location.model';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  public authData: BehaviorSubject<AuthToken> = new BehaviorSubject<AuthToken>(
    null
  );

  get tokenOptions(): GeoLocation23blocksOptions {
    return this.options;
  }

  set tokenOptions(options: GeoLocation23blocksOptions) {
    this.options = (Object as any).assign(this.options, options);
  }

  private options: GeoLocation23blocksOptions;

  constructor(
    private http: HttpClient,
    @Inject(GEOLOCATION_23BLOCKS_SERVICE_OPTIONS) config: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() private activatedRoute: ActivatedRoute,
    @Optional() private router: Router,
    private store: Store<AppState>
  ) {
    const defaultOptions: GeoLocation23blocksOptions = {
      apiPath: null,
      apiBase: null,
      APPID: null,
    };

    const mergedOptions = (Object as any).assign(defaultOptions, config);
    this.options = mergedOptions;

    if (this.options.apiBase === null) {
      console.warn(
        `[GeoLocation 23Blocks] You have not configured 'apiBase', which may result in security issues. ` +
          `Please refer to the documentation at https://github.com/neroniaky/angular-token/wiki`
      );
    }
  }

  private checkAuthData(authData: AuthToken): boolean {
    if (
      authData.companyToken != null &&
      authData.accessToken != null &&
      authData.client != null &&
      authData.expiry != null &&
      authData.tokenType != null &&
      authData.uid != null &&
      authData.appid != null
    ) {
      if (this.authData.value != null) {
        return authData.expiry >= this.authData.value.expiry;
      }
      return true;
    }
    return false;
  }

  public getAuthDataFromStorage(): void {
    const authData: AuthToken = {
      companyToken: localStorage.getItem('companyToken'),
      accessToken: localStorage.getItem('accessToken'),
      client: localStorage.getItem('client'),
      expiry: localStorage.getItem('expiry'),
      tokenType: localStorage.getItem('tokenType'),
      uid: localStorage.getItem('uid'),
      appid: localStorage.getItem('appid'),
    };

    if (this.checkAuthData(authData)) {
      this.authData.next(authData);
    }
  }

  public getCountries(
    query: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ['search']: query,
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23GATEWAY_URL + '/countries/',
      httpOptions
    );
  }

  public getStates(
    query: string,
    country?: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ['search']: query,
          ...(country && { ['country']: country.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23GATEWAY_URL + '/states/',
      httpOptions
    );
  }

  public getCounties(
    query: string,
    country?: string,
    state?: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ['search']: query,
          ...(country && { ['country']: country.toString() }),
          ...(state && { ['state']: state.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23GATEWAY_URL + '/counties/',
      httpOptions
    );
  }

  public getCities(
    query: string,
    country?: string,
    state?: string,
    county?: string,
    page?: number,
    perPage?: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ['search']: query,
          ...(country && { ['country']: country.toString() }),
          ...(state && { ['state']: state.toString() }),
          ...(county && { ['county']: county.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(perPage && { ['records']: perPage.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23GATEWAY_URL + '/cities/',
      httpOptions
    );
  }

  createAddress(dataForm: AddressData): Observable<ApiResponse> {
    return this.http.post(environment.API_23GEOLOCATION_URL + '/addresses', {
      address: dataForm,
    });
  }

  updateAddress(
    uniqueId: string,
    dataForm: AddressData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23GEOLOCATION_URL + '/addresses/' + uniqueId,
      { address: dataForm }
    );
  }

  deleteAddress(uniqueId: string): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23GEOLOCATION_URL + '/addresses/' + uniqueId
    );
  }

  createLocation(dataForm: LocationData): Observable<ApiResponse> {
    return this.http.post(environment.API_23GEOLOCATION_URL + '/locations', {
      location: dataForm,
    });
  }

  updateLocation(
    uniqueId: string,
    location: LocationData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23GEOLOCATION_URL + '/locations/' + uniqueId,
      { location }
    );
  }

  deleteLocation(uniqueId: string): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23GEOLOCATION_URL + '/locations/' + uniqueId
    );
  }
  getLocation(uniqueId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23GEOLOCATION_URL + '/locations/' + uniqueId
    );
  }

  getNormalizedLocation(uniqueId: string): Observable<Location> {
    return this.getLocation(uniqueId).pipe(
      map((apiResponse) => {
        if (!apiResponse?.data) return null;
        const data: any = normalize(apiResponse);
        const _location: Location =
          build(data, 'location', apiResponse.data.id, {
            eager: true,
          }) || null;
        return _location;
      })
    );
  }

  getLocations(): Observable<ApiResponse> {
    return this.http.get(environment.API_23GEOLOCATION_URL + '/locations/');
  }

  getNormalizedLocations(): Observable<Location[]> {
    return this.getLocations().pipe(
      map((apiResponse) => {
        if (!apiResponse?.data?.length) return [];
        const data: any = normalize(apiResponse);
        const _locations: Location[] =
          build(data, 'location', apiResponse.data.id, {
            eager: true,
          }) || [];
        return _locations;
      })
    );
  }

  getAddress(uniqueId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23GEOLOCATION_URL + '/addresses/' + uniqueId
    );
  }

  createBooking(
    locationId: string,
    premiseId: string,
    booking: BookingData
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23GEOLOCATION_URL}/locations/${locationId}/premises/${premiseId}/bookings`,
      { booking }
    );
  }

  createPremiseEvent(
    locationId: string,
    premiseId: string,
    event: CheckInOrOutData
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23GEOLOCATION_URL}/locations/${locationId}/premises/${premiseId}/events`,
      { event }
    );
  }

  getPremiseBookings(
    locationId: string,
    premiseId: string
  ): Observable<ApiResponse> {
    return this.http.get(
      `${environment.API_23GEOLOCATION_URL}/locations/${locationId}/premises/${premiseId}/bookings`
    );
  }

  getUserBookings(userId: string): Observable<ApiResponse> {
    return this.http.get(
      `${environment.API_23GEOLOCATION_URL}/users/${userId}/bookings`
    );
  }

  cancelPremiseBooking(
    locationId: string,
    premiseId: string,
    bookingCode: string
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23GEOLOCATION_URL}/locations/${locationId}/premises/${premiseId}/bookings/${bookingCode}/cancel`,
      {}
    );
  }

  // Admin only

  updatePremiseBooking(
    locationId: string,
    premiseId: string,
    bookingCode: string,
    booking: BookingData
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23GEOLOCATION_URL}/locations/${locationId}/premises/${premiseId}/bookings/${bookingCode}`,
      { booking }
    );
  }

  deletePremiseBooking(
    locationId: string,
    premiseId: string,
    bookingCode: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23GEOLOCATION_URL}/locations/${locationId}/premises/${premiseId}/bookings/${bookingCode}`
    );
  }

  getPremiseBookingsForSelectedDate(
    locationId: string,
    premiseId: string,
    userId: string,
    date: string
  ): Observable<ApiResponse> {
    return this.http.get(
      `${environment.API_23GEOLOCATION_URL}/locations/${locationId}/premises/${premiseId}/events?user_unique_id=${userId}&event_date=${date}`
    );
  }
}
