import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  MOONCHECK_23BLOCKS_SERVICE_OPTIONS,
  Mooncheck23blocksOptions,
} from './models/mooncheck.interfaces';
import { MooncheckService } from './services/mooncheck.service';
import { mooncheckReducer } from './reducers/mooncheck.reducers';
import { MooncheckEffects } from './effects/mooncheck.effects';
import { Mooncheck23blocksInterceptor } from './interceptors/mooncheck.interceptors';
import { MooncheckGuard } from './guards/mooncheck.guard';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('mooncheck', mooncheckReducer),
    EffectsModule.forFeature([MooncheckEffects]),
  ],
})
export class Mooncheck23blocksModule {
  constructor(@Optional() @SkipSelf() parentModule: Mooncheck23blocksModule) {
    if (parentModule) {
      throw new Error(
        "Mooncheck23blocksModule is already loaded. It should only be imported in your application's main module."
      );
    }
  }
  static forRoot(
    options: Mooncheck23blocksOptions
  ): ModuleWithProviders<Mooncheck23blocksModule> {
    return {
      ngModule: Mooncheck23blocksModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: Mooncheck23blocksInterceptor,
          multi: true,
        },
        options.MooncheckOptionsProvider || {
          provide: MOONCHECK_23BLOCKS_SERVICE_OPTIONS,
          useValue: options,
        },
        MooncheckService,
        MooncheckGuard,
      ],
    };
  }
}
