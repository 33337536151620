import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InTabNavigationService implements OnDestroy {
  private _currentTransparencyTab$ = new BehaviorSubject('');
  currentTransparencyTab$ = this._currentTransparencyTab$.asObservable();

  private _currentTransparencySubTab$ = new BehaviorSubject('');
  currentTransparencySubTab$ = this._currentTransparencySubTab$.asObservable();

  _currentBreakdown$ = new BehaviorSubject('');
  currentBreakdown$ = this._currentBreakdown$.asObservable();

  unsubscribe = new Subject();
  constructor() {
    const existingCurrentTab = localStorage.getItem('currentTab');
    const existingCurrentSubTab = localStorage.getItem('currentSubTab');
    const existingCurrentBreakdown = localStorage.getItem('currentBreakdown');
    this._currentTransparencyTab$.next(existingCurrentTab || 'overview');
    this._currentTransparencySubTab$.next(existingCurrentSubTab || '1');
    // this._currentBreakdown$.next(existingCurrentBreakdown || '');

    this.currentTransparencyTab$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((currentTab) => {
        localStorage.setItem('currentTab', currentTab);
      });

    this.currentTransparencySubTab$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((currentSubTab) => {
        localStorage.setItem('currentSubTab', currentSubTab);
      });

    this.currentBreakdown$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((currentBreakdown) => {
        localStorage.setItem('currentBreakdown', currentBreakdown);
      });
  }

  changeTab(newTab: string) {
    this._currentTransparencyTab$.next(newTab);
  }

  changeSubTab(newTab: string, newSubTab: string) {
    this._currentTransparencyTab$.next(newTab);
    this._currentTransparencySubTab$.next(newSubTab);
  }

  changeSubTabOnly(newSubTab: string) {
    this._currentTransparencySubTab$.next(newSubTab);
  }

  changeBreakdown(newBreakdown: string) {
    this._currentBreakdown$.next(newBreakdown);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }
}
