import { InjectionToken, Provider } from '@angular/core';
import { PAYMENT_TYPES } from '../constants';

export interface Sales23blocksOptions {
  SalesOptionsProvider?: Provider;
  apiBase?: string;
  apiPath?: string;
  APPID: string;
}

export const SALES_23BLOCKS_SERVICE_OPTIONS = new InjectionToken(
  'SALES_23BLOCKS_SERVICE_OPTIONS'
);

export interface UserIdentityData {
  user_unique_id: string;
  name: string;
  email: string;
  phone: string;
  role_name: string;
  role_unique_id: string;
  avatar_url?: string;
  email_notifications?: boolean;
  sms_notifications?: boolean;
  whatsapp_notifications?: boolean;
  other_notifications?: boolean;
}

export interface UserData {
  name: string;
  email: string;
  phone: string;
  status?: string;
  user_unique_id: string;
  role_name: string;
  role_unique_id: string;
}

export interface NewSubscriptionData {
  subscription_number: string;
  status:
    | 'new'
    | 'active'
    | 'suspended'
    | 'incomplete'
    | 'incomplete_expired'
    | 'trialing'
    | 'past_due'
    | 'canceled'
    | 'unpaid'
    | 'cancelling'
    | 'payment_failed'
    | 'canceled';
  notes?: string;
}

export interface UpdateSubscriptionData {
  subscription_number?: string;
  status:
    | 'new'
    | 'active'
    | 'suspended'
    | 'incomplete'
    | 'incomplete_expired'
    | 'trialing'
    | 'past_due'
    | 'canceled'
    | 'unpaid'
    | 'cancelling'
    | 'payment_failed'
    | 'canceled';
  notes?: string;
}

export interface MailTemplateData {
  template_html: string;
  template_text: string;
  from_address: string;
  from_name: string;
  from_subject: string;
}

export interface CustomerData {
  name: string;
  email: string;
  phone: string;
  customer_type: string;
  company_unique_id?: string;
  user_unique_id?: string;
}

export interface StripeSubscriptionData {
  customer_id: string;
  subscription_price_id: string;
  card_token: any;
  trial_period_days?: number;
  setup_price_id?: string;
}

export interface StripePaymentAttemptData {
  customer_id: string;
  amount: number;
  card_token: any;
}

export interface CustomerIdentityData {
  email: string;
  company_unique_id: string;
  name?: string;
  phone?: string;
  payload?: string;
  stripe_id?: string;
  status?: string;
  time_zone?: string;
  preferred_language?: string;
  avatar_url?: string;
  email_notifications?: string;
  sms_notifications?: string;
  whatsapp_notifications?: string;
  other_notifications?: string;
  source?: string;
  source_alias?: string;
  source_id?: string;
  source_type?: string;
  updated_at?: string;
}

export interface CustomerSuscriptionData {
  subscription_number: string;
  code?: string;
  program_code?: string;
  description?: string;
  promotional?: string;
  trial_period?: string;
  duration?: string;
  duration_unit?: string;
  duration_description?: string;
  recurring_payment_fees?: string;
  recurring_payment_amount?: string;
  start_at?: string;
  end_at?: string;
  subscribed_at?: string;
  closed_at?: string;
  last_payment_at?: string;
  next_payment_at?: string;
  last_payment?: string;
  payments_made?: string;
  status?: string;
  bankruptcy?: string;
  notes?: string;
}

export interface NewTenantData {
  url_id?: string;
  code: string;
  name: string;
  preferred_domain: string;
  preferred_language: string;
  payload: string;
  secret?: string;
}

export interface NewOrderData {
  customer_unique_id: string;
  customer_email: string;
  customer_name?: string;
  tax: number;
  tax_value: number;
  total: number;
  fees: number;
  parent_unique_id: string;
  source_id?: string;
  source?: string;
  source_alias?: string;
  source_type?: string;
  payload?: any;

  cart_unique_id: string;

  referred_by?: string;
  promo_code?: string;

  discount_value: number;
  discount: number; //percentage
}

export interface OrderDetailsData {
  product_sku: string;
  product_unique_id: string;
  product_name: string;
  product_description: string;
  category_name: string;
  is_variation: boolean;
  quantity: number;
  notes: string;
  subtotal: number;
  discount: number;
  tax: number;
  tax_value: number;
  fees: number;
  fees_value: number;
  total: number;
  balance: number;
  payload: any;
  price_with_fees: number;
  price_with_taxes?: number;
  subtotal_tax?: number;

  source?: string;
  source_type?: string;
  source_id?: string;
  source_alias?: string;

  cart_unique_id?: string;
  cart_detail_unique_id?: string;

  referred_by?: string;
  promo_code?: string;
}

export interface OrderDetailProviderData {
  vendor_unique_id: string;
  vendor_name: string;
  vendor_email: string;
  vendor_phone: string;
  vendor_contact?: string;
  status?: string;
  payload?: string;

  referred_by?: string;
  promo_code?: string;
}

export interface ReportData {
  start_at: string;
  end_at: string;
  display: string;
  customer_unique_id: string;
  parent_unique_id: string;
  logistics: string;
  group_by: string;
  source: string;
  referred_by: string;
  promo_code: string;
  // vendor_unique_id: string;
}

export interface PaymentReportData {
  order_unique_id: string;
  vendor_unique_id: string;
  vendor_name: string;
  vendor_email: string;
  payment_type: string;
  gateway_unique_id: string;
  start_at: string;
  end_at: string;
  paid_start: string;
  paid_end: string;
  group_by: string;
}

export interface ProviderReportData {
  order_details_unique_id: string;
  order_unique_id: string;
  product_unique_id: string;
  product_sku: string;
  vendor_unique_id: string;
  vendor_name: string;
  source_alias: string;
  source_id: string;
  display_unique_id: string;
  customer_unique_id: string;
  parent_unique_id: string;
  logistic_status: string;
  start_at: string;
  end_at: string;
  group_by: string;
}

export interface PaymentData {
  gateway_unique_id: string;
  gateway_name: string;
  gateway_reference: string;
  gateway_order_id: string;
  gateway_franchise: string;
  gateway_payment_type: string;
  gateway_transaction_id: string;
  gateway_description: string;
  gateway_subtotal: string;
  gateway_discount: string;
  gateway_delivery: string;
  gateway_tax: string;
  gateway_tax_value: string;
  gateway_fees: string;
  gateway_fees_value: string;
  gateway_tips: string;
  gateway_tips_value: string;
  gateway_total: string;
  gateway_balance: string;
  gateway_paid_at: string;
  gateway_status: string;
  gateway_response: string;
  payload: string;
  payment_type: PaymentType;
}

type PaymentTypeKey = keyof typeof PAYMENT_TYPES;
export type PaymentType = (typeof PAYMENT_TYPES)[PaymentTypeKey]['value'];

export interface VendorToPayData {
  // # Vendor Information
  vendor_unique_id: string;
  vendor_name: string;
  vendor_email: string;
  vendor_phone: string;
  vendor_contact: string;
  // # Payment to do.
  discount: string;
  discount_value: string;
  fees: string;
  fees_value: string;
  tips: string;
  tips_value: string;
  subtotal: string;
  delivery: string;
  tax: string;
  tax_value: string;
  price: string | number;
  total: string | number;
  // # Metadata
  status: string;
  payload: string;
}

export interface VendorPaymentData {
  //   # Payment completed information
  gateway_unique_id: string;
  gateway_name: string;
  gateway_reference: string;
  gateway_order_id: string;
  gateway_franchise: string;
  gateway_payment_type: string;
  gateway_transaction_id: string;
  gateway_description: string;
  gateway_fees: string;
  gateway_fees_value: string;
  gateway_tips: string;
  gateway_tips_value: string;
  gateway_subtotal: string;
  gateway_discount: string;
  gateway_discount_value: string;
  gateway_delivery: string;
  gateway_tax: string;
  gateway_tax_value: string;
  gateway_total: string;
  gateway_balance: string;
  gateway_paid_at: string;
  gateway_status: string;
  gateway_response: string;
  paid_at: string;
  payment_type: string;
  gateway_payload: string;
}
