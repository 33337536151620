import { Action } from '@ngrx/store';
import { CartDetails } from '../models/cart-details.model';
import { Cart } from '../models/cart.model';
import {
  OrderCartData,
  UpdateCartData,
  UpdateCartDataSingle,
} from '../models/products.interfaces';
import { UserIdentity } from '../models/user-identity.model';

export enum ProductsActionTypes {
  IdentityRequested = '[Request User Products Identity] API',
  IdentityLoaded = '[Load User Products Identity] API',
  CreateIdentity = '[Create User Products Identity] API',
  CartRequested = '[Request Cart - Products] API',
  CartLoaded = '[Load Cart - Products] API',
  CartError = '[Get Cart Error - Products] API',
  CreateCart = '[Create Cart - Products] API',
  UpdateCart = '[Update Cart - Products] API',
  UpdateCartSingle = '[Update Cart Single (no update) - Products] API',
  RemoveCartDetail = '[Remove Cart Detail - Products] API',
  OrderCart = '[Order Cart - Products] API',
  DestroyAndCreateCart = '[Destroy and Create Cart - Products] API',
}

export class IdentityRequested implements Action {
  readonly type = ProductsActionTypes.IdentityRequested;
}
export class IdentityLoaded implements Action {
  readonly type = ProductsActionTypes.IdentityLoaded;
  constructor(public payload: { userIdentity: UserIdentity }) {}
}

export class CreateIdentity implements Action {
  readonly type = ProductsActionTypes.CreateIdentity;
}

export class CartRequested implements Action {
  readonly type = ProductsActionTypes.CartRequested;
}

export class CartLoaded implements Action {
  readonly type = ProductsActionTypes.CartLoaded;
  constructor(public payload: { cart: Cart }) {}
}

export class CartError implements Action {
  readonly type = ProductsActionTypes.CartError;
}

export class CreateCart implements Action {
  readonly type = ProductsActionTypes.CreateCart;
}

export class UpdateCart implements Action {
  readonly type = ProductsActionTypes.UpdateCart;
  constructor(public payload: UpdateCartData) {}
}

export class UpdateCartSingle implements Action {
  readonly type = ProductsActionTypes.UpdateCartSingle;
  constructor(public payload: UpdateCartDataSingle) {}
}

export class RemoveCartDetail implements Action {
  readonly type = ProductsActionTypes.RemoveCartDetail;
  constructor(public payload: CartDetails) {}
}

export class OrderCart implements Action {
  readonly type = ProductsActionTypes.OrderCart;
  constructor(public payload: OrderCartData) {}
}

export class DestroyAndCreateCart implements Action {
  readonly type = ProductsActionTypes.DestroyAndCreateCart;
}

export type ProductsActions =
  | IdentityRequested
  | IdentityLoaded
  | CreateIdentity
  | CartRequested
  | CartLoaded
  | CartError
  | CreateCart
  | UpdateCart
  | OrderCart
  | DestroyAndCreateCart;
