import { Injectable, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { selectAccessToken } from '../../gateway';
import { AuthToken } from '../../gateway/models/gateway.interfaces';
import { isPlatformServer } from '@angular/common';

import { map } from 'rxjs/operators';
import { normalizeApiResponse } from 'src/app/common/utils/utils';
import {
  ApiResponse,
  CONTENT_23BLOCKS_SERVICE_OPTIONS,
  MetaData,
  Content23blocksOptions,
  SearchParams,
  UserIdentityData,
  ContentPostData,
  CommentData,
} from '../models/content.interfaces';
import { ContentPost } from '../models/content.models';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  private localStorage: Storage | any = {};
  private global: Window | any;

  public authData: BehaviorSubject<AuthToken> = new BehaviorSubject<AuthToken>(
    null
  );

  get tokenOptions(): Content23blocksOptions {
    return this.options;
  }
  set tokenOptions(options: Content23blocksOptions) {
    this.options = (Object as any).assign(this.options, options);
  }
  private options: Content23blocksOptions;
  constructor(
    private http: HttpClient,
    @Inject(CONTENT_23BLOCKS_SERVICE_OPTIONS) config: any,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    const defaultOptions: Content23blocksOptions = {
      apiPath: null,
      apiBase: null,
      APPID: null,
    };
    const mergedOptions = (Object as any).assign(defaultOptions, config);
    this.options = mergedOptions;
    if (this.options.apiBase === null) {
      console.warn(
        `[Content 23Blocks] You have not configured 'apiBase', which may result in security issues. ` +
          `Please refer to the documentation at https://github.com/neroniaky/angular-token/wiki`
      );
    }
  }
  private checkAuthData(authData: AuthToken): boolean {
    if (
      authData.companyToken != null &&
      authData.accessToken != null &&
      authData.client != null &&
      authData.expiry != null &&
      authData.tokenType != null &&
      authData.uid != null &&
      authData.appid != null
    ) {
      if (this.authData.value != null) {
        return authData.expiry >= this.authData.value.expiry;
      }
      return true;
    }
    return false;
  }

  public getAuthDataFromStorage(): void {
    const authData: AuthToken = {
      companyToken: localStorage.getItem('companyToken'),
      accessToken: localStorage.getItem('accessToken'),
      client: localStorage.getItem('client'),
      expiry: localStorage.getItem('expiry'),
      tokenType: localStorage.getItem('tokenType'),
      uid: localStorage.getItem('uid'),
      appid: localStorage.getItem('appid'),
    };

    if (this.checkAuthData(authData)) {
      this.authData.next(authData);
    }
  }

  createUserIdentity(
    userUniqueId: string,
    userIdentityData: UserIdentityData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23CONTENT_URL +
        '/identities/' +
        userUniqueId +
        '/register/',
      {
        ...userIdentityData,
      }
    );
  }

  getUserIdentity(userUniqueId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23CONTENT_URL + '/identities/' + userUniqueId
    );
  }

  updateUserIdentity(
    userUniqueId: string,
    userIdentityData: UserIdentityData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23CONTENT_URL + '/identities/' + userUniqueId,
      { user: userIdentityData }
    );
  }

  /* Posts */

  getPosts(): Observable<ApiResponse> {
    return this.http.get(environment.API_23CONTENT_URL + '/posts/');
  }

  getPost(postId: string): Observable<ContentPost> {
    return this.http
      .get(`${environment.API_23CONTENT_URL}/posts/${postId}`)
      .pipe(
        map((res) => {
          const _post = normalizeApiResponse(res, 'post', false) as ContentPost;
          return _post;
        })
      );
  }

  createPost(post: Partial<ContentPostData>): Observable<ApiResponse> {
    return this.http.post(`${environment.API_23CONTENT_URL}/posts`, {
      post,
    });
  }

  updatePost(
    postId: string,
    post: Partial<ContentPostData>
  ): Observable<ApiResponse> {
    return this.http.put(`${environment.API_23CONTENT_URL}/posts/${postId}`, {
      post,
    });
  }

  deletePost(postId: string): Observable<ApiResponse> {
    return this.http.delete(`${environment.API_23CONTENT_URL}/posts/${postId}`);
  }

  likePost(postId: string): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23CONTENT_URL}/posts/${postId}/like`,
      {}
    );
  }

  /* end Posts */

  /* Post Versions */

  publishPostVersion(
    postId: string,
    postVersionId: string
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23CONTENT_URL}/posts/${postId}/versions/${postVersionId}/publish`,
      {}
    );
  }

  /* end Post Versions */

  /* Comments */

  getParentComments(postId: string): Observable<ApiResponse> {
    return this.http.get(
      `${environment.API_23CONTENT_URL}/posts/${postId}/comments`
    );
  }

  getComment(postId: string, commentId: string): Observable<ApiResponse> {
    return this.http.get(
      `${environment.API_23CONTENT_URL}/posts/${postId}/comments/${commentId}`
    );
  }

  createComment(
    postId: string,
    comment: Partial<CommentData>
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23CONTENT_URL}/posts/${postId}/comments`,
      { comment }
    );
  }

  replyToComment(
    postId: string,
    parentCommentId: string,
    comment: Partial<CommentData>
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23CONTENT_URL}/posts/${postId}/comments/${parentCommentId}/reply`,
      { comment }
    );
  }

  updateComment(
    postId: string,
    commentId: string,
    comment: Partial<CommentData>
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23CONTENT_URL}/posts/${postId}/comments/${commentId}`,
      { comment }
    );
  }

  deleteComment(postId: string, commentId: string): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23CONTENT_URL}/posts/${postId}/comments/${commentId}`
    );
  }

  likeComment(postId: string, commentId: string): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23CONTENT_URL}/posts/${postId}/comments/${commentId}/like`,
      {}
    );
  }

  /* end Comments */
}
