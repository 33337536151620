import { Action } from '@ngrx/store';
import { UserIdentity } from '../models/user-identity.model';

export enum FilesActionTypes {
  IdentityRequested = '[Request Files Identity] API',
  IdentityLoaded = '[Load User Files Identity] API',
  CreateIdentity = '[Check and Create Files Identity] API',
}

export class IdentityRequested implements Action {
  readonly type = FilesActionTypes.IdentityRequested;
}
export class IdentityLoaded implements Action {
  readonly type = FilesActionTypes.IdentityLoaded;
  constructor(
    public payload: { userIdentity: UserIdentity; firstTime: boolean }
  ) {}
}

export class CreateIdentity implements Action {
  readonly type = FilesActionTypes.CreateIdentity;
}

export type FilesActions = IdentityRequested | IdentityLoaded | CreateIdentity;
