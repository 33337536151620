import { SearchActions, SearchActionTypes } from '../actions/search.actions';
import { UserIdentity } from '../models/user-identity.model';

export interface SearchState {}

export const initialSearchState: SearchState = {};

export function searchReducer(
  state = initialSearchState,
  action: SearchActions
): SearchState {
  switch (action.type) {
    case SearchActionTypes.IdentityLoaded: {
      return {
        ...state,
        isIdentityLoaded: true,
        userIdentity: action.payload.userIdentity,
      };
    }

    default:
      return state;
  }
}
