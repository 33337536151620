// Actions
import { Notification } from '..';
import {
  NotificationsActions,
  NotificationsActionTypes,
} from '../actions/notifications.actions';

// Models

export interface NotificationsState {
  notifications: Notification[];
  notification: Notification;
}

export const initialNotificationsState: NotificationsState = {
  notifications: [],
  notification: undefined,
};

export function notificationsReducer(
  state = initialNotificationsState,
  action: NotificationsActions
): NotificationsState {
  switch (action.type) {
    case NotificationsActionTypes.NotificationsLoaded: {
      return {
        ...state,
        notifications: action.payload.notifications,
      };
    }
    case NotificationsActionTypes.NotificationLoaded: {
      return {
        ...state,
        notification: action.payload.notification,
      };
    }

    case NotificationsActionTypes.NotificationReceived: {
      return {
        ...state,
        notifications: [...state.notifications, action.payload.notification],
      };
    }

    default:
      return state;
  }
}
