import { ContentActionTypes, ContentActions } from '../actions/content.actions';
import { UserIdentity } from '../models/user-identity.model';

export interface ContentState {
  userIdentity: UserIdentity;
  isIdentityLoaded: boolean;
}

export const initialContentState: ContentState = {
  userIdentity: undefined,
  isIdentityLoaded: false,
};

export function contentReducer(
  state = initialContentState,
  action: ContentActions
): ContentState {
  switch (action.type) {
    case ContentActionTypes.IdentityLoaded: {
      return {
        ...state,
        isIdentityLoaded: true,
        userIdentity: action.payload.userIdentity,
      };
    }
    default:
      return state;
  }
}
