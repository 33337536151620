// Angular
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  CanActivateChild,
  UrlTree,
} from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState } from 'src/app/core/reducers';
import {
  isIdentityLoaded,
  isPaymentStatusLoaded,
  selectPaymentStatus,
  selectUserIdentity,
} from '../selectors/sales.selectors';
import { UserIdentity } from '../models/user-identity.model';
import { currentAuthUser, isUserLoaded } from '../../gateway';

@Injectable()
export class SalesGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.store.pipe(
      withLatestFrom(
        this.store.select(isPaymentStatusLoaded),
        this.store.select(selectPaymentStatus),
        this.store.select(isIdentityLoaded),
        this.store.select(selectUserIdentity),
        this.store.select(currentAuthUser),
        this.store.select(isUserLoaded)
      ),
      filter(
        ([
          store,
          isPaymentLoaded,
          paymentStatus,
          isIdentityLoaded,
          userIdentity,
          user,
          isUserLoaded,
        ]) =>
          (isPaymentLoaded && isIdentityLoaded) ||
          (isUserLoaded && user.roleId !== 7)
      ),
      map(
        ([
          store,
          isPaymentLoaded,
          paymentStatus,
          isIdentityLoaded,
          userIdentity,
          user,
        ]) => {
          console.log('Guard Sales: Evaluating...');
          if (user.roleId !== 7) {
            console.log('Guard Sales PASS: User is not of type company admin');
            return true;
          }
          // const userType = this.getUserType(userIdentity);
          if (this.isPaymentStatusOK(paymentStatus)) {
            console.log('Guard Sales PASS: Payment is ' + paymentStatus);
            return true;
          } else {
            console.log('Guard Sales FAIL: Payment is ' + paymentStatus);
            const url = this.router.parseUrl('payments');
            return url;
          }
        }
      )
    );
  }

  isPaymentStatusOK(paymentStatus: string): boolean {
    return paymentStatus === 'active' ||
      paymentStatus === 'trialing' ||
      paymentStatus === 'cancelling'
      ? true
      : false;
  }

  // getUserType(userIdentity: UserIdentity): string {
  //   return userIdentity.roleName === 'teacher' ||
  //     userIdentity.roleName === 'Teacher'
  //     ? 'teacher'
  //     : 'student';
  // }
}
