import { Injectable, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { selectAccessToken } from '../../gateway';
import { AuthToken } from '../../gateway/models/gateway.interfaces';
import { isPlatformServer } from '@angular/common';

import { ApiResponse } from '../../models/api-response.model';
import {
  Search23blocksOptions,
  SearchData,
  SEARCH_23BLOCKS_SERVICE_OPTIONS,
  UserIdentityData,
} from '../models/search.interfaces';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public authData: BehaviorSubject<AuthToken> = new BehaviorSubject<AuthToken>(
    null
  );

  get tokenOptions(): Search23blocksOptions {
    return this.options;
  }
  set tokenOptions(options: Search23blocksOptions) {
    this.options = (Object as any).assign(this.options, options);
  }
  private options: Search23blocksOptions;
  constructor(
    private http: HttpClient,
    @Inject(SEARCH_23BLOCKS_SERVICE_OPTIONS) config: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() private activatedRoute: ActivatedRoute,
    @Optional() private router: Router,
    private store: Store<AppState>
  ) {
    const defaultOptions: Search23blocksOptions = {
      apiPath: null,
      apiBase: null,
      APPID: null,
    };
    const mergedOptions = (Object as any).assign(defaultOptions, config);
    this.options = mergedOptions;
    if (this.options.apiBase === null) {
      console.warn(
        `[Search 23Blocks] You have not configured 'apiBase', which may result in security issues. ` +
          `Please refer to the documentation at https://github.com/neroniaky/angular-token/wiki`
      );
    }
  }
  private checkAuthData(authData: AuthToken): boolean {
    if (
      authData.companyToken != null &&
      authData.accessToken != null &&
      authData.client != null &&
      authData.expiry != null &&
      authData.tokenType != null &&
      authData.uid != null &&
      authData.appid != null
    ) {
      if (this.authData.value != null) {
        return authData.expiry >= this.authData.value.expiry;
      }
      return true;
    }
    return false;
  }

  public getAuthDataFromStorage(): void {
    const authData: AuthToken = {
      companyToken: localStorage.getItem('companyToken'),
      accessToken: localStorage.getItem('accessToken'),
      client: localStorage.getItem('client'),
      expiry: localStorage.getItem('expiry'),
      tokenType: localStorage.getItem('tokenType'),
      uid: localStorage.getItem('uid'),
      appid: localStorage.getItem('appid'),
    };

    if (this.checkAuthData(authData)) {
      this.authData.next(authData);
    }
  }

  createUserIdentity(
    userUniqueId: string,
    dataForm: UserIdentityData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23SEARCH_URL + '/users/' + userUniqueId + '/register/',
      {
        user: dataForm,
      }
    );
  }

  getUserIdentity(userUniqueId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23SEARCH_URL + '/users/' + userUniqueId
    );
  }

  updateUserIdentity(
    userUniqueId: string,
    userIdentityData: UserIdentityData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23SEARCH_URL + '/users/' + userUniqueId,
      { user: userIdentityData }
    );
  }

  search(searchData: SearchData): Observable<any> {
    const { params, exclude, page, records } = searchData;
    return this.http.post(environment.API_23SEARCH_URL + '/cloud', {
      search: {
        params,
        ...(exclude && { ['exclude']: exclude }),
        ...(records && { ['records']: records.toString() }),
        ...(page && { ['page']: page.toString() }),
      },
    });
  }
}
