import { Inject, Injectable } from '@angular/core';
import { AppState } from 'src/app/core/reducers';
import { Store, select } from '@ngrx/store';
import { selectAccessToken } from '../../gateway';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResponse } from '../../models/api-response.model';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { AuthToken } from '../../gateway/models/gateway.interfaces';

export interface AWSUrl {
  presigned_url: string;
  public_url: string;
  signed_url: string;
  file_name: string;
}

import {
  AccountData,
  AccountDetailsData,
  AddContactData,
  AccountLocationData,
  CategoryData,
  ContactData,
  CRM23blocksOptions,
  CRM_23BLOCKS_SERVICE_OPTIONS,
  AccountDocumentData,
  FollowUpsData,
  LeadData,
  NewTenantData,
  OpportunityData,
  PresignUploadData,
  QuoteData,
  AddQuoteData,
} from '../models/crm.interfaces';

@Injectable({
  providedIn: 'root',
})
export class CRMService {
  public authData: BehaviorSubject<AuthToken> = new BehaviorSubject<AuthToken>(
    null
  );

  get tokenOptions(): CRM23blocksOptions {
    return this.options;
  }

  set tokenOptions(options: CRM23blocksOptions) {
    this.options = (Object as any).assign(this.options, options);
  }

  private options: CRM23blocksOptions;
  private appId: string;
  private companyToken: string;

  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    @Inject(CRM_23BLOCKS_SERVICE_OPTIONS) config: any
  ) {
    const defaultOptions: CRM23blocksOptions = {
      apiPath: null,
      apiBase: null,
      APPID: null,
    };

    const mergedOptions = (Object as any).assign(defaultOptions, config);
    this.options = mergedOptions;

    if (this.options.apiBase === null) {
      console.warn(
        `[CRM 23Blocks] You have not configured 'apiBase', which may result in security issues. ` +
          `Please refer to the documentation at https://github.com/neroniaky/angular-token/wiki`
      );
    }
  }

  private checkAuthData(authData: AuthToken): boolean {
    if (
      authData.companyToken != null &&
      authData.accessToken != null &&
      authData.client != null &&
      authData.expiry != null &&
      authData.tokenType != null &&
      authData.uid != null &&
      authData.appid != null
    ) {
      if (this.authData.value != null) {
        return authData.expiry >= this.authData.value.expiry;
      }
      return true;
    }
    return false;
  }

  public getAuthDataFromStorage(): void {
    const authData: AuthToken = {
      companyToken: localStorage.getItem('companyToken'),
      accessToken: localStorage.getItem('accessToken'),
      client: localStorage.getItem('client'),
      expiry: localStorage.getItem('expiry'),
      tokenType: localStorage.getItem('tokenType'),
      uid: localStorage.getItem('uid'),
      appid: localStorage.getItem('appid'),
    };

    if (this.checkAuthData(authData)) {
      this.authData.next(authData);
    }
  }

  // Accounts

  public createAccount(data: AccountData): Observable<ApiResponse> {
    return this.http.post(environment.API_23CRM_URL + '/accounts/', {
      account: data,
    });
  }

  public editAccount(
    accountId: string,
    data: AccountData
  ): Observable<ApiResponse> {
    return this.http.put(environment.API_23CRM_URL + '/accounts/' + accountId, {
      account: data,
    });
  }

  public getAccounts(
    query: string,
    page: number,
    sizes: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(sizes && { ['records']: sizes.toString() }),
        },
      }),
    };
    return this.http.get(environment.API_23CRM_URL + '/accounts', httpOptions);
  }

  public getAccount(
    uniqueId: string,
    parentTenant?: boolean
  ): Observable<ApiResponse> {
    const options = parentTenant
      ? {
          headers: {
            'company-token': localStorage.getItem('parentCompanyToken'),
            appid: localStorage.getItem('appid'),
          },
        }
      : {};
    return this.http.get(
      environment.API_23CRM_URL + '/accounts/' + uniqueId,
      options
    );
  }

  deleteAccount(uniqueId: string): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23CRM_URL + '/accounts/' + uniqueId
    );
  }

  public editAccountDetails(
    uniqueId: string,
    dataForm: AccountDetailsData,
    parentTenant?: boolean
  ) {
    const options = parentTenant
      ? {
          headers: {
            'company-token': localStorage.getItem('parentCompanyToken'),
            appid: localStorage.getItem('appid'),
          },
        }
      : {};
    return this.http.put(
      environment.API_23CRM_URL + '/accounts/' + uniqueId + '/details',
      { account: dataForm },
      options
    );
  }

  public createAccountDetails(
    uniqueId: string,
    dataForm: AccountDetailsData,
    parentTenant?: boolean
  ): Observable<ApiResponse> {
    const options = parentTenant
      ? {
          headers: {
            'company-token': localStorage.getItem('parentCompanyToken'),
            appid: localStorage.getItem('appid'),
          },
        }
      : {};
    return this.http.post(
      environment.API_23CRM_URL + '/accounts/' + uniqueId + '/details',
      { account: dataForm },
      options
    );
  }

  public getAccountContacts(
    uniqueId: string,
    query: string,
    page: number,
    sizes: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(sizes && { ['records']: sizes.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23CRM_URL + '/accounts/' + uniqueId + '/contacts',
      httpOptions
    );
  }

  public getContact(uniqueId: string): Observable<ApiResponse> {
    return this.http.get(environment.API_23CRM_URL + '/contacts/' + uniqueId);
  }

  public createContact(dataForm: ContactData): Observable<ApiResponse> {
    return this.http.post(environment.API_23CRM_URL + '/contacts', {
      contact: dataForm,
    });
  }

  public editContact(
    uniqueId: string,
    dataForm: ContactData
  ): Observable<ApiResponse> {
    return this.http.put(environment.API_23CRM_URL + '/contacts/' + uniqueId, {
      contact: dataForm,
    });
  }

  public deleteContact(uniqueId: string): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23CRM_URL + '/contacts/' + uniqueId
    );
  }

  public associateContactToAccount(
    uniqueId: string,
    data: AddContactData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23CRM_URL + '/accounts/' + uniqueId + '/contacts',
      { account: data }
    );
  }

  public removeAccountContact(
    accountUniqueId: string,
    contactUniqueId: string
  ) {
    return this.http.delete(
      environment.API_23CRM_URL +
        '/accounts/' +
        accountUniqueId +
        '/contacts/' +
        contactUniqueId
    );
  }

  public getContactListTrash(): Observable<ApiResponse> {
    return this.http.get(environment.API_23CRM_URL + '/contacts/trash/show');
  }

  addAccountLocation(
    uniqueId: string,
    data: AccountLocationData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23CRM_URL + '/accounts/' + uniqueId + '/locations',
      { location: data }
    );
  }

  removeAccountLocation(
    accountUniqueId: string,
    locationUniqueId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23CRM_URL +
        '/accounts/' +
        accountUniqueId +
        '/locations/' +
        locationUniqueId
    );
  }

  getAccountLocations(uniqueId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23CRM_URL + '/accounts/' + uniqueId + '/locations'
    );
  }

  getAccountDocuments(
    uniqueId: string,
    query: string,
    page: number,
    sizes: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(sizes && { ['records']: sizes.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23CRM_URL + '/accounts/' + uniqueId + '/documents',
      httpOptions
    );
  }

  addDocumentsToAccount(
    uniqueId: string,
    document: AccountDocumentData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23CRM_URL + '/accounts/' + uniqueId + '/documents',
      { document }
    );
  }

  // 1st step to amazon
  addPresignUpload(
    uniqueId: string,
    presignUploadData: PresignUploadData
  ): Observable<AWSUrl> {
    return this.http.put<AWSUrl>(
      environment.API_23CRM_URL + '/accounts/' + uniqueId + '/presign_document',
      presignUploadData
    );
  }

  // Upload file to amazon
  uploadFileAWS(aws_url: string, file: File): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': file.type,
      }),
    };
    return this.http.put(aws_url, file, httpOptions);
  }

  getAccountDocument(
    accountUniqueId: string,
    docUniqueId: string
  ): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23CRM_URL +
        '/accounts/' +
        accountUniqueId +
        '/documents/' +
        docUniqueId
    );
  }

  deleteAccountDocument(
    accountId: string,
    docId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23CRM_URL +
        '/accounts/' +
        accountId +
        '/documents/' +
        docId
    );
  }

  addCRMBlock(
    tenantAppId: string,
    companyData: NewTenantData
  ): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        blockAppId: tenantAppId,
      }),
    };
    return this.http.post<ApiResponse>(
      environment.API_23CRM_URL + '/companies',
      { company: companyData },
      httpOptions
    );
  }

  createLead(leadData: LeadData): Observable<ApiResponse> {
    return this.http.post(environment.API_23CRM_URL + '/leads', {
      lead: leadData,
    });
  }

  getLead(leadUniqueId: string): Observable<ApiResponse> {
    return this.http.get(environment.API_23CRM_URL + '/leads/' + leadUniqueId);
  }

  editLead(leadUniqueId: string, leadData: LeadData): Observable<ApiResponse> {
    return this.http.put(environment.API_23CRM_URL + '/leads/' + leadUniqueId, {
      lead: leadData,
    });
  }

  deleteLead(leadUniqueId: string): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23CRM_URL + '/leads/' + leadUniqueId
    );
  }

  getLeads(
    query: string,
    page: number,
    sizes: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(sizes && { ['records']: sizes.toString() }),
        },
      }),
    };
    return this.http.get(environment.API_23CRM_URL + '/leads', httpOptions);
  }

  //   # Categories

  createCategory(categoryData: CategoryData): Observable<ApiResponse> {
    return this.http.post(environment.API_23CRM_URL + '/categories', {
      category: categoryData,
    });
  }

  getCategory(categoryUniqueId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23CRM_URL + '/categories/' + categoryUniqueId
    );
  }

  editCategory(
    categoryUniqueId: string,
    categoryData: CategoryData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23CRM_URL + '/categories' + categoryUniqueId,
      {
        category: categoryData,
      }
    );
  }

  deleteCategory(categoryUniqueId: string): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23CRM_URL + '/categories/' + categoryUniqueId
    );
  }

  getCategories(
    query: string,
    page: number,
    sizes: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(sizes && { ['records']: sizes.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23CRM_URL + '/categories',
      httpOptions
    );
  }

  getQuotes(): Observable<ApiResponse> {
    return this.http.get(environment.API_23CRM_URL + '/quotes');
  }

  getQuote(quoteId: string): Observable<ApiResponse> {
    return this.http.get(environment.API_23CRM_URL + '/quotes/' + quoteId);
  }

  createQuote(quoteData: QuoteData): Observable<ApiResponse> {
    return this.http.post(environment.API_23CRM_URL + '/quotes', {
      quote: quoteData,
    });
  }

  editQuote(quoteId: string, quoteData: QuoteData): Observable<ApiResponse> {
    return this.http.put(environment.API_23CRM_URL + '/quotes/' + quoteId, {
      quote: quoteData,
    });
  }

  getAccountQuotes(
    accountId: string,
    query: string,
    page: number,
    sizes: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(sizes && { ['records']: sizes.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23CRM_URL + '/accounts/' + accountId + '/quotes',
      httpOptions
    );
  }

  createAccountQuote(
    accountId: string,
    data: AddQuoteData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23CRM_URL + '/accounts/' + accountId + '/quotes',
      { account: data }
    );
  }

  editAccountQuote(
    accountId: string,
    data: AddQuoteData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23CRM_URL + '/accounts/' + accountId + '/quotes',
      { account: data }
    );
  }

  deleteAccountQuote(accountId: string): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23CRM_URL + '/accounts/' + accountId + '/quotes'
    );
  }

  getFollowUpsByLead(
    leadId: string,
    query: string,
    page: number,
    sizes: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(sizes && { ['records']: sizes.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23CRM_URL + '/leads/' + leadId + '/follows',
      httpOptions
    );
  }

  getFollowUpByLead(
    leadId: string,
    followUpId: string
  ): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23CRM_URL + '/leads/' + leadId + '/follows/' + followUpId
    );
  }

  createFollowUpstoLead(
    leadId: string,
    data: FollowUpsData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23CRM_URL + '/leads/' + leadId + '/follows',
      { follow: data }
    );
  }

  editFollowUpLead(
    leadId: string,
    followId: string,
    data: FollowUpsData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23CRM_URL + '/leads/' + leadId + '/follows/' + followId,
      { follow: data }
    );
  }

  editFollowUp(
    followUpId: string,
    followUpData: FollowUpsData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23CRM_URL + '/FollowUps/' + followUpId,
      {
        followUp: followUpData,
      }
    );
  }

  deleteFollowUp(followUpId: string): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23CRM_URL + '/followUps' + followUpId
    );
  }

  //   # Opportunities
  getOpportunities(
    query: string,
    page: number,
    sizes: number
  ): Observable<ApiResponse> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...(query && { ['search']: query.toString() }),
          ...(page && { ['page']: page.toString() }),
          ...(sizes && { ['records']: sizes.toString() }),
        },
      }),
    };
    return this.http.get(
      environment.API_23CRM_URL + '/opportunities',
      httpOptions
    );
  }

  getOpportunity(opportunityId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23CRM_URL + '/opportunities/' + opportunityId
    );
  }

  createOpportunity(opportunityData: OpportunityData): Observable<ApiResponse> {
    return this.http.post(environment.API_23CRM_URL + '/opportunities/', {
      opportunity: opportunityData,
    });
  }

  editOpportunity(
    opportunityId: string,
    opportunityData: OpportunityData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23CRM_URL + '/opportunities/' + opportunityId,
      { opportunity: opportunityData }
    );
  }

  deleteOpportunity(opportunityId: string): Observable<ApiResponse> {
    return this.http.delete(
      environment.API_23CRM_URL + '/opportunities/' + opportunityId
    );
  }
}
