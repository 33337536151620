import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  Sales23blocksOptions,
  SALES_23BLOCKS_SERVICE_OPTIONS,
} from './models/sales.interfaces';
import { salesReducer } from './reducers/sales.reducers';
import { Sales23blocksInterceptor } from './interceptors/sales.interceptors';
import { SalesService } from './services/sales.service';
import { SalesEffects } from './effects/sales.effects';
import { SalesGuard } from './guards/sales.guard';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('sales', salesReducer),
    EffectsModule.forFeature([SalesEffects]),
  ],
})
export class Sales23blocksModule {
  constructor(@Optional() @SkipSelf() parentModule: Sales23blocksModule) {
    if (parentModule) {
      throw new Error(
        "Sales23blocksModule is already loaded. It should only be imported in your application's main module."
      );
    }
  }
  static forRoot(
    options: Sales23blocksOptions
  ): ModuleWithProviders<Sales23blocksModule> {
    return {
      ngModule: Sales23blocksModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: Sales23blocksInterceptor,
          multi: true,
        },
        options.SalesOptionsProvider || {
          provide: SALES_23BLOCKS_SERVICE_OPTIONS,
          useValue: options,
        },
        SalesService,
        SalesGuard,
      ],
    };
  }
}
