import { Action } from '@ngrx/store';
import { ProfileData } from '../models/gateway.interfaces';
import { Profile } from '../models/profile.model';

export enum UserActionTypes {
  SaveProfile = '[SaveProfile] Request API',
  SaveProfileSuccess = '[SaveProfileSuccess] API',
  SaveProfileFailure = '[SaveProfileFailure] API',
  ProfileLoaded = '[ProfileLoaded] API',
}

export class SaveProfile implements Action {
  readonly type = UserActionTypes.SaveProfile;
  constructor(public payload: { profileData: ProfileData; next?: Action }) {}
}

export class SaveProfileSuccess implements Action {
  readonly type = UserActionTypes.SaveProfileSuccess;
}

export class SaveProfileFailure implements Action {
  readonly type = UserActionTypes.SaveProfileFailure;
}

export class ProfileLoaded implements Action {
  readonly type = UserActionTypes.ProfileLoaded;
  constructor(public payload: { profile: Profile }) {}
}

export type UserActions =
  | SaveProfile
  | SaveProfileSuccess
  | SaveProfileFailure
  | ProfileLoaded;
