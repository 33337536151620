import { Injectable, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { selectAccessToken } from '../../gateway';
import { AuthToken } from '../../gateway/models/gateway.interfaces';
import { isPlatformServer } from '@angular/common';

import { ApiResponse } from '../../models/api-response.model';
import {
  CustomerData,
  CustomerIdentityData,
  CustomerSuscriptionData,
  MailTemplateData,
  NewOrderData,
  NewSubscriptionData,
  NewTenantData,
  OrderDetailProviderData,
  OrderDetailsData,
  PaymentData,
  PaymentReportData,
  ProviderReportData,
  ReportData,
  Sales23blocksOptions,
  SALES_23BLOCKS_SERVICE_OPTIONS,
  StripePaymentAttemptData,
  StripeSubscriptionData,
  UpdateSubscriptionData,
  UserIdentityData,
  VendorPaymentData,
  VendorToPayData,
} from '../models/sales.interfaces';
import { map } from 'rxjs/operators';
import { Order } from '../models/order-model';
import { normalizeApiResponse } from 'src/app/common/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class SalesService {
  public authData: BehaviorSubject<AuthToken> = new BehaviorSubject<AuthToken>(
    null
  );

  get tokenOptions(): Sales23blocksOptions {
    return this.options;
  }
  set tokenOptions(options: Sales23blocksOptions) {
    this.options = (Object as any).assign(this.options, options);
  }
  private options: Sales23blocksOptions;
  constructor(
    private http: HttpClient,
    @Inject(SALES_23BLOCKS_SERVICE_OPTIONS) config: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() private activatedRoute: ActivatedRoute,
    @Optional() private router: Router,
    private store: Store<AppState>
  ) {
    const defaultOptions: Sales23blocksOptions = {
      apiPath: null,
      apiBase: null,
      APPID: null,
    };
    const mergedOptions = (Object as any).assign(defaultOptions, config);
    this.options = mergedOptions;
    if (this.options.apiBase === null) {
      console.warn(
        `[Sales 23Blocks] You have not configured 'apiBase', which may result in security issues. ` +
          `Please refer to the documentation at https://github.com/neroniaky/angular-token/wiki`
      );
    }
  }
  private checkAuthData(authData: AuthToken): boolean {
    if (
      authData.companyToken != null &&
      authData.accessToken != null &&
      authData.client != null &&
      authData.expiry != null &&
      authData.tokenType != null &&
      authData.uid != null &&
      authData.appid != null
    ) {
      if (this.authData.value != null) {
        return authData.expiry >= this.authData.value.expiry;
      }
      return true;
    }
    return false;
  }

  public getAuthDataFromStorage(): void {
    const authData: AuthToken = {
      companyToken: localStorage.getItem('companyToken'),
      accessToken: localStorage.getItem('accessToken'),
      client: localStorage.getItem('client'),
      expiry: localStorage.getItem('expiry'),
      tokenType: localStorage.getItem('tokenType'),
      uid: localStorage.getItem('uid'),
      appid: localStorage.getItem('appid'),
    };

    if (this.checkAuthData(authData)) {
      this.authData.next(authData);
    }
  }

  createUserIdentity(
    userUniqueId: string,
    dataForm: UserIdentityData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23SALES_URL + '/users/' + userUniqueId + '/register/',
      {
        user: dataForm,
      }
    );
  }

  getUserIdentity(userUniqueId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23SALES_URL + '/users/' + userUniqueId
    );
  }

  createUserSubscription(
    userUniqueId: string,
    subscriptionId: string,
    subscriptionData: NewSubscriptionData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23SALES_URL +
        '/users/' +
        userUniqueId +
        '/subscriptions/' +
        subscriptionId,
      { subscription: subscriptionData }
    );
  }

  getUserSubscription(
    userUniqueId: string,
    subscriptionId: string
  ): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23SALES_URL +
        '/users/' +
        userUniqueId +
        '/subscriptions/' +
        subscriptionId
    );
  }

  updateUserSubscription(
    userUniqueId: string,
    subscriptionId: string,
    subscriptionData: UpdateSubscriptionData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23SALES_URL +
        '/users/' +
        userUniqueId +
        '/subscriptions/' +
        subscriptionId,
      { subscription: subscriptionData }
    );
  }

  getUserPayments(userUniqueId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23SALES_URL + '/users/' + userUniqueId + '/payments'
    );
  }

  getAllMailTemplates(): Observable<ApiResponse> {
    return this.http.get(environment.API_23SALES_URL + '/mailtemplates/');
  }

  getMailTemplate(templateId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23SALES_URL + '/mailtemplates/' + templateId
    );
  }

  updateMailTemplate(
    templateId: string,
    templateData: MailTemplateData
  ): Observable<ApiResponse> {
    const body = { template: templateData };
    return this.http.put(
      environment.API_23SALES_URL + '/mailtemplates/' + templateId,
      body
    );
  }

  updateUserIdentity(
    userUniqueId: string,
    userIdentityData: UserIdentityData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23SALES_URL + '/users/' + userUniqueId,
      { user: userIdentityData }
    );
  }

  createStripeCustomer(customerData: CustomerData): Observable<ApiResponse> {
    return this.http.post(environment.API_23SALES_URL + '/stripe/customers', {
      customer: customerData,
    });
  }

  createStripeSubscription(
    stripeSubscriptionData: StripeSubscriptionData
  ): Observable<any> {
    return this.http.post(
      environment.API_23SALES_URL + '/stripe/subscriptions',
      {
        subscription: stripeSubscriptionData,
      }
    );
  }

  createPaymentAttempt(
    stripePaymentAttemptData: StripePaymentAttemptData
  ): Observable<any> {
    return this.http.post(environment.API_23SALES_URL + '/stripe/payments', {
      payment: stripePaymentAttemptData,
    });
  }

  deleteStripeSubscription(customerId: string): Observable<any> {
    return this.http.request(
      'delete',
      environment.API_23SALES_URL + '/stripe/subscriptions',
      {
        body: {
          subscription: { customer_id: customerId },
        },
      }
    );
  }

  createCustomerIdentity(
    customerUniqueId: string,
    customer: CustomerIdentityData
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23SALES_URL +
        '/customers/' +
        customerUniqueId +
        '/register/',
      {
        customer,
      }
    );
  }

  getCustomerIdentity(customerUniqueId: string): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23SALES_URL + '/customers/' + customerUniqueId
    );
  }

  createCustomerSubscription(
    companyUniqueId: string,
    subscriptionModelUniqueId: string
  ): Observable<ApiResponse> {
    return this.http.post(
      environment.API_23SALES_URL +
        '/customers/' +
        companyUniqueId +
        '/subscriptions/',
      {
        subscription: {
          subscription_model_unique_id: subscriptionModelUniqueId,
        },
      }
    );
  }

  updateCustomerSubscription(
    customerUniqueId: string,
    companySubscriptionId: string,
    subscriptionData: CustomerSuscriptionData
  ): Observable<ApiResponse> {
    return this.http.put(
      environment.API_23SALES_URL +
        '/customers/' +
        customerUniqueId +
        '/subscriptions/' +
        companySubscriptionId,
      { subscription: subscriptionData }
    );
  }

  getCustomerSubscription(
    customerUniqueId: string,
    customerSubscriptionId: string
  ): Observable<ApiResponse> {
    return this.http.get(
      environment.API_23SALES_URL +
        '/customers/' +
        customerUniqueId +
        '/subscriptions/' +
        customerSubscriptionId
    );
  }

  addBlock(
    tenantAppId: string,
    companyData: NewTenantData
  ): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        blockAppId: tenantAppId,
      }),
    };
    return this.http.post<ApiResponse>(
      environment.API_23SALES_URL + '/companies',
      { company: companyData },
      httpOptions
    );
  }

  // Orders

  createOrder(orderData: Partial<NewOrderData>): Observable<ApiResponse> {
    return this.http.post(`${environment.API_23SALES_URL}/orders`, {
      order: {
        customer_unique_id: orderData.customer_unique_id,
        customer_email: orderData.customer_email,
        customer_name: orderData.customer_name,
        tax: orderData.tax,
        tax_value: orderData.tax_value,
        total: orderData.total,
        fees: orderData.fees,
        ...(orderData.payload && {
          payload: JSON.stringify(orderData.payload),
        }),
        parent_unique_id: orderData.parent_unique_id,
        source_id: orderData.source_id,
        source: orderData.source,
        source_alias: orderData.source_alias,
        source_type: orderData.source_type,
        cart_unique_id: orderData.cart_unique_id,
        discount_value: orderData.discount_value,
        promo_code: orderData.promo_code,
      },
    });
  }

  // update the status of an order
  updateOrderStatus(orderId: string, status: string): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23SALES_URL}/orders/${orderId}/status`,
      {
        order: {
          status,
        },
      }
    );
  }

  addOrderDetails(
    orderId: string,
    orderDetails: Partial<OrderDetailsData>
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23SALES_URL}/orders/${orderId}/details`,
      {
        details: {
          product_sku: orderDetails.product_sku,
          product_unique_id: orderDetails.product_unique_id,
          product_name: orderDetails.product_name,
          product_description: orderDetails.product_description,
          category_name: orderDetails.category_name,
          is_variation: orderDetails.is_variation,
          quantity: orderDetails.quantity,
          notes: orderDetails.notes,
          subtotal:
            orderDetails.subtotal ||
            Number(orderDetails.price_with_fees) *
              Number(orderDetails.quantity),
          discount: orderDetails.discount,
          tax: orderDetails.tax,
          tax_value: orderDetails.subtotal_tax,
          fees: orderDetails.fees,
          fees_value: orderDetails.fees_value,
          total: orderDetails.total,
          ...(orderDetails.payload && {
            payload: JSON.stringify(orderDetails.payload),
          }),

          source: orderDetails.source,
          source_type: orderDetails.source_type,
          source_alias: orderDetails.source_alias,
          source_id: orderDetails.source_id,

          // Store cart, cart detail uniqueId
          cart_unique_id: orderDetails.cart_unique_id,
          cart_detail_unique_id: orderDetails.cart_detail_unique_id,
        },
      }
    );
  }

  removeOrderDetails(
    orderId: string,
    orderDetailsId: string
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23SALES_URL}/orders/${orderId}/details/${orderDetailsId}/status`,
      {
        details: {
          status: 'cancelled',
        },
      }
    );
  }

  getOrder(orderId: string): Observable<ApiResponse> {
    return this.http.get(`${environment.API_23SALES_URL}/orders/${orderId}`);
  }

  getOrdersBySourceId(sourceId: string): Observable<ApiResponse> {
    return this.http.get(
      `${environment.API_23SALES_URL}/orders/?source=${sourceId}`
    );
  }

  getNormalizedOrder(orderId: string): Observable<Order> {
    return this.getOrder(orderId).pipe(
      map((apiResponse) => {
        if (!apiResponse?.data) return null;
        const _order = normalizeApiResponse(
          apiResponse,
          'order',
          false
        ) as Order;

        return _order;
      })
    );
  }

  updateOrderProvider(
    bookingCode: string,
    vendor: OrderDetailProviderData,
    productSku: string,
    productUniqueId?: string
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23SALES_URL}/sources/${bookingCode}/providers`,
      {
        vendor,
        product: {
          sku: productSku,
          unique_id: productUniqueId,
        },
      }
    );
  }

  cancelOrder(orderId: string): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23SALES_URL}/orders/${orderId}/cancel`
    );
  }

  getOrdersReport(reportData: Partial<ReportData>): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23SALES_URL}/reports/orders/list`,
      {
        query_params: reportData,
      }
    );
  }

  getProvidersReport(
    reportData: Partial<ProviderReportData>
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23SALES_URL}/reports/orders/providers/list`,
      {
        query_params: reportData,
      }
    );
  }

  createPayment(
    orderId: string,
    payment: Partial<PaymentData>
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23SALES_URL}/orders/${orderId}/payments`,
      {
        payment,
      }
    );
  }

  translateOrderStatus(status: string): string {
    switch (status) {
      case 'active':
        return 'activa';
      case 'paid':
        return 'pagada';
      case 'payment_attempted':
        return 'pago intentado';
      default:
        return status;
    }
  }

  // Vendor payments

  getVendorAccountPayable(accountPayableId: string): Observable<ApiResponse> {
    return this.http.get(
      `${environment.API_23SALES_URL}/payables/${accountPayableId}`
    );
  }

  createVendorAccountPayable(
    orderId: string,
    detailId: string,
    vendorId: string,
    vendorToPayData: Partial<VendorToPayData>
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23SALES_URL}/orders/${orderId}/details/${detailId}/vendors/${vendorId}/payments`,
      {
        vendor_to_pay: vendorToPayData,
      }
    );
  }

  updateVendorAccountPayable(
    orderId: string,
    detailId: string,
    vendorId: string,
    paymentId: string,
    vendorToPayData: Partial<VendorToPayData>
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23SALES_URL}/orders/${orderId}/details/${detailId}/vendors/${vendorId}/payments/${paymentId}`,
      {
        vendor_to_pay: vendorToPayData,
      }
    );
  }

  deleteVendorAccountPayable(
    orderId: string,
    detailId: string,
    vendorId: string,
    paymentId: string
  ): Observable<ApiResponse> {
    return this.http.delete(
      `${environment.API_23SALES_URL}/orders/${orderId}/details/${detailId}/vendors/${vendorId}/payments/${paymentId}`
    );
  }

  registerVendorPayment(
    orderId: string,
    detailId: string,
    vendorId: string,
    accountPayableId: string,
    VendorPaymentData: Partial<VendorPaymentData>
  ): Observable<ApiResponse> {
    return this.http.put(
      `${environment.API_23SALES_URL}/orders/${orderId}/details/${detailId}/vendors/${vendorId}/payments/${accountPayableId}/pay`,
      {
        vendor_payment: VendorPaymentData,
      }
    );
  }

  // Payment management reports

  getVendorsPaymentsReportList(
    params: Partial<PaymentReportData>
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23SALES_URL}/reports/vendors/payments/list`,
      {
        query_params: params,
      }
    );
  }

  getVendorsPaymentsReportSummary(
    params: Partial<PaymentReportData>
  ): Observable<ApiResponse> {
    return this.http.post(
      `${environment.API_23SALES_URL}/reports/vendors/payments/summary`,
      {
        query_params: params,
      }
    );
  }
}
