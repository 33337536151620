// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class UserStatusService {
  // Private properties
  private onStatusUpdated$ = new BehaviorSubject<boolean>(false);

  // Public properties
  public userStatus$: Observable<boolean>;
  /**
   * Service constructor
   */
  constructor() {
    console.log('service constructor');
    this.userStatus$ = this.onStatusUpdated$.asObservable();
  }

  isOnline(): void {
    this.onStatusUpdated$.next(true);
  }

  isOffline(): void {
    this.onStatusUpdated$.next(false);
  }
}
