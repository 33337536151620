import { Action } from '@ngrx/store';
import { UserIdentity } from '../models/user-identity.model';

export enum FormsActionTypes {
  IdentityRequested = '[Request User Forms Identity] API',
  IdentityLoaded = '[Load User Forms Identity] API',
  CreateIdentity = '[Create User Forms Identity] API',
}

export class IdentityRequested implements Action {
  readonly type = FormsActionTypes.IdentityRequested;
}
export class IdentityLoaded implements Action {
  readonly type = FormsActionTypes.IdentityLoaded;
  constructor(public payload: { userIdentity: UserIdentity }) {}
}

export class CreateIdentity implements Action {
  readonly type = FormsActionTypes.CreateIdentity;
}

export type FormsActions = IdentityRequested | IdentityLoaded | CreateIdentity;
