import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  OnboardingActions,
  OnboardingActionTypes,
} from '../actions/onboarding.actions';
import { UserJourney } from '../models/user-journey.model';
import { Onboarding } from '../models/onboarding.model';
import { UserIdentity } from '../models/user-identity.model';

export interface OnboardingState extends EntityState<Onboarding> {
  userJourney: UserJourney;
  isUserJourneyLoaded: boolean;
  isUserJourneyFetched: boolean;
  isUserJourneyLoading: boolean;
  isCurrentStepComplete: boolean;
  isOnboardingComplete: boolean;
  onboardingConfig: Onboarding;
  isOnboardingConfigLoaded: boolean;
  isOnboardingConfigFetched: boolean;
  onboardingStep: any;
  isStepLoaded: boolean;
  isIdentityLoaded: boolean;
  isIdentityFetched: boolean;
  userIdentity: UserIdentity;
}

export const adapter: EntityAdapter<Onboarding> = createEntityAdapter<
  Onboarding
>();
export const initialOnboardingState: OnboardingState = adapter.getInitialState({
  userJourney: {
    attempts: undefined,
    createdAt: undefined,
    id: undefined,
    journeyLogs: [],
    nextStep: undefined,
    nextStepUniqueId: undefined,
    nextStepUrl: undefined,
    onboardingName: undefined,
    onboardingUniqueId: undefined,
    payload: undefined,
    previousStep: undefined,
    previousStepUniqueId: undefined,
    previousStepUrl: undefined,
    progress: undefined,
    source: undefined,
    sourceAlias: undefined,
    sourceId: undefined,
    sourceType: undefined,
    status: undefined,
    stepName: undefined,
    stepOrder: undefined,
    stepParams: undefined,
    stepUniqueId: undefined,
    stepUrl: undefined,
    uniqueId: undefined,
    updatedAt: undefined,
    userUniqueId: undefined,
    stepStatus: undefined,
  },
  isUserJourneyLoaded: false,
  isUserJourneyFetched: false,
  isUserJourneyLoading: false,
  isCurrentStepComplete: false,
  isOnboardingComplete: false,
  onboardingConfig: {
    contentUrl: undefined,
    description: undefined,
    id: undefined,
    imageUrl: undefined,
    name: undefined,
    payload: undefined,
    source: undefined,
    sourceAlias: undefined,
    sourceId: undefined,
    sourceType: undefined,
    status: undefined,
    steps: [],
    totalSteps: undefined,
    uniqueId: undefined,
    videoUrl: undefined,
  },
  isOnboardingConfigLoaded: false,
  isOnboardingConfigFetched: false,
  onboardingStep: undefined,
  isStepLoaded: false,
  isIdentityLoaded: false,
  isIdentityFetched: false,
  userIdentity: undefined,
});
export function onboardingReducer(
  state = initialOnboardingState,
  action: OnboardingActions
): OnboardingState {
  switch (action.type) {
    case OnboardingActionTypes.UserJourneyLoaded: {
      return {
        ...state,
        userJourney: action.payload.userJourney,
        isUserJourneyLoaded: action.payload.userJourney ? true : false,
        isUserJourneyFetched: true,
        isOnboardingComplete: action.payload.isComplete,
        isCurrentStepComplete:
          action.payload.userJourney &&
          action.payload.userJourney.stepStatus === 'complete'
            ? true
            : false,
        isUserJourneyLoading: false,
      };
    }
    case OnboardingActionTypes.OnboardingStepLoaded: {
      return {
        ...state,
      };
    }

    case OnboardingActionTypes.IdentityLoaded: {
      return {
        ...state,
        userIdentity: action.payload.userIdentity,
        isIdentityFetched: true,
        isIdentityLoaded: action.payload.userIdentity ? true : false,
      };
    }
    case OnboardingActionTypes.OnboardingConfigLoaded: {
      return {
        ...state,
        onboardingConfig: action.payload.onboardingConfig,
        isOnboardingConfigFetched: true,
        isOnboardingConfigLoaded: action.payload.onboardingConfig
          ? true
          : false,
      };
    }
    case OnboardingActionTypes.SendStep: {
      return {
        ...state,
        isUserJourneyLoading: true,
      };
    }
    default:
      return state;
  }
}
