import { InjectionToken, Provider } from '@angular/core';

export interface Wallet23blocksOptions {
  WalletOptionsProvider?: Provider;
  apiBase?: string;
  apiPath?: string;
  APPID: string;
}

export const WALLET_23BLOCKS_SERVICE_OPTIONS = new InjectionToken(
  'WALLET_23BLOCKS_SERVICE_OPTIONS'
);

export interface UserData {
  name: string;
  email: string;
  phone: string;
  status: string;
  user_unique_id: string;
  role_name: string;
  role_unique_id: string;
}

export type Status =
  | 'new'
  | 'active'
  | 'suspended'
  | 'incomplete'
  | 'incomplete_expired'
  | 'trialing'
  | 'past_due'
  | 'canceled'
  | 'unpaid'
  | 'cancelling'
  | 'payment_failed'
  | 'canceled';

export interface WalletTransactionData {
  amount: number;
  notes: string;
  payload?: string;
  source?: string;
  source_alias?: string;
  source_id?: string;
  source_type?: string;
  address?: string;
  wallet_type?: string;
}

export interface WalletData {
  notes?: string;
  payload?: string;
}

export interface TransferData {
  target_user_unique_id: string;
  target_wallet_code: string;
  amount: number;
  otp_code: number;
  source: string;
  source_alias: string;
  source_id: string;
  source_type: string;
}
