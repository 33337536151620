// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  appVersion: 'v8.1.8',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'api',
  appThemeName: 'Metronic',
  appPurchaseUrl: 'https://1.envato.market/EA4JP',
  appHTMLIntegration:
    'https://preview.keenthemes.com/metronic8/demo1/documentation/base/helpers/flex-layouts.html',
  appPreviewUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1/',
  appPreviewAngularUrl:
    'https://preview.keenthemes.com/metronic8/angular/demo1',
  appPreviewDocsUrl: 'https://preview.keenthemes.com/metronic8/angular/docs',
  appPreviewChangelogUrl:
    'https://preview.keenthemes.com/metronic8/angular/docs/changelog',
  appDemos: {
    demo1: {
      title: 'Demo 1',
      description: 'Default Dashboard',
      published: true,
      thumbnail: './assets/media/demos/demo1.png',
    },

    demo2: {
      title: 'Demo 2',
      description: 'SaaS Application',
      published: true,
      thumbnail: './assets/media/demos/demo2.png',
    },

    demo3: {
      title: 'Demo 3',
      description: 'New Trend',
      published: true,
      thumbnail: './assets/media/demos/demo3.png',
    },

    demo4: {
      title: 'Demo 4',
      description: 'Intranet Application',
      published: true,
      thumbnail: './assets/media/demos/demo4.png',
    },

    demo5: {
      title: 'Demo 5',
      description: 'Support Forum',
      published: false,
      thumbnail: './assets/media/demos/demo5.png',
    },

    demo6: {
      title: 'Demo 6',
      description: 'Admin Backend',
      published: true,
      thumbnail: './assets/media/demos/demo6.png',
    },

    demo7: {
      title: 'Demo 7',
      description: 'CRM Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo7.png',
    },

    demo8: {
      title: 'Demo 8',
      description: 'Core Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo8.png',
    },

    demo9: {
      title: 'Demo 9',
      description: 'Fancy Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo9.png',
    },

    demo10: {
      title: 'Demo 10',
      description: 'Modern Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo10.png',
    },

    demo11: {
      title: 'Demo 11',
      description: 'Light Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo11.png',
    },

    demo12: {
      title: 'Demo 12',
      description: 'Reporting System',
      published: false,
      thumbnail: './assets/media/demos/demo12.png',
    },

    demo13: {
      title: 'Demo 13',
      description: 'Classic Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo13.png',
    },

    demo14: {
      title: 'Demo 14',
      description: 'Creative Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo14.png',
    },

    demo15: {
      title: 'Demo 15',
      description: 'Minimalistic Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo15.png',
    },

    demo16: {
      title: 'Demo 16',
      description: 'Modern Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo16.png',
    },

    demo17: {
      title: 'Demo 17',
      description: 'Backend System',
      published: false,
      thumbnail: './assets/media/demos/demo17.png',
    },

    demo18: {
      title: 'Demo 18',
      description: 'System Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo18.png',
    },

    demo19: {
      title: 'Demo 19',
      description: 'Light Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo19.png',
    },

    demo20: {
      title: 'Demo 20',
      description: 'Monochrome Dashboard',
      published: false,
      thumbnail: './assets/media/demos/demo20.png',
    },
  },

  name: 'Production',
  version: '0.0.20',
  authTokenKey: 'authce9d77b308c149d5923kjh4wd8f',
  APP_URL: 'https://app.mooncheck.com',
  API_23GATEWAY_URL: 'https://gateway.23blocks.com',
  API_23PRODUCTS_URL: 'https://products.23blocks.com',
  API_23REALTIME_URL: 'https://conversations.23blocks.com',
  API_23UNIVERSITY_URL: 'https://university.23blocks.com',
  API_23ONBOARDING_URL: 'https://onboarding.23blocks.com',
  API_23SALES_URL: 'https://sales.23blocks.com',
  API_23CRM_URL: 'https://crm.23blocks.com',
  API_23FILES_URL: 'https://files.23blocks.com',
  API_23FORMS_URL: 'https://forms.23blocks.com',
  API_23SEARCH_URL: 'https://search.23blocks.com',
  API_23GEOLOCATION_URL: 'https://geolocation.23blocks.com',
  API_23REWARDS_URL: 'https://rewards.23blocks.com',
  API_23WALLET_URL: 'https://wallets.23blocks.com',
  API_23ASSETS_URL: 'https://assets.23blocks.com',
  API_23MOONCHECK_URL: 'https://api.mooncheck.com',
  API_23CONTENT_URL: 'https://content.23blocks.com',
  WEBSOCKETS_23_URL: 'wss://conversations.23blocks.com/cable',
  APPID: 'PwzmmlYIlD73mPh2t_on4MjOIRU',

  GUEST_ROLE_UNIQUE_ID: '50cf0286-089d-454a-86cf-187ab2e60f3a', // role 1: guest
  ADMIN_ROLE_UNIQUE_ID: '42fb20fc-bcc8-4d48-9a2c-41334879331a', // role 2: admin
  SUPPORT_ROLE_UNIQUE_ID: '928447fe-2434-4380-9335-39865dd13839', // role 3: support
  USER_ROLE_UNIQUE_ID: '870bf4c0-569b-4344-ae10-59a7c21a50c0', // role 4: user
  INVESTOR_ROLE_UNIQUE_ID: 'e0b8249e-83c2-4ac9-b03d-cbe18be193cf', // role 5: investor (authorized)

  FILE_CATEGORY_UNIQUE_ID: '3339deec-1015-4fba-a482-16eec0788fa3',

  TURN_URLS: 'turn:turn.23blocks.com:3478',
  TURN_USERNAME: 'turn23blocks',
  TURN_CREDENTIAL: 'algo',

  RECAPTCHA_KEY: '6Les5-4hAAAAAFIDlY1xEej-W5Y04Kf7ZQjHJ81_',

  LANDING_FORM_UNIQUE_ID: 'a005013c-b69e-4662-8e0d-9dd51a79ff4c',
  EDITOR_API_KEY: 'd6z13uw3jssn6784r2aiwiv78ogdrrhfl2f6b6gw4ebvsugb',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
