import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  filter,
  first,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';
import { environment } from 'src/environments/environment';
import {
  AuthActionTypes,
  currentAuthUser,
  selectRoleById,
  User,
  UserLoaded,
} from '../../gateway';

import normalize from 'json-api-normalizer';
import build from 'redux-object';
import { Router } from '@angular/router';
import { UserData } from '../models/files.interface';
import {
  CreateIdentity,
  FilesActionTypes,
  IdentityLoaded,
  IdentityRequested,
} from '../actions/files.actions';
import { FilesService } from '../services/files.service';
import {
  getRoleNameForIdentity,
  getRoleUniqueId,
} from 'src/app/core/tools/helper-functions';

@Injectable()
export class FilesEffects {
  loadIdentity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<IdentityRequested>(FilesActionTypes.IdentityRequested),
        concatLatestFrom((action) => this.store.select(currentAuthUser)),
        mergeMap(([action, user]) => {
          return this.files.getUserIdentity(user.uniqueId);
        }),
        tap(
          (apiResponse) => {
            if (apiResponse.data) {
              // console.log(apiResponse, 'Ya existe identidad files');
              const data: any = normalize(apiResponse);
              const _userIdentity = build(
                data,
                'userIdentity',
                apiResponse.data.id,
                {
                  eager: true,
                }
              );
              this.store.dispatch(
                new IdentityLoaded({
                  userIdentity: _userIdentity,
                  firstTime: false,
                })
              );
            } else {
              console.log('No existe identidad files');
              this.store.dispatch(new CreateIdentity());
            }
          },
          (error) => {
            this.store.dispatch(new CreateIdentity());
          }
        )
      ),
    { dispatch: false }
  );

  createIdentity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CreateIdentity>(FilesActionTypes.CreateIdentity),
        concatLatestFrom((action) => this.store.select(currentAuthUser)),
        switchMap(([action, user]) => {
          console.log('Creando Identidad Files...');
          const userData = this.buildUserData(user);
          return this.files.registerForUpload(user.uniqueId, userData);
        }),
        tap(
          (apiResponse) => {
            console.log(apiResponse, 'Se agrego identidad Files');
            const data: any = normalize(apiResponse);
            const _userIdentity = build(
              data,
              'userIdentity',
              apiResponse.data.id,
              {
                eager: true,
              }
            );
            this.store.dispatch(
              new IdentityLoaded({
                userIdentity: _userIdentity,
                firstTime: true,
              })
            );
          },
          (error) => {
            console.log(error, 'Error al agregar identidad Files');
          }
        )
      ),
    { dispatch: false }
  );

  buildUserData(user: User): UserData {
    const role_name = getRoleNameForIdentity(user.roleId);
    const role_unique_id = getRoleUniqueId(user.roleId);

    return {
      name: user.name || 'User Name',
      phone:
        user.phone ||
        (Math.floor(Math.random() * 9000000000) + 1000000000).toString(),
      email: user.email || 'example@email.com',
      user_unique_id: user.uniqueId,
      role_name,
      role_unique_id,
      status: '',
    };
  }

  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UserLoaded>(AuthActionTypes.UserLoaded),
        tap((action) => {
          // this.store.dispatch(new IdentityRequested());
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private files: FilesService,
    private store: Store<AppState>,
    private router: Router
  ) {}
}
