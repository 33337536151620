import { InjectionToken, Provider } from '@angular/core';

export interface Content23blocksOptions {
  ContentOptionsProvider?: Provider;
  apiBase?: string;
  apiPath?: string;
  APPID: string;
}

export const CONTENT_23BLOCKS_SERVICE_OPTIONS = new InjectionToken(
  'CONTENT_23BLOCKS_SERVICE_OPTIONS'
);

export interface UserIdentityData {
  user: Partial<UserData>;
  tag: TagData;
}

export interface UserData {
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  phone: string;
  avatar_url: string;
  user_unique_id: string;
  role_name: string;
  role_unique_id: string;
  payload: string;
  time_zone: string;
  preferred_language: string;
  email_notifications: string;
  sms_notifications: string;
  whatsapp_notifications: string;
  other_notifications: string;
  wallet_code: string;
}

export interface TagData {
  tag: string;
  unique_id: string;
}

export type SearchParams = {
  query: string;
  page: number;
  records: number;
};

export class ApiResponse {
  status?: string;
  success?: boolean;
  statusText?: string;
  errors?: any;
  data?: any;
  meta?: MetaData;
  include?: any;
}

export interface MetaData {
  totalRecords: number;
  totalPages: number;
}

/* implemented */

export interface ContentPostData {
  title: string;
  abstract: string;
  keywords: string;
  content: string;
  thumbnail_url: string;
  image_url: string;
  media_url: string;
  payload: any;
  status: string;
  publish_at: string;
  publish_until: string;

  user_unique_id: string;
  user_name: string;
  user_alias: string;
  is_public: boolean;
  version: number; //published version
  revision: number; //draft
  source: 'draft' | 'contribution';
  likes: string;
  comments: string;
}

export interface CommentData {
  content: string;
  thumbnail_url: string;
  image_url: string;
  content_url: string;
  media_url: string;
  user_unique_id: string;
  user_name: string;
  user_alias: string;
  payload: string;
  status: string;
  parent_id: string;
}
