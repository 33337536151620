import { FormsActions, FormsActionTypes } from '../actions/forms.actions';
import { UserIdentity } from '../models/user-identity.model';

export interface FormsState {
  userIdentity: UserIdentity;
  isIdentityLoaded: boolean;
}

export const initialFormsState: FormsState = {
  userIdentity: undefined,
  isIdentityLoaded: false,
};

export function formsReducer(
  state = initialFormsState,
  action: FormsActions
): FormsState {
  switch (action.type) {
    case FormsActionTypes.IdentityLoaded: {
      return {
        ...state,
        isIdentityLoaded: true,
        userIdentity: action.payload.userIdentity,
      };
    }
    default:
      return state;
  }
}
