import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  Wallet23blocksOptions,
  WALLET_23BLOCKS_SERVICE_OPTIONS,
} from './models/wallet.interfaces';
import { walletReducer } from './reducers/wallet.reducers';
import { Wallet23blocksInterceptor } from './interceptors/wallet.interceptors';
import { WalletService } from './services/wallet.service';
import { WalletEffects } from './effects/wallet.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('wallet', walletReducer),
    EffectsModule.forFeature([WalletEffects]),
  ],
})
export class Wallet23blocksModule {
  constructor(@Optional() @SkipSelf() parentModule: Wallet23blocksModule) {
    if (parentModule) {
      throw new Error(
        "Wallet23blocksModule is already loaded. It should only be imported in your application's main module."
      );
    }
  }
  static forRoot(
    options: Wallet23blocksOptions
  ): ModuleWithProviders<Wallet23blocksModule> {
    return {
      ngModule: Wallet23blocksModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: Wallet23blocksInterceptor,
          multi: true,
        },
        options.WalletOptionsProvider || {
          provide: WALLET_23BLOCKS_SERVICE_OPTIONS,
          useValue: options,
        },
        WalletService,
      ],
    };
  }
}
