import { Action } from '@ngrx/store';
import { UserIdentity } from '../models/user-identity.model';

export enum AssetsActionTypes {
  IdentityRequested = '[Request User Assets Identity] API',
  IdentityLoaded = '[Load User Assets Identity] API',
  CreateIdentity = '[Create User Assets Identity] API',
}

export class IdentityRequested implements Action {
  readonly type = AssetsActionTypes.IdentityRequested;
}
export class IdentityLoaded implements Action {
  readonly type = AssetsActionTypes.IdentityLoaded;
  constructor(public payload: { userIdentity: UserIdentity }) {}
}

export class CreateIdentity implements Action {
  readonly type = AssetsActionTypes.CreateIdentity;
}

export type AssetsActions = IdentityRequested | IdentityLoaded | CreateIdentity;
