import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsService } from './services/products.service';
import {
  Products23blocksOptions,
  PRODUCTS_23BLOCKS_SERVICE_OPTIONS,
} from './models/products.interfaces';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Products23blocksInterceptor } from './interceptors/products-23blocks.interceptor';
import { ProductsFacade } from './facades/products.facade';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { productsReducer } from './reducers/products.reducers';
import { ProductsEffects } from './effects/products.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('products', productsReducer),
    EffectsModule.forFeature([ProductsEffects]),
  ],
})
export class Products23blocksModule {
  constructor(@Optional() @SkipSelf() parentModule: Products23blocksModule) {
    if (parentModule) {
      throw new Error(
        "Products23Blocks is already loaded. It should only be imported in your application's main module."
      );
    }
  }
  static forRoot(
    options: Products23blocksOptions
  ): ModuleWithProviders<Products23blocksModule> {
    return {
      ngModule: Products23blocksModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: Products23blocksInterceptor,
          multi: true,
        },
        options.productsOptionsProvider || {
          provide: PRODUCTS_23BLOCKS_SERVICE_OPTIONS,
          useValue: options,
        },
        ProductsService,
        ProductsFacade,
      ],
    };
  }
}
